import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, ChartOptions } from "chart.js";
import { Pie } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

interface Props {
  data: any;
  chartRef: React.RefObject<ChartJSOrUndefined<"pie", any, unknown>>;
}

const PieChart = ({ data, chartRef }: Props) => {
  ChartJS.register(ArcElement, Tooltip);

  // const chartData = {
  //   labels: data.map((item) => item.label),
  //   datasets: [
  //     {
  //       data: data.map((item) => item.value),
  //       backgroundColor: data.map((item) => item.color),
  //       borderWidth: 0,
  //     },
  //   ],
  // };

  const options = {
    responsive: true,
    locale: "en",
  };

  return <Pie ref={chartRef} data={data} options={options} />;
};

export default PieChart;
