import React from "react";
import { Header } from "@/components/header/header";

interface LayoutProps {}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
}) => {
  return (
    <>
      <Header />
      <div className="wrapper w-full bg-main-mobile md:bg-main bg-cover bg-no-repeat dark:bg-dark-bg relative">
        {children}
      </div>
    </>
  );
};
