import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const SORT_ORDER_OPTIONS = {
  ASC: "asc",
  DESC: "desc",
} as const;

export type SortOrderValue =
  typeof SORT_ORDER_OPTIONS[keyof typeof SORT_ORDER_OPTIONS];

const SORT_BY_OPTIONS = {
  name: "name",
  action: "action",
  price: "price",
  quantity: "quantity",
  date: "date",
  changeDaily: "change-daily",
  volume: "volume",
  changeWeekly: "change-weekly",
  changeMonthly: "change-monthly",
  totalLocked: "total-locked",
  inflow: "inflow",
  outflow: "outflow",
  fundFee: "fund-fee",
  holdAmount: "hold-amount",
  cap: "cap",
  share: "share",
} as const;

type SortByValue = typeof SORT_BY_OPTIONS[keyof typeof SORT_BY_OPTIONS];

type SortState = {
  order: SortOrderValue | "";
  sortBy: SortByValue | "";
};

const QUERY_KEYS = {
  order: "order",
  sortBy: "sortBy",
} as const;

/**
 * Custom hook for managing table sort by columns.
 * @returns a stateful value, handler and options which can be accepted by handler.
 * */
export const useTableSort = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState<SortState>(() => {
    const order = (searchParams.get(QUERY_KEYS.order) as SortOrderValue) ?? "";
    const sortBy = (searchParams.get(QUERY_KEYS.sortBy) as SortByValue) ?? "";

    return {
      order,
      sortBy,
    };
  });

  const handleSort = (sortBy: SortByValue) => {
    const sortOrder =
      sortBy === sort.sortBy && sort.order === SORT_ORDER_OPTIONS.ASC
        ? SORT_ORDER_OPTIONS.DESC
        : SORT_ORDER_OPTIONS.ASC;

    // update local state;
    setSort({
      sortBy,
      order: sortOrder,
    });

    // update query params
    const newParams = new URLSearchParams(searchParams);
    newParams.set("sortBy", sortBy);
    newParams.set("order", sortOrder);

    setSearchParams(newParams);
  };

  return {
    ...sort,
    handleSort,
    sortOptions: SORT_BY_OPTIONS,
  };
};
