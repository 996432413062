import { TOKENS_ICONS } from "../assets/icons/tokens/tokensIcons";

export const Tokens = [
  {
    name: "$athom",
    icon: TOKENS_ICONS.athom,
    verified: true,
    id: "5aac31kdsf23a27b",
  },
  {
    name: "$BDOGE",
    icon: TOKENS_ICONS.bdoge,
    verified: true,
    id: "5aac31kdsf23a27d",
  },
  {
    name: "$CHIMPY",
    icon: TOKENS_ICONS.chimpy,
    verified: true,
    id: "5aac31kdsf23a32f",
  },
  {
    name: "$CLAW",
    icon: TOKENS_ICONS.claw,
    verified: true,
    id: "5aac31kdsf23a24a",
  },
  {
    name: "$CNFT",
    icon: TOKENS_ICONS.cnft,
    verified: true,
    id: "5aac31kdsf23a12f",
  },
  {
    name: "$FRACTALS",
    icon: TOKENS_ICONS.fractals,
    verified: true,
    id: "5aac31kdsf23a22f",
  },
  {
    name: "$HOSKY",
    icon: TOKENS_ICONS.hosky,
    verified: true,
    id: "5aac31kdsf23a23f",
  },
  {
    name: "$LEAF",
    icon: TOKENS_ICONS.leaf,
    verified: true,
    id: "5aac31kdsf23a32d",
  },
  {
    name: "$NFTC",
    icon: TOKENS_ICONS.nftc,
    verified: true,
    id: "5aac31kdsf23a24f",
  },
];

export const SelectedTokensData = [
  {
    name: "$HOSKY",
    icon: TOKENS_ICONS.hosky,
    verified: true,
    id: "5aac31kdsf23a23f",
  },
  {
    name: "$LEAF",
    icon: TOKENS_ICONS.leaf,
    verified: true,
    id: "5aac31kdsf23a32d",
  },
  {
    name: "$NFTC",
    icon: TOKENS_ICONS.nftc,
    verified: true,
    id: "5aac31kdsf23a24f",
  },
];
