import { ReactComponent as ArrowDown } from "@icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "@icons/arrow-up.svg";
import { ReactComponent as ArrowRight } from "@icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "@icons/arrow-left.svg";
import { ReactComponent as ChevronDown } from "@icons/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "@icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@icons/chevron-right.svg";
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as Bookmark } from "@icons/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "@icons/bookmark-filled.svg";
import { ReactComponent as CircleCheck } from "@icons/check-circle-2.svg";
import { ReactComponent as SettingsIcon } from "@icons/settings.svg";
import { ReactComponent as SwipeIcon } from "@icons/swipe.svg";
import { ReactComponent as PlusCircle } from "@icons/plus-circle.svg";
import { ReactComponent as MinusCircle } from "@icons/minus-circle.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet.svg";
import { ReactComponent as HelpCircle } from "@icons/help-circle.svg";
import { ReactComponent as XCircle } from "@icons/x-circle.svg";
import { ReactComponent as DownloadCloud } from "@icons/download-cloud.svg";
import { ReactComponent as Check } from "@icons/check.svg";
import { ReactComponent as MoreHorizontal } from "@icons/more-horizontal.svg";
import { ReactComponent as Plus } from "@icons/plus.svg";
import { ReactComponent as Cross } from "@icons/x.svg";
import { ReactComponent as Percent } from "@icons/percent.svg";

export const ICON_COLLECTION = {
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  SearchIcon: SearchIcon,
  bookmark: Bookmark,
  bookmarkFilled: BookmarkFilled,
  circleCheck: CircleCheck,
  settings: SettingsIcon,
  swipeIcon: SwipeIcon,
  plusCircle: PlusCircle,
  minusCircle: MinusCircle,
  walletIcon: WalletIcon,
  helpCircle: HelpCircle,
  xCircle: XCircle,
  downloadCloud: DownloadCloud,
  check: Check,
  moreHorizontal: MoreHorizontal,
  plus: Plus,
  cross: Cross,
  percent: Percent,
};

type Keys = keyof typeof ICON_COLLECTION;
export type IconCollectionType = typeof ICON_COLLECTION[Keys];
