import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@/components/dropdown/dropdown";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import Slider from "rc-slider";
import { useTheme } from "@/components/theme-context/theme-context";

interface Props {
  rangeValue: number[];
  setRangeValue: (value: number[]) => void;
}

const FeeRangePicker = ({ rangeValue, setRangeValue }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const handleRangeChange = (value: number[] | number) => {
    if (typeof value !== "number") {
      setRangeValue(value);
    }
  };

  return (
    <Dropdown
      title={t("funds-list-table.search-bar.dropdowns.fee")}
      placement="bottom-end"
    >
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center gap-3">
          <div className="flex flex-col w-full">
            <span className="text-gray-800 dark:text-white">From:</span>
            <div className="relative">
              <input
                name="price-range-from-value"
                type="number"
                min={0}
                max={100}
                value={rangeValue[0]}
                onChange={(event) =>
                  setRangeValue([+event.target.value, rangeValue[1]])
                }
                className="py-3 pr-7 px-4 w-full text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="20px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <span className="text-gray-800 dark:text-white">To:</span>
            <div className="relative">
              <input
                name="price-range-to-value"
                type="number"
                min={0}
                max={100}
                value={rangeValue[1]}
                onChange={(event) =>
                  setRangeValue([rangeValue[0], +event.target.value])
                }
                className="py-3 pr-7 px-4 w-full text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="20px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
        </div>

        <div className="px-3">
          <Slider
            range
            min={0}
            max={100}
            step={5}
            value={rangeValue}
            onChange={handleRangeChange}
            className="range-slider mb-3"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default FeeRangePicker;
