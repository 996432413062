import athom from "./athom.png";
import bdoge from "./bdoge.png";
import chimpy from "./chimpy.png";
import claw from "./claw.png";
import cnft from "./cnft.png";
import fractals from "./fractals.png";
import hosky from "./hosky.png";
import leaf from "./leaf.png";
import nftc from "./nftc.png";

export const TOKENS_ICONS = {
  athom: athom,
  bdoge: bdoge,
  chimpy: chimpy,
  claw: claw,
  cnft: cnft,
  fractals: fractals,
  hosky: hosky,
  leaf: leaf,
  nftc: nftc,
};
