import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLockedBody } from "usehooks-ts";

import { Layout } from "@/layout/layout";
import { Loader } from "@ui/loader/loader";
import { VioletButton } from "@ui/violet-button/violet-button";

import { ListItem } from "@/pages/confirmation/components/list-item";
import { TokenItem } from "@/pages/confirmation/components/token-item";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";

import { CreateFundFormType } from "@/pages/create-fund/types";

export const Confirmation = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (!state) {
      navigate(-1);
      return;
    }

    navigate("/create", { state });
  };

  useLockedBody(loading);

  if (!state) {
    return (
      <Layout>
        <div className="min-h-screen w-full gap-7 py-8 md:pb-16 px-3 md:px-16 dark:bg-dark-bg">
          <h1 className="text-5xl font-semibold dark:text-white">
            Oops.. Seems like something went wrong.
          </h1>
          <button
            className="dark:text-white hover:text-gray-600 dark:hover:text-gray-600 flex items-center justify-end gap-1 px-6 py-2 rounded-full border border-gray-800 hover:border-gray-500 dark:border-gray-400 dark:hover:border-gray-600 transition-colors duration-300"
            onClick={handleGoBack}
          >
            <Icon iconSize="18px" icon={ICON_COLLECTION.arrowLeft} />
            <span className="text-lg">Go Back</span>
          </button>
        </div>
      </Layout>
    );
  }

  const {
    fundName,
    description,
    creatorName,
    address,
    fee,
    commission,
    hide,
    ada,
    selectedTokenIds,
    selectedTokens,
  } = state as CreateFundFormType;

  const handleCreateFund = () => {
    //todo: API mutation call.
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // to prevent possibility going back to the confirmation page.
      navigate("/create", { state: null, replace: true });
    }, 1000);
  };

  return (
    <Layout>
      {loading ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-dark-bg/70">
          <Loader />
        </div>
      ) : null}
      <div className="min-h-screen w-full flex flex-col gap-7 py-8 md:pb-16 px-3 md:px-16 dark:bg-dark-bg">
        <div className="flex flex-col md:flex-row gap-3 items-center justify-between">
          <button
            className="dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-600 flex items-center justify-center md:justify-end gap-1 w-full md:w-fit px-6 py-2 rounded-full border border-gray-800 hover:border-gray-500 dark:border-gray-400 dark:hover:border-gray-600 transition-colors duration-300"
            disabled={loading}
            onClick={handleGoBack}
          >
            <Icon iconSize="24px" icon={ICON_COLLECTION.arrowLeft} />
            <span className="font-sf-pro text-l font-bold">Go Back</span>
          </button>

          <VioletButton
            text="Create Fund"
            icon={ICON_COLLECTION.arrowRight}
            disabled={loading}
            onClick={handleCreateFund}
            iconClass="white-icon"
            className="w-full md:w-auto px-6 py-2.5"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-6 w-full">
          <div className="h-full w-full md:w-1/2 p-7 rounded-lg bg-white dark:bg-gray-900 border dark:border-gray-800">
            <h2 className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white">
              Summary
            </h2>
            <ul className="dark:text-white flex flex-col gap-6 mt-8">
              <li>
                <ListItem
                  valueNoWrap
                  label={t("create-fund-form.input-title.name")}
                  value={fundName}
                />
              </li>
              <li>
                <ListItem
                  label={t("create-fund-form.input-title.description")}
                  value={description}
                />
              </li>
              {!hide ? (
                <li>
                  <ListItem
                    valueNoWrap
                    label={t("create-fund-form.input-title.creator")}
                    value={creatorName}
                  />
                </li>
              ) : null}
              <li>
                <ListItem
                  valueNoWrap
                  label="ADA added to the fund"
                  value={`${ada} ADA`}
                />
              </li>
              {commission ? (
                <>
                  <li>
                    <ListItem
                      label={t("create-fund-form.input-title.address")}
                      value={address}
                    />
                  </li>
                  <li>
                    <ListItem
                      valueNoWrap
                      label={t("create-fund-form.input-title.fee")}
                      value={`${fee} %`}
                    />
                  </li>
                </>
              ) : null}
            </ul>
          </div>
          <div className="h-full w-full md:w-1/2 p-7 rounded-lg bg-white dark:bg-gray-900 border dark:border-gray-800">
            <h2 className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white">
              Selected tokens
            </h2>
            <div className="flex items-center justify-between w-full mt-8 py-2 text-s md:text-m font-sf-pro font-medium text-gray-400">
              <span>{t("create-fund-tokens.selected.title.name")}</span>
              <span>Distribution</span>
            </div>
            <ul className="dark:text-white">
              {selectedTokenIds.map((id) => (
                <li
                  className="border-b border-gray-100 dark:border-gray-800 last:border-b-0"
                  key={id}
                >
                  <TokenItem token={selectedTokens[id]} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};
