import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { BookmarksContext } from "./context";
import { ActualFundType } from "@constants/funds-data";
import { fetchFundsList } from "@/api/overview";

interface Props {
  children: React.ReactNode;
}

const BookmarkProvider = ({ children }: Props) => {
  const [funds, setFunds] = useState<ActualFundType[]>([]);
  const [bookmarks, setBookmarks] = useState<ActualFundType["fund_id"][]>(
    () => {
      const storedBookmarks = localStorage.getItem("bookmarks");
      if (storedBookmarks) {
        return JSON.parse(storedBookmarks) as ActualFundType["fund_id"][];
      }

      return [];
    },
  );

  // mock-up
  const topChangeDaily = (() => {
    const list = [...funds];
    const topChange = list.sort(
      (item_1, item_2) => item_2.price_change24h - item_1.price_change24h,
    );
    return topChange.slice(0, 3);
  })();

  // mock-up
  const topInflow = (() => {
    const list = [...funds];
    const topInflowSorted = list.sort(
      (item_1, item_2) => item_2.inflow_7d - item_1.inflow_7d,
    );
    return topInflowSorted.slice(0, 3);
  })();

  // mock-up
  const topOutflow = (() => {
    const list = [...funds];
    const topOutflowSorted = list.sort(
      (item_1, item_2) => item_2.outflow_7d - item_1.outflow_7d,
    );
    return topOutflowSorted.slice(0, 3);
  })();

  const handleBookmark = (fund_id: ActualFundType["fund_id"]) => {
    setBookmarks((prev) => {
      if (prev.includes(fund_id)) {
        return prev.filter((id) => id !== fund_id);
      }

      return [...prev, fund_id];
    });
  };

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["funds"],
    queryFn: fetchFundsList,
  });

  useEffect(() => {
    if (!(isLoading || isFetching) && !!data) setFunds(data);
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
  }, [bookmarks]);

  return (
    <BookmarksContext.Provider
      value={{
        funds,
        bookmarks,
        topChangeDaily,
        topOutflow,
        topInflow,
        isFundsLoading: isLoading || isFetching,
        handleBookmark,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
};

export default BookmarkProvider;
