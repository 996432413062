import React, { useEffect, useRef } from "react";
import {
  Address,
  BigNum,
  BaseAddress,
  LinearFee,
  TransactionBuilder,
  TransactionBuilderConfigBuilder,
  TransactionOutputs,
  TransactionOutput,
  TransactionUnspentOutput,
  DataCost,
  min_ada_for_output,
  PlutusList,
  PlutusData,
  ConstrPlutusData,
  AuxiliaryData,
  GeneralTransactionMetadata,
  encode_json_str_to_metadatum,
  hash_script_data,
  Costmdls,
  Transaction,
  Redeemers,
  TxInputsBuilder,
  TransactionWitnessSet,
  TransactionInput,
  TransactionHash,
  Redeemer,
  RedeemerTag,
  BigInt,
  ExUnits,
  hash_plutus_data,
  Ed25519KeyHash,
  PlutusWitness,
  TxBuilderConstants,
  UnitInterval,
  ExUnitPrices,
  DatumSource,
  PlutusScriptSource,
  PlutusScript,
} from "@emurgo/cardano-serialization-lib-asmjs";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import {
  toHex,
  fromHex,
  assetsToValue,
} from "@/providers/cardano-serializer-provider/helpers/utils";

import { CardanoSerializerContext } from "@/providers/cardano-serializer-provider/context";
import {
  getProtocolParameters,
  testnetFeeAddr,
  testnetCreatorFeeAddr,
  mainnetAddr,
  requestScript,
} from "@/providers/cardano-serializer-provider/constants";

import {
  Cardano,
  WalletApi,
} from "@/providers/cardano-serializer-provider/types";

import CoinSelection from "@/providers/cardano-serializer-provider/helpers/coinSelection.js";

// todo: add types

interface Props {
  children: React.ReactNode;
}
const CardanoSerializerProvider = ({ children }: Props) => {
  const { enabledWallet } = useCardano({ limitNetwork: NetworkType.MAINNET });

  /**
   * When the wallet is connect it returns the connector which is
   * written to this API variable and all the other operations
   * run using this API object
   */
  const APIRef = useRef<WalletApi | null>(null);

  /**
   * Update API reference when the wallet is connected
   */
  const updateAPIRef = async () => {
    try {
      if (!("cardano" in window)) {
        throw new Error("Cardano is not defined");
      }

      const cardano = window.cardano as Cardano;

      if (enabledWallet) {
        APIRef.current = await cardano[enabledWallet].enable();
      }
    } catch (e) {
      console.error("updateAPIRef ERROR >>", e);
      return "";
    }
  };

  const initTransactionBuilder = async () => {

    const protocolParams = await getProtocolParameters()
    var Fraction = require('fractional').Fraction

    const priceMemFranction = new Fraction(protocolParams.priceMem)
    const priceMemFranctionNumerator = BigNum.from_str(priceMemFranction.numerator.toString())
    const priceMemFranctionDenominator = BigNum.from_str(priceMemFranction.denominator.toString())
    const priceMem = UnitInterval.new(priceMemFranctionNumerator, priceMemFranctionDenominator)
  
    const priceStepFranction = new Fraction(protocolParams.priceStep)
    const priceStepFranctionNumerator = BigNum.from_str(priceStepFranction.numerator.toString())
    const priceStepFranctionDenominator = BigNum.from_str(priceStepFranction.denominator.toString())
  
    const priceStep = UnitInterval.new(priceStepFranctionNumerator, priceStepFranctionDenominator)
  
    const exUnitPrices = ExUnitPrices.new(priceMem, priceStep)
  
    return TransactionBuilder.new(
      TransactionBuilderConfigBuilder.new()
        .fee_algo(
          LinearFee.new(
            BigNum.from_str(protocolParams.linearFee.minFeeA),
            BigNum.from_str(protocolParams.linearFee.minFeeB),
          ),
        )
        .pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
        .key_deposit(BigNum.from_str(protocolParams.keyDeposit))
        .coins_per_utxo_word(BigNum.from_str(protocolParams.coinsPerUtxoByte))
        .max_value_size(protocolParams.maxValSize)
        .max_tx_size(protocolParams.maxTxSize)
        .prefer_pure_change(true)
        .ex_unit_prices(exUnitPrices)
        .build(),
    );
  };

  const initTx = async () => {
    const txBuilder = await initTransactionBuilder();

    const datums = PlutusList.new();
    const metadata = {};
    const outputs = TransactionOutputs.new();

    return { txBuilder, datums, metadata, outputs };
  };

  const createOutput = (address, value, datum, datumHash) => {
    const v = value;

    const output = TransactionOutput.new(address, v);

    if (datum) {
      output.set_data_hash(datumHash);
    }

    const dataCost = DataCost.new_coins_per_byte(BigNum.from_str("4310"));
    const minAda = min_ada_for_output(output, dataCost);

    if (minAda.compare(v.coin()) === 1) v.set_coin(minAda);

    const outputFinal = TransactionOutput.new(address, v);

    if (datum) {
      outputFinal.set_data_hash(datumHash);
    }

    return outputFinal;
  };

  function createOutputInlineDatum(address, value, datum) {
    const v = value;
  
    const output = TransactionOutput.new(address, v);
  
    if (datum) {
      output.set_plutus_data(datum);
    }
  
    const dataCost = DataCost.new_coins_per_byte(BigNum.from_str("4310"));
    const minAda = min_ada_for_output(output, dataCost);
  
    if (minAda.compare(v.coin()) == 1) v.set_coin(minAda);
  
    const outputFinal = TransactionOutput.new(address, v);
  
    if (datum) {
      outputFinal.set_plutus_data(datum);
    }
  
    return outputFinal;
  }
  

  async function finalizeTX(
    txBuilder,
    changeAddress,
    utxos,
    outputs,
    datums,
    metadata,
    scriptUtxo,
    action,
    embedDatum = false,
    setCollateral = false,
  ) {

    const protocolParams = await getProtocolParameters()

    // add outputs
    for (let i = 0; i < outputs.len(); i++) {
      txBuilder.add_output(outputs.get(i));
    }

    let aux_data;
    // add metadata
    if (metadata) {
      aux_data = AuxiliaryData.new();
      const generalMetadata = GeneralTransactionMetadata.new();
      Object.keys(metadata).forEach((label) => {
        generalMetadata.insert(
          BigNum.from_str(label),
          encode_json_str_to_metadatum(JSON.stringify(metadata[label]), 1),
        );
      });
      aux_data.set_metadata(generalMetadata);
      txBuilder.set_auxiliary_data(aux_data);
    }

    CoinSelection.setProtocolParameters(
      protocolParams.minUtxo,
      protocolParams.linearFee.minFeeA,
      protocolParams.linearFee.minFeeB,
      protocolParams.maxTxSize.toString(),
      protocolParams.coinsPerUtxoByte.toString(),
    );

    let { input, change } = CoinSelection.randomImprove(
      utxos,
      outputs,
      14,
      scriptUtxo ? [scriptUtxo] : [],
    );

    if (scriptUtxo) {
      input.forEach((utxo) => {
        if (
          utxo.input().transaction_id().to_hex() !==
            scriptUtxo.input().transaction_id().to_hex() ||
          utxo.input().index() !== scriptUtxo.input().index()
        ) {
          txBuilder.add_input(
            utxo.output().address(),
            utxo.input(),
            utxo.output().amount(),
          );
        }
      });
    } else {
      input.forEach((utxo) => {
        txBuilder.add_input(
          utxo.output().address(),
          utxo.input(),
          utxo.output().amount(),
        );
      });
    }

    if (scriptUtxo || setCollateral) {
      const modelsArray = Object.values(protocolParams.costModels);

      const costmdls = TxBuilderConstants.plutus_vasil_cost_models();
      txBuilder.calc_script_data_hash(costmdls);


      var walletCollateral;

      if(enabledWallet === 'nami') {
        walletCollateral = await APIRef.current?.experimental.getCollateral();
      } else {
        walletCollateral = await APIRef.current?.getCollateral();
      }

      if (!walletCollateral) throw Error("WALLET COLLATERAL MISSING");

      var collateralUtxos = walletCollateral.map((utxo) =>
        TransactionUnspentOutput.from_bytes(fromHex(utxo)),
      );
      if (!collateralUtxos?.length || !collateralUtxos[0])
        throw Error("WALLET COLLATERAL MISSING");

      var collateralUtxo = collateralUtxos[0];

      const txInputsBuilder = TxInputsBuilder.new();
      txInputsBuilder.add_input(
        collateralUtxo.output().address(),
        collateralUtxo.input(),
        collateralUtxo.output().amount(),
      );

      txBuilder.set_collateral(txInputsBuilder);
    }

    if (embedDatum && datums && !action) {
      const costmdls = Costmdls.new();
      const redeemers = Redeemers.new();
      const datumsTmp = PlutusList.from_bytes(datums.to_bytes());
      const scriptDataHash = hash_script_data(redeemers, costmdls, datumsTmp);
      txBuilder.set_script_data_hash(scriptDataHash);
    } 

    txBuilder.add_change_if_needed(changeAddress.to_address());

    const costmdls = Costmdls.from_json(JSON.stringify(protocolParams.costModels))
    txBuilder.calc_script_data_hash(costmdls)

    const buildTransaction = txBuilder.build_tx();
    const witnessSetTmp = buildTransaction.witness_set();

    const tmpTransaction = Transaction.new(
      buildTransaction.body(),
      witnessSetTmp,
      buildTransaction.auxiliary_data(),
    );


    const unsignedTxCBOR = toHex(tmpTransaction.to_bytes());

    let txVkeyWitnesses = await APIRef.current?.signTx(unsignedTxCBOR, true);
    if (!txVkeyWitnesses) throw Error("txVkeyWitnesses is missing");

    const txVkeyWitnessesNew = TransactionWitnessSet.from_bytes(
      fromHex(txVkeyWitnesses),
    );

    const witnessSet = tmpTransaction.witness_set();
    witnessSet.set_vkeys(txVkeyWitnessesNew.vkeys()!);


    const signedTx = Transaction.new(
      tmpTransaction.body(),
      witnessSet,
      tmpTransaction.auxiliary_data(),
    );

    const txCbor = signedTx.to_bytes();
    const txHash = await APIRef.current?.submitTx(toHex(txCbor));

    return { txHash, txCbor };
  }


function Credential(isScript, credentialHash) {
    const scriptList = PlutusList.new();
    scriptList.add(PlutusData.new_bytes(fromHex(credentialHash)));
  
    let constructorNumber = "0";
  
    if (isScript) constructorNumber = "1";
  
    return PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(
        BigNum.from_str(constructorNumber),
        scriptList,
      ),
    );
  }

function StakingHash(credential : PlutusData) {
    const credentialList = PlutusList.new();
    credentialList.add(credential);
  
    const obj = PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(BigNum.from_str("0"), credentialList),
    );
  
    const outerCredentialList = PlutusList.new();
    outerCredentialList.add(obj);
  
    return PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(BigNum.from_str("0"), outerCredentialList),
    );
}
  
function NoStakingCredential() {
    return PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(
        BigNum.from_str("1"),
        PlutusList.new(),
      ),
    );
}

function AddressObj(isScript, pubKeyHash, stakeKeyHash) {
  const credentialList = PlutusList.new();

  const pubKeyHashCredential = Credential(isScript, pubKeyHash);

  let stakeKeyHashCredential = NoStakingCredential();

  if (stakeKeyHash != undefined) {
    const stakingCredential = Credential(false, stakeKeyHash);
    stakeKeyHashCredential = StakingHash(stakingCredential);
  }

  credentialList.add(pubKeyHashCredential);
  credentialList.add(stakeKeyHashCredential);

  const addressData = PlutusData.new_constr_plutus_data(
    ConstrPlutusData.new(BigNum.from_str("0"), credentialList),
  );

  return addressData;
}


function Token(tokenPolicyId , tokenNameHex) {
    const tokenList = PlutusList.new();

    tokenList.add(PlutusData.new_bytes(fromHex(tokenPolicyId)));
    tokenList.add(PlutusData.new_bytes(fromHex(tokenNameHex)));

    const tokenData = PlutusData.new_constr_plutus_data(
        ConstrPlutusData.new(BigNum.from_str("0"), tokenList),
    );

    return tokenData;
}


const getDatum = (pubKeyHash, stakeKeyHash) => {
    
    const list = PlutusList.new();

    const pubKeyHashBytes = PlutusData.new_bytes(fromHex(pubKeyHash));
    list.add(pubKeyHashBytes);

    const owner = AddressObj(false, pubKeyHash, stakeKeyHash);
    list.add(owner);

    const fundTokenList = PlutusList.new();
    const fundTokenListData = PlutusData.new_list(fundTokenList);
    list.add(fundTokenListData);

    const status = 0;
    list.add(PlutusData.new_integer(BigInt.from_str("0")));

    const paymentToken = Token("", "");
    list.add(paymentToken);

    const paymentAmount = PlutusData.new_integer(BigInt.from_str("1"));
    list.add(paymentAmount);

    const datum =  PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(BigNum.from_str("0"), list),
    );

    return datum;
  };

  // Redeemer
  const CANCEL = (index) => {
    const redeemerData = PlutusData.new_constr_plutus_data(
      ConstrPlutusData.new(BigNum.from_str("2"), PlutusList.new()),
    );

    const redeemer = Redeemer.new(
      RedeemerTag.new_spend(),
      BigNum.from_str(index),
      redeemerData,
      // increased from 650000 to 700000 as people couldnt cancel

      ExUnits.new(BigNum.from_str("1000000"), BigNum.from_str("500000000")),
    );

    return redeemer;
  };

  const getPubKeyHash = async () => {
    if (APIRef.current === null) {
      return null;
    }

    const changeAddress = await APIRef.current.getChangeAddress();

    const walletAddress = BaseAddress.from_address(
      Address.from_bytes(fromHex(changeAddress)),
    );

    var oCreator =
      walletAddress?.payment_cred()?.to_keyhash()?.to_bytes() ??
      "default_value";

    return toHex(oCreator);
  }

  function constructStakingUTXO(
    utxoHash,
    utxoId,
    lovelaceAttached,
    address,
  ) {
  
    var assets = [
      {
        unit: 'lovelace',
        quantity: lovelaceAttached.toString(),
      },
    ]
  
    const utxo = TransactionUnspentOutput.new(
      TransactionInput.new(
        TransactionHash.from_bytes(fromHex(utxoHash)),
        Number(utxoId.toString()),
      ),
      TransactionOutput.new(address, assetsToValue(assets)),
    )
    return utxo
  }


  const placeFundRequests = async (fundName, adaFundAmount, adaInterfaceFee, adaCreatorFee) => {
    if (APIRef.current === null) {
      throw new Error("Wallet is not connected!");
    }

    const lovelaceStakeAmount = Math.round(adaFundAmount * 1e6);

    const { txBuilder, datums, metadata, outputs } = await initTx();

    const changeAddress = await APIRef.current.getChangeAddress();

    const walletAddress = BaseAddress.from_address(
      Address.from_bytes(fromHex(changeAddress)),
    );

    var pubKeyHash =  walletAddress?.payment_cred()?.to_keyhash()?.to_bytes() ?? "default_value";
    var stakeKeyHash =  walletAddress?.stake_cred()?.to_keyhash()?.to_bytes() ?? "default_value";

    var datum = getDatum(toHex(pubKeyHash), toHex(stakeKeyHash));
    var hash = hash_plutus_data(datum);
    const utxos = ((await APIRef.current.getUtxos()) ?? []).map((utxo) =>
      TransactionUnspentOutput.from_bytes(fromHex(utxo)),
    );

    const newMetatdata = {
      [674]: {},
      [675]: {},
      [676]: {},
    };

    newMetatdata[674] = { msg: ["Linkage Finance: Place Fund Request"] };
    newMetatdata[675] = fundName;
    newMetatdata[676] = toHex(pubKeyHash);

    var assets = [
      {
        unit: "lovelace",
        quantity: lovelaceStakeAmount.toString(),
      },
    ];

    var output = createOutputInlineDatum(mainnetAddr, assetsToValue(assets), datum);
    outputs.add(output);
    //datums.add(datum);

    var feeAssets = [
      {
        unit: "lovelace",
        quantity: Math.round(adaInterfaceFee * 1e6).toString(),
      },
    ];

    var interfaceFeeOutput = createOutput(testnetFeeAddr, assetsToValue(feeAssets), null, null);
    //outputs.add(interfaceFeeOutput);

    var creatorFeeAssets = [
      {
        unit: "lovelace",
        quantity: Math.round(adaCreatorFee * 1e6).toString(),
      },
    ];

    if(adaCreatorFee > 0) {
      var creatorFeeOutput = createOutput(testnetCreatorFeeAddr, assetsToValue(creatorFeeAssets), null, null);
      //outputs.add(creatorFeeOutput);  
    }

    console.log('outputs', outputs.to_hex())

    const answer =  finalizeTX(
      txBuilder,
      walletAddress,
      utxos,
      outputs,
      datums,
      newMetatdata, //newMetadata
      null,
      null,
      false,
    );

    return answer;
  };

  const cancelFundRequest = async (
    utxoHash,
    utxoId,
    lovelaceAttached,
  ) => {
    if (APIRef.current === null) {
      throw new Error("Wallet is not connected!");
    }
    
    const { txBuilder, datums, metadata, outputs } = await initTx()
    
    const changeAddress = await APIRef.current.getChangeAddress();

    const walletAddress = BaseAddress.from_address(Address.from_bytes(fromHex(changeAddress)))

    const utxos = ((await APIRef.current.getUtxos()) ?? []).map((utxo) =>
      TransactionUnspentOutput.from_bytes(fromHex(utxo)),
    );

    var pubKeyHash =
      walletAddress?.payment_cred()?.to_keyhash()?.to_bytes() ??
      "default_value";

    var stakeKeyHash =
      walletAddress?.payment_cred()?.to_keyhash()?.to_bytes() ??
      "default_value";

    lovelaceAttached = Math.round(lovelaceAttached)
  
    const stakeUTXO = constructStakingUTXO(
      utxoHash,
      utxoId,
      lovelaceAttached,
      mainnetAddr,
    )
  
  const datum = getDatum(toHex(pubKeyHash), toHex(stakeKeyHash));  
  
    // add required signers
    if (walletAddress && walletAddress.payment_cred()) {
      const paymentCred = walletAddress.payment_cred(); // Store the payment_cred to avoid calling it multiple times
      const keyHash = paymentCred.to_keyhash(); // Attempt to get the key hash
  
      if (keyHash) { // Check if keyHash is not undefined
          const keyHashBytes = keyHash.to_bytes(); // Now it's safe to call to_bytes
          const ed25519KeyHash = Ed25519KeyHash.from_bytes(keyHashBytes);
          txBuilder.add_required_signer(ed25519KeyHash);
      }
  }      
    
    const datumSource = DatumSource.new_ref_input(stakeUTXO.input())
    const redeemer = CANCEL('0')
    const plutusScriptSource = PlutusScriptSource.new(requestScript)

    console.log('compute script hash', requestScript.hash().to_hex())
    
    const plutusWitness = PlutusWitness.new_with_ref(plutusScriptSource, datumSource, redeemer)
    txBuilder.add_plutus_script_input(plutusWitness, stakeUTXO.input(), stakeUTXO.output().amount())

    return finalizeTX(txBuilder, walletAddress, utxos, outputs, datums, null, stakeUTXO, CANCEL)
  }
  

  useEffect(() => {
    updateAPIRef();
  }, [enabledWallet]);

  return (
    <CardanoSerializerContext.Provider value={{ placeFundRequests, getPubKeyHash, cancelFundRequest }}>
      {children}
    </CardanoSerializerContext.Provider>
  );
};

export default CardanoSerializerProvider;
