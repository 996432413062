import { TOP_FUND_COLLECTION } from "@/assets/icons/top-funds/iconsConstant";


export const POSITIONS_HOSTORY_DATA = [
  {
    count: {
      quantity: 19,
      countIcon_1: TOP_FUND_COLLECTION.topFund_1_1,
      countIcon_2: TOP_FUND_COLLECTION.topFund_1_2,
    },
    title: "Claire",
    featured: true,
    bookmarked: false,
    verified: true,
    action: "Added",
    quantity: 250,
    date: "12.12.2023",
    fee: 2,
  },
  {
    count: {
      quantity: 11,
      countIcon_1: TOP_FUND_COLLECTION.topFund_2_1,
      countIcon_2: TOP_FUND_COLLECTION.topFund_2_2,
    },
    title: "Austin",
    featured: true,
    bookmarked: false,
    verified: true,
    action: "Removed",
    quantity: 40,
    date: "15.12.2023",
    fee: 1,
  },
].map((fund, idx) => ({ ...fund, id: idx }));
