import React from "react";
import { Layout } from "@/layout/layout";
import { VioletButton } from "@ui/violet-button/violet-button";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { SelectTokensFilled } from "./components/select-tokens/select-tokens-filled";
import { CreateFundFormFilled } from "./components/create-fund-form/create-fund-form-filled";

export const CreateFundFilled = () => {
  return (
    <Layout>
      <div className="h-full w-full flex flex-col gap-7 py-8 md:pb-14 px-3 md:px-16 dark:bg-dark-bg">
        <div className="flex flex-col md:flex-row gap-6 w-full">
          <CreateFundFormFilled />
          <SelectTokensFilled />
        </div>
        <div className="flex w-full justify-end">
          <VioletButton
            text="Create fund"
            icon={ICON_COLLECTION.arrowRight}
            iconClass={"white-icon"}
            className="w-full md:w-auto px-32px py-12px"
          />
        </div>
      </div>
    </Layout>
  );
};
