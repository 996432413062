import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useOnClickOutside } from "usehooks-ts";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";

const ACTIONS = {
  added: "add",
  removed: "rem",
} as const;
export type ActionValue = typeof ACTIONS[keyof typeof ACTIONS];

const actionsSelectOptions = [
  { value: ACTIONS.added, label: "Added" },
  { value: ACTIONS.removed, label: "Removed" },
];

interface Props {
  selectedActions: ActionValue[];
  handleSelect: (action: ActionValue) => void;
}

export const PositionActionsFilters = ({
  selectedActions,
  handleSelect,
}: Props) => {
  const { theme } = useTheme();

  const popperRef = useRef(null);
  const buttonRef = useRef(null);

  const [isActionOpen, setIsActionOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-end",
  });

  useOnClickOutside(popperRef, () => setIsActionOpen(false));

  return (
    <div ref={popperRef}>
      <button
        ref={buttonRef}
        onClick={() => setIsActionOpen((prev) => !prev)}
        className="py-8px px-12px flex items-center gap-8px h-full rounded-xl relative"
        style={{
          border: isActionOpen
            ? theme === "dark"
              ? "1px solid #1F2937"
              : "1px solid #A78BFA"
            : theme === "dark"
            ? "1px solid #374151"
            : "1px solid #E5E7EB",
          backgroundColor: isActionOpen
            ? theme === "dark"
              ? "#1F2937"
              : "transparent"
            : "transparent",
        }}
      >
        <div className="font-sf-pro flex items-center gap-8px">
          <span className="text-l text-gray500 dark:text-gray-300 font-medium">
            Action:
          </span>
          {selectedActions.map((action, index) => (
            <div
              key={index}
              className="flex h-full items-center justify-center gap-8px px-8px p-1 rounded bg-violet-200 dark:bg-gray-600 cursor-pointer"
            >
              <span className="font-sf-pro text-s font-bold text-violet-600 dark:text-gray-100">
                {
                  actionsSelectOptions.find((item) => item.value === action)
                    ?.label
                }
              </span>
              <div
                role="button"
                className="flex items-center justify-center"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelect(action);
                }}
              >
                <Icon
                  icon={ICON_COLLECTION.cross}
                  iconSize="16px"
                  iconColor={theme === "dark" ? "#F3F4F6" : "#5B21B6"}
                  hoverColor="#DC2626"
                />
              </div>
            </div>
          ))}
        </div>
        <div
          className="flex items-center justify-center"
          style={{
            transform: isActionOpen ? "rotate(180deg)" : "",
          }}
        >
          <Icon
            icon={ICON_COLLECTION.chevronDown}
            className="height-24"
            iconColor={theme === "dark" ? "#fff" : "#000"}
          />
        </div>
      </button>
      {isActionOpen && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className={clsx(
            "z-20 dialog-sheet bg-white dark:bg-gray-950 mt-2 rounded-xl shadow-lg shadow-gray-300 dark:shadow-gray-800 p-12px flex flex-col gap-1 w-56 font-sf-pro text-l font-medium text-gray-800 dark:text-gray-500",
          )}
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
          aria-label="Action Dropdown"
        >
          {actionsSelectOptions.map((action) => (
            <button
              key={action.value}
              className={twMerge(
                clsx(
                  "w-full rounded p-8px flex items-center gap-8px hover:bg-violet-50 dark:hover:bg-gray-800 transition-colors duration-300",
                  {
                    "bg-violet-50 dark:bg-gray-800 dark:text-white":
                      selectedActions.includes(action.value),
                  },
                ),
              )}
              onClick={() => handleSelect(action.value)}
            >
              {selectedActions.includes(action.value) && (
                <Icon
                  icon={ICON_COLLECTION.check}
                  iconColor={theme === "dark" ? "#6D28D9" : "#7C3AED"}
                />
              )}
              <span>{action.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
