import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";

interface Props {
  token: Token;
  handleSelect: (token: Token) => void;
  style: React.CSSProperties;
}

export const TokensTableRow: React.FC<Props> = ({
  token,
  handleSelect,
  style,
}) => {
  const [showFullId, setShowFullId] = useState(false);
  const truncatedId = `${token.policy_id.slice(0, 3)}...${token.policy_id.slice(
    -6,
  )}`;

  const onMouseOver = () => setShowFullId(true);
  const onMouseOut = () => setShowFullId(false);

  return (
    <button
      style={style}
      onClick={() => handleSelect(token)}
      className="flex items-center justify-between w-full py-3 border-b border-gray-100 dark:border-gray-800"
    >
      <div className="flex items-center gap-2">
        <img src={token.token_image} alt="" className="h-12" />
        <h6 className="font-sf-pro text-lg md:text-xl text-gray-800 dark:text-white font-semibold">
          {token.token_symbol}
        </h6>
        {/*{token.verified ? (*/}
        {/*  <Icon icon={ICON_COLLECTION.circleCheck} iconColor="#7C3AED" />*/}
        {/*) : null}*/}
      </div>
      <span
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={twMerge(
          "font-sf-pro text-sm md:text-base text-gray-500",
          showFullId && "bg-white dark:bg-gray-900 z-10",
        )}
      >
        {showFullId ? token.policy_id : truncatedId}
      </span>
    </button>
  );
};
