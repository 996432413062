import { DETAILS_CHARTS } from "@/constants/fund-details";
import React from "react";
import { FundsDetailsChartItem } from "./funds-details-chart-item/funds-details-chart-item";
import { Loader } from "@/UI/loader/loader";

export const FundsDetailsCharts = ({ isLoading }) => {
  return (
    <div className="w-full md:w-1/3 h-full flex flex-col gap-16px md:gap-24px">
      {DETAILS_CHARTS.map((item, index) => (
        <>
          {isLoading ? (
            <div className="w-full h-full flex flex-col gap-24px justify-between p-16px rounded-lg bg-white dark:bg-gray-900">
              <Loader />
            </div>
          ) : (
            <FundsDetailsChartItem key={index} item={item} />
          )}
        </>
      ))}
    </div>
  );
};
