import { useTheme } from "@/components/theme-context/theme-context";
import { MultiSelect } from "../multi-select/multi-select";
import i18next from "i18next";
import * as React from "react";

export const SelectLang = () => {
  const { theme } = useTheme();

  const handleChange = (value) => {
    i18next.changeLanguage(value.value);
  };

  const langSelectOptions = [{ value: "en", label: "ENG" }];
  return (
    <>
      <MultiSelect
        id="lang"
        name="lang"
        options={langSelectOptions}
        onChange={handleChange}
        placeholder={"ENG"}
        isSearchable={false}
        styles={{
          input: (base) => ({
            ...base,
            padding: "0",
            margin: "0",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "16px",
            color: theme === "dark" ? "#fff" : "#000",
          }),
          option: (base) => ({
            ...base,
            cursor: "pointer",
            backgroundColor: theme === "dark" ? "rgb(17, 24, 39)" : "#fff",
            paddingTop: "10px",
            paddingBottom: "10px",
            color: theme === "dark" ? "#fff" : "#000",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: theme === "dark" ? "#0a1636" : "#D9D9D9",
            },
            "&:active": {
              backgroundColor: theme === "dark" ? "#0a1636" : "#D9D9D9",
            },
          }),
          singleValue: (base) => ({
            ...base,
            width: "35px",
            fontSize: "16px",
            color: theme === "dark" ? "#fff" : "#000",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            width: "35px",
            cursor: "pointer",
          }),
          control: (base, state) => ({
            ...base,
            paddingLeft: "5px",
            minWidth: "50px",
            border: state.menuIsOpen
              ? theme === "dark"
                ? "1px solid #1F2937 !important"
                : "1px solid #A78BFA !important"
              : theme === "dark"
              ? "1px solid #374151 !important"
              : "1px solid #E5E7EB !important",
            borderRadius: "10px",
            outline: "none",
            boxShadow: "0 !important",
            backgroundColor: state.menuIsOpen
              ? theme === "dark"
                ? "#1F2937"
                : "transparent"
              : "transparent",
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: theme === "dark" ? "rgb(17, 24, 39)" : "#fff",
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            cursor: "pointer",
            transform: state.selectProps.menuIsOpen
              ? "rotate(180deg)"
              : "rotate(0)",
          }),
        }}
      />
    </>
  );
};
