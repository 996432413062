import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

import { FundInteractionsFundsTable } from "@/pages/fund-interactions/components/fund-interections-funds-table/fund-interections-funds-table";
import { Loader } from "@ui/loader/loader";
import { SwipeToSeeMore } from "@ui/swipe/swipe";
import { Search } from "@ui/search/search";
import { useFunds } from "@/providers/bookmarks-provider/hooks/use-fund";

export const FundInteractionsMain = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(() => {
    return searchParams.get("q") ?? "";
  });

  const { funds, isFundsLoading } = useFunds();

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("q", debouncedSearchValue);

    setSearchParams(newParams);
  }, [debouncedSearchValue]);

  const filteredList = useMemo(() => {
    return funds.filter((item) =>
      item.fund_name.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
  }, [debouncedSearchValue, funds]);

  return (
    <section className="w-11/12 md:w-full p-24px bg-white dark:bg-dark-gray900 rounded-lg flex flex-col overflow-hidden md:overflow-visible">
      <h3 className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white mb-32px">
        {t("funds-interactions.title")}
      </h3>
      <Search
        isExpand={false}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <div className="w-full flex justify-between items-center mb-32px pt-24px">
        <h3 className="font-sf-pro text-xl font-semibold text-gray-800 dark:text-white">
          {t("funds-interactions.table.title")}
        </h3>
      </div>
      {isFundsLoading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full overflow-x-scroll md:overflow-x-auto pb-4">
            <FundInteractionsFundsTable data={filteredList} />
          </div>
          <SwipeToSeeMore />
        </>
      )}
    </section>
  );
};
