import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useWindowSize } from "usehooks-ts";

import { Icon } from "@ui/icons/icon";
import { Loader } from "@ui/loader/loader";
import { useTheme } from "@/components/theme-context/theme-context";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { FundDetailsDiagram } from "./fund-details-diagram/fund-details-diagram";
import { FundsDetailsCharts } from "./fund-details-charts/funds-details-charts";
import { fetchFundDetails } from "@/api/fund-details";

const colors = [
  "#5B21B6",
  "#6D28D9",
  "#7C3AED",
  "#8B5CF6",
  "#A78BFA",
  "#C4B5FD",
  "#DDD6FE",
];

interface Props {
  chartRef: React.RefObject<ChartJSOrUndefined<"pie", any, unknown>>;
}

export const FundsDetailsInfo = ({ chartRef }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { width } = useWindowSize();

  const isMobile = width < 992;

  const { fund_id } = useParams();

  const {
    data: fund,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fund-details", fund_id],
    queryFn: fetchFundDetails,
    enabled: !!fund_id,
  });

  // todo: error message?
  if (!fund) {
    return (
      <div
        className="w-full flex flex-col md:flex-row items-center justify-between gap-16px md:gap-24px"
        style={{ height: isMobile ? "1300px" : "446px" }}
      >
        <div className="w-full md:w-1/3 h-full flex flex-col justify-between p-16px rounded-lg bg-white dark:bg-gray-900">
          <Loader />
        </div>
      </div>
    );
  }

  const chartData = (() => {
    const divideBy = 95 / fund.tokens.length;
    const labels = fund.tokens.map((token) => token.token_symbol);

    // todo: fix palette? auto-generation?
    const palette =
      fund.tokens.length > 7
        ? fund.tokens.map(
            (_, index) =>
              `rgba(91, 33, 182, ${(
                (100 - (index + 1) * divideBy) /
                100
              ).toFixed(2)})`,
          )
        : colors.slice(0, fund.tokens.length);

    const datasets = [
      {
        data: fund.tokens.map((token) => token.token_share),
        backgroundColor: palette,
        borderWidth: 0,
      },
    ];

    return {
      labels,
      datasets,
    };
  })();

  const legendData = (() => {
    const divideBy = 95 / fund.tokens.length;
    const palette =
      fund.tokens.length > 7
        ? fund.tokens.map(
            (_, index) =>
              `rgba(91, 33, 182, ${(
                (100 - (index + 1) * divideBy) /
                100
              ).toFixed(2)})`,
          )
        : colors.slice(0, fund.tokens.length);

    return fund.tokens.map((token, index) => ({
      label: token.token_symbol,
      value: Math.round(token.token_share),
      color: palette[index],
    }));
  })();

  return (
    <div
      className="w-full flex flex-col md:flex-row items-center justify-between gap-16px md:gap-24px"
      style={{ height: isMobile ? "1300px" : "446px" }}
    >
      {isLoading || isFetching ? (
        <div className="w-full md:w-1/3 h-full flex flex-col justify-between p-16px rounded-lg bg-white dark:bg-gray-900">
          <Loader />
        </div>
      ) : (
        <div className="w-full md:w-1/3 h-full flex flex-col justify-between p-16px rounded-lg bg-white dark:bg-gray-900">
          <div className="w-full flex flex-col gap-12px font-sf-pro">
            <h5 className="text-l font-medium text-gray-800 dark:text-white">
              {t("fund-details.info.title")}
            </h5>
            <p className="text-m font-medium text-gray-500 leading-normal">
              {fund.fund_description}
            </p>
          </div>
          <div className="w-full flex flex-col gap-12px">
            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t("fund-details.infoList.total")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>

              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.total_locked.toLocaleString("en")}
              </span>
            </div>

            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t("fund-details.infoList.tokens")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>

              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.tokens.length}
              </span>
            </div>

            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t("fund-details.infoList.price")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>

              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.fund_price.toFixed(2)}
              </span>
            </div>

            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t("fund-details.infoList.inflow")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>

              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.inflow_7d.toLocaleString("en")}
              </span>
            </div>

            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t("fund-details.infoList.outflow")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>

              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.outflow_7d.toLocaleString("en")}
              </span>
            </div>

            {/*<div className="flex items-center font-sf-pro gap-3 cursor-pointer">*/}
            {/*  <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">*/}
            {/*    {t("fund-details.infoList.change")}*/}
            {/*  </h6>*/}
            {/*  <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>*/}

            {/*  <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">*/}
            {/*    <span*/}
            {/*      className={clsx(*/}
            {/*        "text-l h-full font-medium whitespace-nowrap flex gap-1",*/}
            {/*        {*/}
            {/*          "text-green-500 dark:text-green-500":*/}
            {/*            parseInt(fund.price_change24h.toString(), 10) > 0,*/}
            {/*          "text-red-500 dark:text-red-500":*/}
            {/*            parseInt(fund.price_change24h.toString(), 10) < 0,*/}
            {/*        },*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      {fund.price_change24h.toLocaleString('en')}%*/}
            {/*      {parseInt(fund.price_change24h.toString(), 10) > 0 ? (*/}
            {/*        <Icon*/}
            {/*          icon={ICON_COLLECTION.arrowUp}*/}
            {/*          className="h-5 w-5"*/}
            {/*          iconColor="#22C55E"*/}
            {/*        />*/}
            {/*      ) : (*/}
            {/*        <Icon*/}
            {/*          icon={ICON_COLLECTION.arrowDown}*/}
            {/*          className="h-5 w-5"*/}
            {/*          iconColor="#EF4444"*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
          <div className="w-full flex flex-col gap-12px">
            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-white font-medium whitespace-nowrap flex gap-1">
                {t("fund-details.infoList.creator")}
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.fund_creator}
              </span>
            </div>

            <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
              <h6 className="text-l h-full text-gray-500 dark:text-white font-medium whitespace-nowrap flex gap-1">
                <Tooltip
                  place="top"
                  id={"help"}
                  style={{
                    backgroundColor: theme === "dark" ? "#fff" : "#111827",
                    color: theme === "dark" ? "#1F2937" : "#fff",
                    borderRadius: "6px",
                  }}
                />
                {t("fund-details.infoList.fee")}
                <Icon
                  icon={ICON_COLLECTION.helpCircle}
                  iconSize="12px"
                  iconColor="#5B21B6"
                  tooltip_id="help"
                  tooltip_text="Tooltip"
                />
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {fund.fund_fee.toLocaleString("en")}
              </span>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="w-full md:w-1/3 h-full flex flex-col gap-32px md:gap-0 justify-between items-center p-16px rounded-lg bg-white dark:bg-gray-900">
          <Loader />
        </div>
      ) : (
        <FundDetailsDiagram
          data={chartData}
          legendData={legendData}
          chartRef={chartRef}
        />
      )}
      <FundsDetailsCharts isLoading={isLoading} />
    </div>
  );
};
