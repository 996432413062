import React, { useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import MobileListFilters from "@ui/range-dropdown/mobile-list-filters";
import { Search } from "@ui/search/search";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import {
  FILTER_KEYS,
  FilterKeysValue,
  Filters,
} from "@/pages/funds-list/components/funds-main/funds-main";
import TotalLockedRangePicker from "@/pages/funds-list/components/funds-main/range-pickers/total-locked-range-picker";
import ChangePricePicker from "@/pages/funds-list/components/funds-main/range-pickers/change-price-picker";
import FeeRangePicker from "@/pages/funds-list/components/funds-main/range-pickers/fee-range-picker";

interface FundsMainSearchBarProps {
  filters: Filters;
  maxLocked: number;
  setFilters: (filters: Filters) => void;
  handleFilters: (
    field: FilterKeysValue,
    value: Filters[keyof Filters],
  ) => void;
}

export const FundsMainSearchBar: React.FC<FundsMainSearchBarProps> = ({
  filters,
  setFilters,
  maxLocked,
  handleFilters: onChangeFilters,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 992;

  // const onChangeFilters = (filter, value) => {
  //   handleFilters(filter, value);
  //   // setFilters({
  //   //   ...filters,
  //   //   [filter]: value,
  //   // });s
  // };

  const handelChangeRadio = (value: Filters[keyof Filters]) => {
    onChangeFilters(FILTER_KEYS.priceRadio, value);
  };

  // const [isSwitcherEnabled, setIsSwitcherEnabled] = useState(
  //   filters.onlyVerified,
  // );

  // const handleSwitchVerified = () => {
  //   setIsSwitcherEnabled(!isSwitcherEnabled);
  //   onChangeFilters(FILTER_KEYS.verified, !isSwitcherEnabled);
  // };

  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <div className="flex items-center justify-between w-full md:relative">
      {!isMobile ? (
        <Search
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          searchValue={filters.searchValue}
          setSearchValue={(value) => onChangeFilters(FILTER_KEYS.search, value)}
        />
      ) : null}
      <div className={clsx("flex gap-3 w-fit items-center justify-end")}>
        <div className="flex gap-2 items-center">
          {/*<div className="items-center gap-2 hidden md:flex">*/}
          {/*  <button*/}
          {/*    onClick={handleSwitchVerified}*/}
          {/*    className={clsx(`w-44 h-7 switcher after:bg-white after:w-2/5`, {*/}
          {/*      "bg-violet-500 dark:bg-violet-700": filters.onlyVerified,*/}
          {/*      "switcher--clicked bg-gray600": !filters.onlyVerified,*/}
          {/*    })}*/}
          {/*  ></button>*/}
          {/*  <span className="font-sf-pro text-gray-700 dark:text-white text-l">*/}
          {/*    {t("verified")}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <button
            onClick={() => navigate("/create")}
            className="md:hidden flex items-center justify-center rounded-full w-12	h-12 bg-violet-600"
          >
            <Icon icon={ICON_COLLECTION.plusCircle} iconColor="#fff" />
          </button>
          {!isMobile ? (
            <>
              <TotalLockedRangePicker
                max={maxLocked}
                rangeValue={filters.totalLockedRange}
                setRangeValue={(val) =>
                  onChangeFilters(FILTER_KEYS.locked, val)
                }
              />
              <ChangePricePicker
                rangeValue={filters.priceChangeRange}
                activeRadio={filters.activePriceRadio}
                setRangeValue={(val) =>
                  onChangeFilters(FILTER_KEYS.priceChange, val)
                }
                setActiveRadio={(value) => handelChangeRadio(value)}
              />
              <FeeRangePicker
                rangeValue={filters.feeRange}
                setRangeValue={(val) => onChangeFilters(FILTER_KEYS.fee, val)}
              />
            </>
          ) : (
            <MobileListFilters
              // handleSwitchVerified={handleSwitchVerified}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
};
