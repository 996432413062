import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "rc-slider";

import { Dropdown } from "@/components/dropdown/dropdown";

interface Props {
  max: number;
  rangeValue: number[];
  setRangeValue: (value: number[]) => void;
}

const TotalLockedRangePicker = ({ max, rangeValue, setRangeValue }: Props) => {
  const { t } = useTranslation();

  const handleRangeChange = (value: number[] | number) => {
    if (typeof value !== "number") {
      setRangeValue(value);
    }
  };

  return (
    <Dropdown
      title={t("funds-list-table.search-bar.dropdowns.total")}
      placement="bottom-end"
    >
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center gap-3">
          <div className="flex flex-col">
            <span className="text-gray-800 dark:text-white">From:</span>
            <input
              type="number"
              min={0}
              max={max}
              value={rangeValue[0]}
              className="py-3 px-4 text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
            />
          </div>

          <div className="flex flex-col">
            <span className="text-gray-800 dark:text-white">To:</span>
            <input
              type="number"
              min={0}
              max={max}
              value={rangeValue[1]}
              className="py-3 px-4 text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
            />
          </div>
        </div>
        <div className="px-3">
          <Slider
            range
            min={0}
            max={max}
            value={rangeValue}
            onChange={handleRangeChange}
            className="range-slider mb-3"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default TotalLockedRangePicker;
