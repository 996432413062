import topFund_1_1 from "./top-funds-1-1.png";
import topFund_1_2 from "./top-funds-1-2.png";
import topFund_2_1 from "./top-funds-2-1.png";
import topFund_2_2 from "./top-funds-2-2.png";
import topFund_3_1 from "./top-funds-3-1.png";
import topFund_3_2 from "./top-funds-3-2.png";
import topFund_4_1 from "./top-funds-4-1.png";
import topFund_4_2 from "./top-funds-4-2.png";
import topFund_5_1 from "./top-funds-5-1.png";
import topFund_5_2 from "./top-funds-5-2.png";
import topFund_6_1 from "./top-funds-6-1.png";
import topFund_6_2 from "./top-funds-6-2.png";
import topFund_7_1 from "./top-funds-7-1.png";
import topFund_7_2 from "./top-funds-7-2.png";
import topFund_8_1 from "./top-funds-8-1.png";
import topFund_8_2 from "./top-funds-8-2.png";
import topFund_9_1 from "./top-funds-9-1.png";
import topFund_9_2 from "./top-funds-9-2.png";

export const TOP_FUND_COLLECTION = {
  topFund_1_1: topFund_1_1,
  topFund_1_2: topFund_1_2,
  topFund_2_1: topFund_2_1,
  topFund_2_2: topFund_2_2,
  topFund_3_1: topFund_3_1,
  topFund_3_2: topFund_3_2,
  topFund_4_1: topFund_4_1,
  topFund_4_2: topFund_4_2,
  topFund_5_1: topFund_5_1,
  topFund_5_2: topFund_5_2,
  topFund_6_1: topFund_6_1,
  topFund_6_2: topFund_6_2,
  topFund_7_1: topFund_7_1,
  topFund_7_2: topFund_7_2,
  topFund_8_1: topFund_8_1,
  topFund_8_2: topFund_8_2,
  topFund_9_1: topFund_9_1,
  topFund_9_2: topFund_9_2,
};
