import { createContext } from "react";
import { ActualFundType, Fund } from "@constants/funds-data";

export type BookmarksContextType = {
  bookmarks: ActualFundType["fund_id"][];
  funds: ActualFundType[];
  topChangeDaily: ActualFundType[];
  topInflow: ActualFundType[];
  topOutflow: ActualFundType[];
  isFundsLoading: boolean;
  handleBookmark: (fund: ActualFundType["fund_id"]) => void;
};

export const BookmarksContext = createContext(
  null as unknown as BookmarksContextType,
);
