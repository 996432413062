import { createContext } from "react";

type CardanoSerializerType = {
  placeFundRequests: (
    fundName,
    adaFundAmount,
    adaInterfaceFee,
    adaCreatorFee,
  ) => Promise<{
    txHash: string | undefined;
    txCbor: Uint8Array;
  }>,
  getPubKeyHash: () => Promise<string | null>,
  cancelFundRequest: (
    utxoHash,
    utxoId,
    lovelaceAttached) => Promise<{
    txHash: string | undefined;
    txCbor: Uint8Array;
  }>,
};

export const CardanoSerializerContext = createContext(
  null as unknown as CardanoSerializerType,
);
