// src/pages/NotFound.tsx
import React from "react";
import { Layout } from "@/layout/layout";

export const NotFound = () => {
  return (
    <Layout>
      <section className="w-full min-h-screen dark:bg-dark-bg py-3 md:py-8 px-4 md:px-16">
        <div className="text-center">
          <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
          <p className="mt-4 text-lg">Sorry, the page you are looking for does not exist.</p>
        </div>
      </section>
    </Layout>
  );
};

