import React from "react";

import { Layout } from "@/layout/layout";
import { FundInteractionsInfo } from "./components/fund-interactions-info/fund-interactions-info";

export const FundInteractionsSec = () => {
  return (
    <Layout>
      <section className="w-full min-h-screen dark:bg-dark-bg flex flex-col items-center py-9 px-0 md:px-16 overflow-hidden">
        <FundInteractionsInfo />
      </section>
    </Layout>
  );
};
