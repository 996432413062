import React from "react";
import { useFormikContext } from "formik";
import { List } from "react-virtualized";

import { Loader } from "@ui/loader/loader";
import { TokensTableRow } from "./tokens-table-row";

import { CreateFundFormType } from "@/pages/create-fund/types";
import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";

interface Props {
  tokenList: Token[];
  isLoading: boolean;
}

export const TokensTable = ({ tokenList, isLoading }: Props) => {
  const { setValues, values } = useFormikContext<CreateFundFormType>();

  const handleSelect = (token: Token) => {
    const totalPercent = 100;
    const newTokenIds = [
      ...new Set([...values.selectedTokenIds, token.policy_id]),
    ];
    const percentage = +(totalPercent / newTokenIds.length).toFixed(2);

    const newTokens = Object.fromEntries(
      newTokenIds.map((id) => {
        if (values.selectedTokens[id]) {
          return [
            id,
            { ...values.selectedTokens[id], distribution: percentage },
          ];
        }

        return [id, { ...token, distribution: percentage }];
      }),
    );

    setValues({
      ...values,
      selectedTokenIds: newTokenIds,
      selectedTokens: newTokens,
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full py-[258px]">
          <Loader />
        </div>
      ) : (
        <List
          width={1000}
          height={546}
          rowCount={tokenList.length}
          rowHeight={64}
          overscanRowCount={5}
          // style={{ width: "100%" }}
          // containerStyle={{ width: "100%" }}
          autoContainerWidth
          autoWidth
          rowRenderer={({ key, index, style }) => (
            <TokensTableRow
              style={style}
              key={key}
              token={tokenList[index]}
              handleSelect={handleSelect}
            />
          )}
        />
      )}
    </>
  );
};
