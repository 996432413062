import { Icon } from "@/UI/icons/icon";
import { ICON_COLLECTION } from "@/UI/icons/icon-list";
import clsx from "clsx";
import React from "react";
import { ActualTokenType } from "@constants/funds-data";
import { twMerge } from "tailwind-merge";

interface Props {
  dataRow: ActualTokenType;
  handleTableHover: (label: string) => void;
}

export const FundsDetailsTableRow = ({ dataRow, handleTableHover }: Props) => {
  return (
    <tr
      onMouseOver={() => handleTableHover(dataRow.token_symbol)}
      onMouseOut={() => handleTableHover("")}
      className="relative h-14 transition-all duration-200 md:hover:bg-violet-50 md:hover:dark:bg-gray-800 cursor-pointer"
    >
      <td
        className="bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent sticky -left-0.5 z-10 pl-1 md:p-0 md:static px-24px"
        style={{
          minWidth: "40px",
          minHeight: "40px",
          width: "40px",
          height: "40px",
        }}
      >
        <div className="rounded-full overflow-hidden w-fit">
          <img
            src={dataRow.token_image}
            alt=""
            className="w-full h-full"
            style={{
              minWidth: "40px",
              minHeight: "40px",
              width: "40px",
              height: "40px",
            }}
          />
        </div>
      </td>
      <td>
        <span className="text-gray-800 text-m md:text-xl font-medium dark:text-white whitespace-nowrap">
          {dataRow.token_symbol}
        </span>
      </td>

      <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
        {dataRow.token_price.toLocaleString("en")}
      </td>
      <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
        {Number(dataRow.token_marketcap.toFixed(2)).toLocaleString("en")}
      </td>
      <td
        className={twMerge(
          clsx("font-medium text-gray-800 dark:text-white", {
            "text-green-500 dark:text-green-500":
              dataRow.token_price_change24h > 0,
            "text-red-500 dark:text-red-500": dataRow.token_price_change24h < 0,
          }),
        )}
      >
        <div className="flex items-center justify-end text-m md:text-l">
          {Number(dataRow.token_price_change24h.toFixed(2)).toLocaleString(
            "en",
          ) + "%"}
          {dataRow.token_price_change24h > 0 ? (
            <Icon
              icon={ICON_COLLECTION.arrowUp}
              className="h-5 w-5"
              iconColor="#22C55E"
            />
          ) : (
            <Icon
              icon={ICON_COLLECTION.arrowDown}
              className="h-5 w-5"
              iconColor="#EF4444"
            />
          )}
        </div>
      </td>
      <td className={"font-medium text-gray-800 dark:text-white"}>
        <div className="flex items-center justify-end text-m md:text-l">
          {Number(dataRow.token_volume24h.toFixed(2)).toLocaleString("en")}
        </div>
      </td>
      <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
        {dataRow.token_share.toFixed(2) + "%"}
      </td>
    </tr>
  );
};
