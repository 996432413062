import { Icon } from "@/UI/icons/icon";
import { ICON_COLLECTION } from "@/UI/icons/icon-list";
import { VioletButton } from "@/UI/violet-button/violet-button";
import React from "react";
import { useTranslation } from "react-i18next";

interface Point {
  label: string;
  value: string;
  id: number;
}

interface PointsItem {
  id: number;
  title: string;
  formTitle: string;
  balance: string;
  balanceSec?: string;
  points: Point[];
  btn: string;
}

interface FundInteractionsFormCardMobProps {
  item: PointsItem;
}

export const FundInteractionsFormCardMob: React.FC<
  FundInteractionsFormCardMobProps
> = ({ item }) => {
  const { t } = useTranslation();
  const pointsList = Object.values(item.points);
  return (
    <div className="w-full flex flex-col items-center gap-24px bg-white dark:bg-dark-gray900 rounded-lg font-sf-pro">
      <div className="w-full flex flex-col gap-16px">
        <div className="w-full flex flex-col gap-1 font-sf-pro">
          <div className="w-full flex items-center justify-between">
            <label
              htmlFor="input"
              className="text-l font-medium text-gray-800 dark:text-white whitespace-nowrap"
            >
              {t(item.formTitle)}
            </label>
          </div>
          <input
            type="text"
            className="w-full bg-transparent py-12px px-16px rounded-lg border border-gray-200 focus:border-violet-500 dark:border-gray-800 text-gray-800 dark:text-white font-sf-pro text-l font-medium placeholder:font-sf-pro placeholder:text-gray-800 placeholder:dark:text-white placeholder:text-xl placeholder:font-medium"
            placeholder="--"
            name="input"
            id="input"
          />
        </div>
        <div className="w-full flex flex-col gap-16px">
          {pointsList.map((item, index) => (
            <div
              key={index}
              className="flex items-center font-sf-pro gap-3 cursor-pointer"
            >
              <h6 className="text-l h-full text-gray-500 dark:text-white font-medium whitespace-nowrap flex items-center">
                {t(item.label)}
                <Icon
                  icon={ICON_COLLECTION.helpCircle}
                  className="height-12"
                  iconColor="#7C3AED"
                />
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {item.value === "funds-interactions-forms.dashes" ? (
                  t(item.value)
                ) : (
                  <div className="flex items-center gap-2">
                    <span>--</span>
                    <span className="text-gray-400 dark:text-gray-500">
                      {t(item.value)}
                    </span>
                  </div>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <VioletButton
        icon={
          item.id === 1
            ? ICON_COLLECTION.plusCircle
            : ICON_COLLECTION.minusCircle
        }
        className="py-8px px-32px"
        text={t(item.btn)}
        iconColor="#fff"
        iconSize="24px"
      />
    </div>
  );
};
