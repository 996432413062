import { array, boolean, number, object, string } from "yup";

export const createFundValidationSchema = object().shape({
  fundName: string().required("Fund name is required."),
  description: string()
    .required("Description is required.")
    .min(50, "Description can't be less than 50 characters."),
  hide: boolean().oneOf([true, false]),
  creatorName: string().when("hide", {
    is: false,
    then: (schema) => schema.required("Creator name is required."),
  }),
  ada: number().required("ADA is required").min(5, "min: 5 ADA.").max(20, "max: 20 ADA."),
  commission: boolean().oneOf([true, false]),
  address: string().when("commission", {
    is: true,
    then: (schema) => schema.required("Address is required."),
  }),
  fee: string().when("commission", {
    is: true,
    then: (schema) =>
      schema
        .required("Fee is required.")
        .oneOf(
          ["0.05", "0.1", "0.3", "0.5", "1"],
          "You need to select value from dropdown.",
        ),
  }),
  selectedTokenIds: array()
    .of(string())
    .min(1, "At least one token should be selected."),
});
