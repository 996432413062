import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, Theme } from "react-toastify";

import BookmarkProvider from "@/providers/bookmarks-provider/bookmark-provider";

import App from "./App";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import {
  ThemeProvider,
  useTheme,
} from "./components/theme-context/theme-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const ToastWrapper = () => {
  const { theme } = useTheme();

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover={false}
      theme={theme as Theme}
    />
  );
};

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
      <ToastWrapper />
    </ThemeProvider>
  </React.StrictMode>,
);
