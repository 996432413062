import React, { MouseEventHandler } from "react";
import { Icon } from "@ui/icons/icon";
import { twMerge } from "tailwind-merge";

interface VioletButtonProps {
  text: string;
  icon?: string;
  className?: string;
  iconClass?: string;
  iconSize?: string;
  iconColor?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const VioletButton: React.FC<VioletButtonProps> = ({
  text,
  className,
  icon,
  iconClass,
  iconSize,
  iconColor,
  disabled,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        "w-fit flex items-center justify-center gap-3 font-sf-pro text-l font-bold text-white bg-violet_light rounded-3xl transition-all duration-300 hover:bg-violet900 dark:bg-violet900 disabled:bg-violet_light/50 dark:disabled:bg-violet900/50 dark:hover:bg-violet_light",
        !!className && className,
      )}
    >
      {text}
      {icon ? (
        <Icon
          icon={icon}
          className={iconClass}
          iconColor={iconColor}
          iconSize={iconSize}
        />
      ) : null}
    </button>
  );
};
