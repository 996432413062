import { useContext } from "react";
import { BookmarksContext } from "@/providers/bookmarks-provider/context";

export const useBookmarks = () => {
  const context = useContext(BookmarksContext);

  if (!context) {
    throw new Error(
      "useBookmarks hook should be used within BookmarksProvider.",
    );
  }

  const { bookmarks } = context;

  return {
    bookmarks,
  };
};
