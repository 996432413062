import React, { useEffect, useState } from "react";
import { SelectedTokensRow } from "./selected-tokens-row";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { Tooltip } from "react-tooltip";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";
import { getCurrentDistribution } from "@/pages/create-fund/helpers/get-current-distribution";

import { CreateFundFormType } from "@/pages/create-fund/types";

export const SelectedTokens = () => {
  const { values } = useFormikContext<CreateFundFormType>();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(true);

  const currentDistribution = getCurrentDistribution(
    values.selectedTokenIds,
    values.selectedTokens,
  );

  return (
    <div className="flex flex-col w-full overflow-y-auto mb-8">
      <div className="flex text-s md:text-m items-center justify-between w-full py-2 px-3 font-sf-pro font-medium text-gray-400">
        <span>{t("create-fund-tokens.selected.title.name")}</span>
        <Tooltip
          place="top"
          id={"help"}
          style={{
            backgroundColor: theme === "dark" ? "#fff" : "#111827",
            color: theme === "dark" ? "#1F2937" : "#fff",
            borderRadius: "6px",
          }}
        />
        <span className="flex items-center">
          {t("create-fund-tokens.selected.title.distibution")}{" "}
          <Icon
            icon={ICON_COLLECTION.helpCircle}
            className="height-14"
            iconColor="#7C3AED"
            tooltip_id="help"
            tooltip_text="Tooltip"
          />
        </span>
      </div>

      {+currentDistribution !== 100 ? (
        <div className="flex w-full justify-end text-red-400">
          Total distribution should 100%. Current: {currentDistribution}%
        </div>
      ) : null}

      {values.selectedTokenIds.map((token) => (
        <SelectedTokensRow key={token} token={values.selectedTokens[token]} />
      ))}
    </div>
  );
};
