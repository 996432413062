import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import { twMerge } from "tailwind-merge";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { VioletButton } from "@ui/violet-button/violet-button";
import { useTheme } from "@/components/theme-context/theme-context";

interface Point {
  label: string;
  value: string;
  id: number;
}

interface PointsItem {
  id: number;
  title: string;
  formTitle: string;
  balance: string;
  balanceSec?: string;
  points: Point[];
  btn: string;
}

interface FundInteractionsFormCardProps {
  pointsItem: PointsItem;
  width?: string;
}

export const FundInteractionsFormCard: React.FC<
  FundInteractionsFormCardProps
> = ({ pointsItem, width }) => {
  const { isConnected } = useCardano({ limitNetwork: NetworkType.MAINNET });

  const { t } = useTranslation();
  const { theme } = useTheme();
  const pointsList = Object.values(pointsItem.points);

  useEffect(() => {
    console.log("isWalletConnected", isConnected);
  }, [isConnected]);

  return (
    <div
      className={twMerge(
        "relative min-h-[411px] p-24px pb-64px flex flex-col gap-32px bg-white dark:bg-dark-gray900 rounded-lg font-sf-pro",
        width ? width : "w-1/2",
      )}
    >
      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
        {t(pointsItem.title)}
      </h3>
      <div className="w-full flex flex-col gap-16px">
        <div className="w-full flex flex-col gap-1 font-sf-pro">
          <div className="w-full flex items-center justify-between">
            <label
              htmlFor="input"
              className="text-l font-medium text-gray-800 dark:text-white whitespace-nowrap"
            >
              {t(pointsItem.formTitle)}
            </label>
            <div className="flex items-center gap-2">
              {!pointsItem.balanceSec ? (
                <Icon
                  icon={ICON_COLLECTION.walletIcon}
                  iconColor="#9CA3AF"
                  iconSize="16px"
                />
              ) : (
                <span className="text-m font-medium text-gray-800 dark:text-gray-400 whitespace-nowrap">
                  {t(pointsItem.balance)}
                </span>
              )}
              {!pointsItem.balanceSec ? (
                <span className="text-m font-medium text-gray-400 dark:text-white whitespace-nowrap">
                  {t(pointsItem.balance)}
                </span>
              ) : (
                <span className="text-m font-medium text-gray-400 whitespace-nowrap">
                  {t(pointsItem.balanceSec)}
                </span>
              )}
            </div>
          </div>
          <input
            disabled={!isConnected}
            type="text"
            className="w-full bg-transparent py-12px px-16px rounded-lg border border-gray-200 focus:border-violet-500 dark:border-gray-800 text-gray-800 dark:text-white font-sf-pro text-l font-medium placeholder:font-sf-pro placeholder:text-gray-800 placeholder:dark:text-white placeholder:text-xl placeholder:font-medium"
            placeholder="--"
            name="input"
            id="input"
          />
        </div>
        <div className="w-full flex flex-col gap-16px">
          {pointsList.map((item, index) => (
            <div
              key={index}
              className="flex items-center font-sf-pro gap-3 cursor-pointer"
            >
              <Tooltip
                place="top"
                id={"help"}
                style={{
                  backgroundColor: theme === "dark" ? "#fff" : "#111827",
                  color: theme === "dark" ? "#1F2937" : "#fff",
                  borderRadius: "6px",
                }}
              />
              <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
                {t(item.label)}
                <Icon
                  icon={ICON_COLLECTION.helpCircle}
                  className="height-12"
                  iconColor="#7C3AED"
                  tooltip_id="help"
                  tooltip_text="Fee when adding to fund"
                />
              </h6>
              <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
              <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
                {item.value === "funds-interactions-forms.dashes" ? (
                  t(item.value)
                ) : (
                  <div className="flex items-center gap-2">
                    <span>--</span>
                    <span className="text-gray-400 dark:text-gray-500">
                      {t(item.value)}
                    </span>
                  </div>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <VioletButton
        disabled={!isConnected}
        icon={
          pointsItem.id === 1
            ? ICON_COLLECTION.plusCircle
            : ICON_COLLECTION.minusCircle
        }
        className="py-8px px-32px absolute left-1/2 bottom-24px -translate-x-1/2"
        text={t(pointsItem.btn)}
        iconColor="#fff"
        iconSize="24px"
      />
    </div>
  );
};
