import { useContext } from "react";
import { BookmarksContext } from "@/providers/bookmarks-provider/context";

export const useBookmarkHandlers = () => {
  const context = useContext(BookmarksContext);

  if (!context) {
    throw new Error(
      "useBookmarkHandlers hook should be used within BookmarksProvider.",
    );
  }

  const { handleBookmark } = context;

  return {
    handleBookmark,
  };
};
