import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/card/card";
import { FundsTopTableCard } from "./funds-top-table-card/funds-top-table-card";
import { useFunds } from "@/providers/bookmarks-provider/hooks/use-fund";

export const FundsTopTable = () => {
  const { t } = useTranslation();
  const { topChangeDaily, topInflow, topOutflow, isFundsLoading } = useFunds();

  const FUND_LIST_TOP_TABLE = [
    { id: "some-id-1", title: "changes", cardContent: topChangeDaily },
    { id: "some-id-2", title: "inflow", cardContent: topInflow },
    { id: "some-id-3", title: "outflow", cardContent: topOutflow },
  ];

  return (
    <div className="flex justify-between w-full gap-x-4 md:gap-x-7 ml-10 md:m-0 overflow-x-auto pb-5 md:pb-0 pr-9 md:pr-0">
      {FUND_LIST_TOP_TABLE.map((item) => (
        <Card key={item.title}>
          <FundsTopTableCard
            title={t(`funds-top-table.title.${item.title}`)}
            card={item}
            isLoading={isFundsLoading}
          />
        </Card>
      ))}
    </div>
  );
};
