import { boolean, object, string } from "yup";
import { tr } from "date-fns/locale";

export const connectWalletFormValidation = object().shape({
  walletName: string().required("You need to select a wallet to connect."),
  agreementAccepted: boolean().oneOf(
    [true],
    "The terms and conditions must be accepted.",
  ),
});
