import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "rc-slider";
import { twMerge } from "tailwind-merge";

import { Dropdown } from "@/components/dropdown/dropdown";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Icon } from "@ui/icons/icon";
import { useTheme } from "@/components/theme-context/theme-context";

import styles from "@ui/range-dropdown/range-dropdown.module.scss";

interface Props {
  activeRadio: string;
  rangeValue: number[];
  setRangeValue: (value: number[]) => void;
  setActiveRadio: (value: string) => void;
}

const RADIO_INPUTS = [
  { label: "24h", value: "24h", id: "24h-change-price" },
  { label: "7d", value: "7d", id: "7d-change-price" },
  { label: "All time", value: "all_time", id: "all-time-change-price" },
];

const ChangePricePicker = ({
  rangeValue,
  activeRadio,
  setRangeValue,
  setActiveRadio,
}: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const handleRangeChange = (value: number[] | number) => {
    if (typeof value !== "number") {
      setRangeValue(value);
    }
  };

  return (
    <Dropdown
      title={t("funds-list-table.search-bar.dropdowns.price")}
      placement="bottom-end"
    >
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center gap-3">
          <div className="flex flex-col w-full">
            <span className="text-gray-800 dark:text-white">From:</span>
            <div className="relative">
              <input
                name="price-range-from-value"
                type="number"
                min={-100}
                max={100}
                value={rangeValue[0]}
                onChange={(event) =>
                  setRangeValue([+event.target.value, rangeValue[1]])
                }
                className="py-3 pr-7 px-4 w-full text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="20px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <span className="text-gray-800 dark:text-white">To:</span>
            <div className="relative">
              <input
                name="price-range-to-value"
                type="number"
                min={0}
                max={100}
                value={rangeValue[1]}
                onChange={(event) =>
                  setRangeValue([rangeValue[0], +event.target.value])
                }
                className="py-3 pr-7 px-4 w-full text-gray-800 dark:text-white font-medium rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent"
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="20px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between gap-5">
          {RADIO_INPUTS.map(({ value, label, id }) => (
            <div key={id} className={styles["radio-button"]}>
              <input
                type="radio"
                name={id}
                id={id}
                checked={activeRadio === value}
                onChange={() => setActiveRadio(value)}
                className={styles["radio-button__input"]}
              />
              <label
                htmlFor={id}
                className={twMerge(
                  styles["radio-button__label"],
                  "text-gray-700 font-medium dark:text-gray-500 cursor-pointer",
                )}
              >
                {label}
              </label>
            </div>
          ))}
        </div>

        <div className="px-3">
          <Slider
            range
            min={-100}
            max={100}
            value={rangeValue}
            onChange={handleRangeChange}
            className="range-slider mb-3"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default ChangePricePicker;
