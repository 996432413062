import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";

import { TABS, TabValue } from "@/pages/positions/components/positions-main";
import { twMerge } from "tailwind-merge";

interface PositionsNavBarProps {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
}

export const PositionsNavBar: React.FC<PositionsNavBarProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const { theme } = useTheme();
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const isMobile = width <= 992;

  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex items-center gap-3">
        <button
          onClick={() => setActiveTab(TABS.holds)}
          className={twMerge(
            clsx(
              "w-35 text-gray-400 text-l md:text-xl py-2 px-2 border-b-2 border-transparent hover:bg-violet_light hover:text-white transition-colors duration-300 dark:text-gray-500 dark:hover:text-white",
              {
                "text-violet-600 dark:text-white border-violet-600 font-medium":
                  activeTab === TABS.holds,
              },
            ),
          )}
        >
          {t("positions.nav-bar.title.holds")}
        </button>
        <button
          onClick={() => setActiveTab(TABS.history)}
          className={twMerge(
            clsx(
              "w-24 text-gray-400 text-l md:text-xl py-2 px-2 border-b-2 border-transparent hover:bg-violet_light hover:text-white transition-colors duration-300 dark:text-gray-500 dark:hover:text-white",
              {
                "text-violet-600 dark:text-white border-violet-600 font-medium":
                  activeTab === TABS.history,
              },
            ),
          )}
        >
          {t("positions.nav-bar.title.history")}
        </button>
      </div>
      <div className="flex items-center gap-2">
        {activeTab === "history" && !isMobile ? (
          <button className="text-violet-600 py-2 px-6 bg-violet-200 rounded-full font-semibold flex items-center gap-2 dark:bg-gray-800 dark:text-white hover:bg-violet-400 dark:hover:bg-gray-700 transition-all duration-200">
            {t("positions.nav-bar.title.csv")}
            <Icon
              icon={ICON_COLLECTION.downloadCloud}
              iconColor={theme === "dark" ? "#fff" : "#7C3AED"}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
