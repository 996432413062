import React from "react";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  ChartOptions,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useWindowSize } from "usehooks-ts";

import { DatePickerDialog } from "@/UI/date-picker/date-picker";
import { useTheme } from "@/components/theme-context/theme-context";
import { ChartDatepickerMobile } from "./chart-datepicker-mobile";

export const FundsDetailsChartItem = ({ item }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = width < 992;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
  );

  const gradient = (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    const gradient = ctx.createLinearGradient(
      chartArea.left,
      chartArea.top,
      chartArea.left,
      chartArea.bottom,
    );
    gradient.addColorStop(0, theme === "dark" ? "#5B21B6" : "#DDD6FE");
    gradient.addColorStop(
      1,
      theme === "dark"
        ? "rgba(91, 33, 182, 0.00)"
        : "rgba(221, 214, 254, 0.00)",
    );
    return gradient;
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#fff" : "#1F2937",
          font: {
            family: "SF-Pro",
            weight: "500",
          },
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        grid: {
          color: theme === "dark" ? "#374151" : "#D1D5DB",
        },
        ticks: {
          callback: function (value, index, values) {
            if (index === 0 || index === values.length - 1) {
              return value;
            } else {
              return "";
            }
          },
          color: theme === "dark" ? "#fff" : "#1F2937",
          font: {
            family: "SF-Pro",
            weight: "500",
          },
        },
        position: "right",
      },
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 2,
        fill: true,
        backgroundColor: gradient,
      },
    },
  };

  const labels = ["1", "2", "3", "4", "5", "6", "7"];

  const data = {
    labels,
    datasets: [
      {
        label: "data",
        data: item.data,
        borderColor: theme === "dark" ? "#4C1D95" : "#5B21B6",
      },
    ],
  };
  return (
    <div className="relative w-full h-full flex flex-col gap-24px justify-between p-16px rounded-lg bg-white dark:bg-gray-900">
      <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-gray-400/50 rounded-lg backdrop-blur flex items-center justify-center">
        <p className="text-[24px] font-sf-pro font-semibold text-gray-800 dark:text-white -rotate-[25deg]">
          This feature will come soon
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
        <span className="font-sf-pro text-l font-semibold text-gray-800 dark:text-white">
          {t(item.title)}
        </span>
        {/*{isMobile ? (*/}
        {/*  <ChartDatepickerMobile />*/}
        {/*) : (*/}
        {/*  <DatePickerDialog hasDateInputs={false} />*/}
        {/*)}*/}
      </div>
      <div className="w-full" style={{ height: "108px" }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};
