export const HeaderMenu = [
  {
    title: "list",
    path: "/",
  },
  {
    title: "interactions",
    path: "/interactions",
  },
  {
    title: "create",
    path: "/create",
  },
  {
    title: "positions",
    path: "/positions",
  },
];

export const HeaderConnect = {
  title: "Connect Wallet",
  path: "/",
};
