import React, { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

import styles from "./checkbox.module.scss";

interface CheckboxProps {
  label?: string | JSX.Element;
  name: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  checked = false,
  onChange,
}) => {
  return (
    <div className={styles["checkbox"]}>
      <input
        id={`checkbox-${name}`}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className={styles.checkbox__input}
      />
      <label
        htmlFor={`checkbox-${name}`}
        className={twMerge(styles.checkbox__label, "cursor-pointer")}
      >
        {label}
      </label>
    </div>
  );
};
