import { components } from "react-select";
import React from "react";
import { Icon } from "../icons/icon";
import { ICON_COLLECTION } from "../icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";

export const DropdownIndicator = (props: any) => {
  const { theme } = useTheme();
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} style={{ padding: 0 }}>
        <Icon
          icon={ICON_COLLECTION.chevronDown}
          iconColor={theme === "dark" ? "#fff" : "#000"}
        />
      </components.DropdownIndicator>
    )
  );
};
