import React, { useState } from "react";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import {
  estimateAvailableWallets,
  UnavailableWalletVisibility,
  WalletExtensionNotFoundError,
} from "@cardano-foundation/cardano-connect-with-wallet-core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { twMerge } from "tailwind-merge";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "@ui/modal/modal";
import { Icon } from "@ui/icons/icon";

import { ConnectWalletForm } from "@/components/connect-wallet/connect-wallet-form";
import { ConnectWalletFormType } from "@/components/connect-wallet/types";
import { WALLET_LINKS } from "@/components/connect-wallet/data";
import { connectWalletFormValidation } from "@/components/connect-wallet/validation";

interface ConnectWalletProps {
  text: string;
  icon?: string;
  className?: string;
  iconClass?: string;
  iconSize?: string;
  iconColor?: string;
}

const SUPPORTED_WALLETS = [
  "Eternl",
  "Flint",
  "Gero",
  "Nami",
  "Typhon",
  "Yoroi",
  "Lace",
];

export const ConnectWallet: React.FC<ConnectWalletProps> = ({
  text,
  className,
  icon,
  iconClass,
  iconSize,
  iconColor,
}) => {
  const {
    isEnabled,
    isConnected,
    enabledWallet,
    stakeAddress,
    signMessage,
    connect,
    disconnect,
    installedExtensions,
  } = useCardano({ limitNetwork: NetworkType.MAINNET });

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const availableWallets = estimateAvailableWallets(
    SUPPORTED_WALLETS,
    UnavailableWalletVisibility.SHOW_UNAVAILABLE_ON_MOBILE,
    [],
    installedExtensions,
  );

  const connectWalletInitState = {
    walletName: availableWallets[0] ?? "",
    agreementAccepted: false,
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsModalOpen(false);
  };

  const onConnectWallet = async (
    values: ConnectWalletFormType,
    helpers: FormikHelpers<ConnectWalletFormType>,
  ) => {
    if (!values.agreementAccepted) {
      toast.error("Please accept agreement!");
      return;
    }

    const onSuccess = () => {
      toast.success(
        <div className="flex flex-col items-start">
          <p className="text-l text-black dark:text-gray-200 text-center">
            Wallet connected!
          </p>
          <p className="text-m text-black dark:text-gray-200 text-center">
            You can now use the DApp
          </p>
        </div>,
      );

      setTimeout(() => {
        helpers?.resetForm?.();
        closeModal();
      }, 2000);
    };

    const onError = (error: Error) => {
      if (error instanceof WalletExtensionNotFoundError) {
        const link = WALLET_LINKS[values.walletName.toLowerCase()];

        if (!!link) {
          window.open(link);
        } else {
          toast.error(
            `Please make sure you are using a modern browser and the ${values.walletName} browser extension has been installed.`,
          );
        }
      } else {
        toast.error(
          `Something went wrong. Please try again later and make sure to use the Mainnet network.`,
        );
      }
    };

    connect(values.walletName, onSuccess, onError);
  };

  const onDisconnectWallet = () => disconnect();

  useLockedBody(isModalOpen);

  return (
    <>
      <button
        onClick={() => openModal()}
        className={twMerge(
          "w-fit flex items-center justify-center gap-3 font-sf-pro text-l font-bold text-white bg-violet_light rounded-3xl transition-all duration-300 hover:bg-violet900 dark:bg-violet900 dark:hover:bg-violet_light",
          !!className && className,
        )}
      >
        {isConnected ? (
          <>
            {stakeAddress?.slice(0, 5)}...{stakeAddress?.slice(-5)}
          </>
        ) : (
          <>{text}</>
        )}
        {icon && (
          <Icon
            icon={icon}
            className={iconClass}
            iconColor={iconColor}
            iconSize={iconSize}
          />
        )}
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        contentClassName={"bg-white dark:bg-gray-900 shadow-lg"}
      >
        {!isConnected ? (
          <Formik
            initialValues={connectWalletInitState}
            validationSchema={connectWalletFormValidation}
            onSubmit={onConnectWallet}
            validateOnMount
            validateOnChange
            validateOnBlur
          >
            <ConnectWalletForm availableWallets={availableWallets} />
          </Formik>
        ) : (
          <>
            <div className="flex items-center justify-center mt-10">
              <button
                className="md:w-auto px-32px py-12px w-fit flex items-center justify-center gap-3 font-sf-pro text-l font-bold text-white bg-violet_light rounded-3xl transition-all duration-300 hover:bg-violet900 dark:bg-violet900 dark:hover:bg-violet_light"
                data-testid="button"
                onClick={onDisconnectWallet}
              >
                {t("connect-wallet-modal.buttons.disconnect")}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
