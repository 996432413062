import { useContext } from "react";
import { OverviewFiltersContext } from "@/providers/overview-filters-provider/context";

export const useOverviewFilterHandlers = () => {
  const context = useContext(OverviewFiltersContext);

  if (!context) {
    throw new Error(
      "useOverviewFilterHandlers hook should be used within OverviewFiltersProvider",
    );
  }

  const { updateFilterField, handleSortUpdate } = context;
  return {
    updateFilterField,
    handleSortUpdate,
  };
};
