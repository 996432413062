import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLockedBody } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from "react-day-picker";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { format, isAfter } from "date-fns";
import { useTheme } from "@/components/theme-context/theme-context";
import { Search } from "@ui/search/search";
import styles from "./history-mobile-filters.module.scss";
import { fi } from "date-fns/locale";

export const HistoryMobileFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  const [filters, setFilters] = useState(() => {
    const searchValue = searchParams.get("q") ?? "";
    const to = searchParams.get("dateTo");
    const from = searchParams.get("dateFrom");
    const actions = searchParams.has("actions")
      ? [...searchParams.getAll("actions")]
      : [];

    const selectedRange =
      !!to && !!from
        ? ({
            to: new Date(to),
            from: new Date(from),
          } as DateRange)
        : undefined;

    return {
      actions,
      selectedRange,
      searchValue,
      fromValue: format(new Date(from!), "d MMM, y"),
      toValue: format(new Date(to!), "d MMM, y"),
      added: actions.some((item) => item === "add"),
      removed: actions.some((item) => item === "rem"),
      isFiltersSelected: !!from && !!to,
    };
  });

  const { theme } = useTheme();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleRangeUpdate = (range: DateRange | undefined) => {
    setFilters((prev) => ({
      ...prev,
      selectedRange: range,
    }));
  };

  const handleFromValueUpdate = (from: string) => {
    setFilters((prev) => ({
      ...prev,
      fromValue: from,
    }));
  };

  const handleToValueUpdate = (to: string) => {
    setFilters((prev) => ({
      ...prev,
      toValue: to,
    }));
  };

  const handleSearchInput = (input: string) => {
    setFilters((prev) => ({
      ...prev,
      searchValue: input,
    }));
  };

  const handleAddedUpdate = () => {
    setFilters((prev) => ({
      ...prev,
      added: !prev.added,
      actions: prev.added
        ? prev.actions.filter((item) => item !== "add")
        : [...prev.actions, "add"],
    }));
  };

  const handleRemovedUpdate = () => {
    setFilters((prev) => ({
      ...prev,
      removed: !prev.removed,
      actions: prev.removed
        ? prev.actions.filter((item) => item !== "rem")
        : [...prev.actions, "rem"],
    }));
  };

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined,
  ) => {
    handleRangeUpdate(range);
    if (range?.from) {
      handleFromValueUpdate(format(range.from!, "dd MMM, yyyy"));
    } else {
      handleFromValueUpdate("");
    }
    if (range?.to) {
      handleToValueUpdate(format(range.to, "dd MMM, yyyy"));
    } else {
      handleToValueUpdate("");
    }
  };

  const isDayDisabled = (day: Date) => {
    const today = new Date();
    return isAfter(day, today);
  };

  const handleApply = () => {
    setIsOpen(false);
    setFilters((prev) => ({
      ...prev,
      isFiltersSelected: true,
    }));
  };

  useEffect(() => {
    const newParamsObj = {
      tab: "history",
      q: filters.searchValue,
      actions: filters.actions,
      dateFrom: filters.fromValue,
      dateTo: filters.toValue,
    };

    const newSearchParams = new URLSearchParams(searchParams);
    setSearchParams((prev) => ({
      ...prev,
      ...newSearchParams,
      ...newParamsObj,
    }));
  }, [filters]);

  useLockedBody(isOpen);

  return (
    <div>
      <button
        className="flex gap-3 p-3 border border-gray-200 rounded-xl dark:border-gray-700 relative"
        onClick={toggleDropdown}
      >
        <Icon
          icon={ICON_COLLECTION.settings}
          iconColor={theme === "dark" ? "#D1D5DB" : "#9CA3AF"}
        />
        {filters.searchValue ||
        filters.fromValue ||
        filters.toValue ||
        filters.added ||
        filters.removed ? (
          <span
            className={clsx(
              "w-12px h-12px rounded-full absolute -top-1 -right-1 bg-red-500",
              {
                hidden: !filters.isFiltersSelected,
              },
            )}
          ></span>
        ) : null}
      </button>
      <div
        className={clsx(styles["mobile-dropdown-blur"], {
          [styles["mobile-dropdown-blur--open"]]: isOpen,
        })}
        onClick={() => setIsOpen(false)}
      ></div>
      <div
        className={clsx(
          "rounded-tl-3xl rounded-tr-3xl fixed z-50 left-0 bottom-0 w-full flex flex-col gap-4 items-center p-4 bg-white dark:bg-gray-950 transition-all duration-700",
          {
            "translate-y-full": !isOpen,
            "translate-y-0": isOpen,
          },
        )}
      >
        <span
          className="w-16 h-1.5 rounded bg-gray-300"
          onClick={() => setIsOpen(false)}
        ></span>
        <div className="flex w-full items-center justify-between">
          <h3 className="w-full font-sf-pro text-gray-800 font-semibold text-2xl dark:text-white">
            Filters
          </h3>
        </div>
        <Search
          isExpand={false}
          searchValue={filters.searchValue}
          setSearchValue={handleSearchInput}
        />
        <div className="w-full flex flex-col">
          <h4 className="text-l font-semibold font-sf-pro text-gray500 dark:text-white mb-3">
            Action:
          </h4>
          <div className="w-full flex flex-col gap-2 font-sf-pro text-l text-gray-800 font-medium">
            <div
              className={clsx("w-full py-8px px-32px dark:text-gray-500", {
                [styles.option_selected]: filters.added,
                "dark:text-white": filters.added,
              })}
              onClick={handleAddedUpdate}
            >
              Added
            </div>
            <div
              className={clsx("w-full py-8px px-32px dark:text-gray-500", {
                [styles.option_selected]: filters.removed,
                "dark:text-white": filters.removed,
              })}
              onClick={handleRemovedUpdate}
            >
              Removed
            </div>
          </div>
        </div>
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="w-full">
          <h4 className="text-l font-semibold font-sf-pro text-gray500 dark:text-white mb-3">
            Date:
          </h4>
          <DayPicker
            showOutsideDays
            numberOfMonths={1}
            mode="range"
            selected={filters.selectedRange}
            onSelect={handleRangeSelect}
            modifiers={{ disabled: isDayDisabled }}
            modifiersClassNames={{
              selected:
                theme === "dark" ? styles.selected_dark : styles.selected,
              today: theme === "dark" ? styles.today_dark : styles.today,
            }}
            className={`${styles.head} dark:text-gray-300`}
            classNames={{
              head_row: styles.head_row,
              caption: styles.caption,
              caption_label:
                theme === "dark"
                  ? styles.caption_label_dark
                  : styles.caption_label,
              nav_button_next: styles.nav_button_next,
              nav_button_previous: styles.nav_button_previous,
              months: styles.months,
              table: styles.table,
              day: styles.day,
              day_range_start:
                theme === "dark"
                  ? styles.day_range_start_dark
                  : styles.day_range_start,
              day_range_end:
                theme === "dark"
                  ? !filters.fromValue ||
                    !filters.toValue ||
                    filters.fromValue === filters.toValue
                    ? styles.day_range_end_only_dark
                    : styles.day_range_end_dark
                  : !filters.fromValue ||
                    !filters.toValue ||
                    filters.fromValue === filters.toValue
                  ? styles.day_range_end_only
                  : styles.day_range_end,
              day_range_middle:
                theme === "dark"
                  ? styles.day_range_middle_dark
                  : styles.day_range_middle,
            }}
          />
          <div className={`${styles.footer}`}>
            <div
              className={`${styles.footer__days} dark:text-white text-gray-800 text-m md:text-l bg-gray-200 dark:bg-gray-800`}
            >
              {filters.fromValue || filters.toValue ? (
                <div className="flex items-center justify-center gap-10 w-full py-8px px-32px">
                  <p>
                    <span>From: </span> {filters.fromValue}{" "}
                  </p>
                  {filters.toValue ? <p>-</p> : ""}
                  <p>
                    <span>{filters.toValue ? "To: " : ""}</span>{" "}
                    {filters.toValue}
                  </p>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-10 w-full py-8px px-32px">
                  <span>Select Date</span>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between gap-12px mt-4 w-full">
              <button
                className={`${styles.cancel_btn} font-semibold bg-violet-600 dark:bg-violet-700 text-white hover:bg-violet-800 transition-all duration-300`}
                onClick={handleApply}
              >
                Apply
              </button>
              <button
                className={`${styles.cancel_btn} font-semibold bg-violet-200 text-violet-600 dark:bg-gray-800 dark:text-white hover:bg-violet-500 hover:text-white hover:dark:bg-gray-600 transition-all duration-300`}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
