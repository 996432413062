import React from "react";
import clsx from "clsx";
import { Layout } from "@/layout/layout";
import { FundsTopTable } from "./components/funds-top-table/funds-top-table";
import { FundsMain } from "./components/funds-main/funds-main";
import OverviewFiltersProvider from "@/providers/overview-filters-provider/overview-filters-provider";

export const FundsList = () => {
  return (
    <OverviewFiltersProvider>
      <Layout>
        <section className="w-full min-h-screen dark:bg-dark-bg flex flex-col items-center py-9 px-0 md:px-16 overflow-hidden">
          <FundsTopTable />
          <FundsMain />
        </section>
      </Layout>
    </OverviewFiltersProvider>
  );
};
