import React from "react";
import ReactSelect, { Props } from "react-select";
import { SELECT_STYLE_CONFIG } from "./select-style";
import { DropdownIndicator } from "./dropdown-indicator";

interface ISelect extends Props {
  label?: string;
  errorMessage?: string;
  menuHeight?: number;
  isSearchable?: boolean;
  isMulti?: boolean;
}

const RootSelect = ({
  className,
  label,
  name,
  errorMessage,
  menuHeight = 300,
  isSearchable,
  isMulti = false,
  ...rest
}: ISelect) => {
  const styles = SELECT_STYLE_CONFIG(errorMessage);

  return (
    <div>
      {!!label && <label htmlFor={name}>{label}</label>}

      <ReactSelect
        id={name}
        name={name}
        className={className}
        classNamePrefix="select"
        isSearchable={isSearchable}
        isClearable={false}
        isMulti={isMulti}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
        styles={styles}
        menuPlacement="auto"
        {...rest}
      />
    </div>
  );
};

export const MultiSelect = RootSelect;
