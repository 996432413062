import React from "react";
import clsx from "clsx";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange(page: number): void;
}

const Pagination = ({ currentPage, totalPages, onPageChange }: Props) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const { theme } = useTheme();

  return (
    <ul className="flex justify-center mt-4">
      {currentPage > 1 ? (
        <li
          className={clsx(
            "w-8 h-8 flex items-center justify-center font-bold border-gray-300 dark:border-gray-800 border rounded mx-2 cursor-pointer md:hover:bg-violet-200 md:hover:border-violet-200 md:hover:text-gray-800 transition-all duration-300",
          )}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <Icon
            icon={ICON_COLLECTION.chevronLeft}
            className="w-5 h-5"
            iconColor={theme === "dark" ? "#fff" : "#8B5CF6"}
          />
        </li>
      ) : null}
      {pageNumbers.map((number) => (
        <li
          key={number}
          className={clsx(
            "w-8 h-8 flex items-center justify-center font-medium border-gray-300 dark:border-gray-800 border rounded mx-2 cursor-pointer md:hover:bg-violet-200 md:hover:border-violet-200 md:hover:text-gray-800 transition-all duration-300",
            {
              "bg-violet-500 border-violet-500 text-white":
                currentPage === number,
              "text-gray-800 dark:text-white": currentPage !== number,
            },
          )}
          onClick={() => onPageChange(number)}
        >
          {number}
        </li>
      ))}
      {currentPage !== totalPages ? (
        <li
          className={clsx(
            "w-8 h-8 flex items-center justify-center font-bold border-gray-300 dark:border-gray-800 border rounded mx-2 cursor-pointer md:hover:bg-violet-200 md:hover:border-violet-200 md:hover:text-gray-800 transition-all duration-300",
          )}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <Icon
            icon={ICON_COLLECTION.chevronRight}
            className="w-5 h-5"
            iconColor={theme === "dark" ? "#fff" : "#8B5CF6"}
          />
        </li>
      ) : null}
    </ul>
  );
};

export default Pagination;
