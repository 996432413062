import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDebounce, useWindowSize } from "usehooks-ts";
import { DateRange } from "react-day-picker";

import { DatePickerDialog } from "@ui/date-picker/date-picker";
import { HistoryMobileFilters } from "../history-mobile-filters/history-mobile-filters";
import { useTheme } from "@/components/theme-context/theme-context";
import { Search } from "@ui/search/search";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import {
  ActionValue,
  PositionActionsFilters,
} from "@/pages/positions/components/positions-search-bar/position-actions-filters";

export const PositionsHistorySearchBar = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { width } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(() => {
    const q = searchParams.get("q") ?? "";
    const actions = searchParams.has("actions")
      ? ([...searchParams.getAll("actions")] as ActionValue[])
      : [];
    const dateFrom = searchParams.has("dateFrom")
      ? (searchParams.get("dateFrom") as string)
      : "";
    const dateTo = searchParams.has("dateTo")
      ? (searchParams.get("dateTo") as string)
      : "";

    return {
      tab: "history",
      q,
      actions,
      dateFrom,
      dateTo,
    };
  });

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleActionClick = (action: ActionValue) => {
    if (filters.actions.includes(action)) {
      setFilters((prevState) => ({
        ...prevState,
        actions: prevState.actions.filter((item) => item !== action),
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        actions: [...prevState.actions, action],
      }));
    }
  };

  const isMobile = width <= 992;

  const handleSearchInput = (input: string) => {
    setFilters((prevState) => ({
      ...prevState,
      q: input,
    }));
  };

  const handleRange = (range: DateRange | undefined) => {
    if (!range) return;

    console.log("range", {
      dateFrom: range.from?.toISOString() ?? "",
      dateTo: range.to?.toISOString() ?? "",
    });

    setFilters((prevState) => ({
      ...prevState,
      dateFrom: range.from?.toISOString() ?? "",
      dateTo: range.to?.toISOString() ?? "",
    }));
  };

  const debouncedFilters = useDebounce(filters, 500);
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    setSearchParams((prev) => ({
      ...prev,
      ...newParams,
      ...debouncedFilters,
    }));
  }, [debouncedFilters]);

  return (
    <div className="flex justify-end md:justify-between w-full md:relative">
      {!isMobile ? (
        <Search
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          searchValue={filters.q}
          setSearchValue={handleSearchInput}
        />
      ) : null}
      <div className="h-full">
        {!isMobile ? (
          <div
            className={clsx("flex gap-3 h-full", {
              "mr-5": isInputFocused,
            })}
          >
            <PositionActionsFilters
              handleSelect={handleActionClick}
              selectedActions={filters.actions}
            />
            <DatePickerDialog
              handleRange={handleRange}
              initRange={
                !!filters.dateFrom
                  ? {
                      from: new Date(filters.dateFrom),
                      to: new Date(filters.dateTo),
                    }
                  : undefined
              }
              // hasDateInputs={!!filters.dateTo && !!filters.dateFrom}
            />
          </div>
        ) : (
          <div className="flex gap-3">
            <button className="text-violet-600 py-2 px-6 bg-violet-200 rounded-full font-semibold flex items-center gap-2 dark:bg-gray-800 dark:text-white hover:bg-violet-400 dark:hover:bg-gray-700 transition-all duration-200">
              {t("positions.nav-bar.title.csv")}
              <Icon
                icon={ICON_COLLECTION.downloadCloud}
                iconColor={theme === "dark" ? "#fff" : "#7C3AED"}
              />
            </button>
            <HistoryMobileFilters />
          </div>
        )}
      </div>
    </div>
  );
};
