import React, { useState } from "react";
import { TokensTable } from "./tokens-table/tokens-table";
import { useTranslation } from "react-i18next";
import { SelectedTokens } from "./selected-tokens/selected-tokens";
import { SelectedTokensData } from "@constants/tokens";
import { Switcher } from "@/UI/switcher/switcher";
import { Search } from "@/UI/search/search";

export const SelectTokensFilled = () => {
  const handleSwithcerClick = () => {};
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const numberOfElements = SelectedTokensData.length;
  return (
    <div className="h-full w-full md:w-1/2 rounded-lg bg-white p-7 dark:bg-gray-900 border dark:border-gray-800">
      <div className="flex justify-between items-center w-full mb-8">
        <div className="flex items-center gap-2">
          <h3 className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white">
            {t("create-fund-tokens.title")}
          </h3>
          <span className="rounded-full bg-violet-200 h-6 w-6 flex items-center justify-center font-sf-pro text-violet-600">
            {numberOfElements}
          </span>
        </div>
        <div className="hidden md:flex items-center gap-2">
          <Switcher logic={handleSwithcerClick} className="w-44 h-7" />
          <span className="font-sf-pro text-gray-700 dark:text-white text-lg">
            {t("verified")}
          </span>
        </div>
      </div>
      <SelectedTokens />
      <div className="flex items-center justify-between mb-6 relative">
        <Search
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className="flex md:hidden items-center gap-2">
          <Switcher logic={handleSwithcerClick} className="w-44 h-7" />
          <span className="font-sf-pro text-gray-700 dark:text-white text-lg">
            {t("verified")}
          </span>
        </div>
      </div>
      {/*<TokensTable tokenList={[]} />*/}
    </div>
  );
};
