import React, { useEffect, useMemo, useState } from "react";

import { PositionsNavBar } from "./positions-nav-bar/positions-nav-bar";
import { PositionsHoldsSearchBar } from "./positions-search-bar/positions-holds-search-bar";
import { PositionsHoldsTable } from "./positions-holds-table/positions-holds-table";
import { PositionsHistoryTable } from "./positions-history-table/positions-history-table";
import { PositionsHistorySearchBar } from "./positions-search-bar/positions-history-search-bar";

import { Loader } from "@ui/loader/loader";
import { SwipeToSeeMore } from "@ui/swipe/swipe";

import {
  POSITIONS_HOSTORY_DATA,
} from "@/constants/positions-data";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { UTXOResponse } from "@/constants/funds-data";
import { fetchOpenRequests } from "@/api/open-requests";
import { useCardanoSerializedHandlers } from "@/providers/cardano-serializer-provider/hooks/use-cardano-serialized-handlers";

export const TABS = {
  holds: "holds",
  history: "history",
} as const;
export type TabValue = typeof TABS[keyof typeof TABS];

export const PositionsMain = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<TabValue>(() =>
    searchParams.has("tab")
      ? (searchParams.get("tab") as TabValue)
      : TABS.holds,
  );

  const handleTabChange = (tab: TabValue) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      setSearchParams({ tab });
    }
  };

 
  const { getPubKeyHash } = useCardanoSerializedHandlers();
  const [creatorPubKeyHash, setCreatorPubKeyHash] = useState<string | null>(null);

  useEffect(() => {
    const fetchPubKeyHash = async () => {
      const hash = await getPubKeyHash();
      setCreatorPubKeyHash(hash);
    };

    fetchPubKeyHash();
  }, [getPubKeyHash]); // Assuming getPubKeyHash doesn't change, or depends on specific variables
  
  const { data, isLoading, isFetching } = useQuery<UTXOResponse[], Error>({
    queryKey: ["open-requests", creatorPubKeyHash],
    queryFn: fetchOpenRequests,
    enabled: !!creatorPubKeyHash,
  });



  return (
    <div className="w-full h-full rounded-lg bg-white dark:bg-gray-900 p-6 flex flex-col gap-5">
      <PositionsNavBar activeTab={activeTab} setActiveTab={handleTabChange} />
      {activeTab === TABS.holds ? (
        <PositionsHoldsSearchBar />
      ) : (
        <PositionsHistorySearchBar />
      )}
      {!isLoading ? (
        <>
          <div className="w-full overflow-x-scroll md:overflow-x-auto pb-5">
            {activeTab === TABS.holds ? (
              <PositionsHoldsTable data={data} />
            ) : (
              <PositionsHistoryTable data={POSITIONS_HOSTORY_DATA} />
            )}
          </div>
          <SwipeToSeeMore />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
