import { useContext } from "react";
import { BookmarksContext } from "@/providers/bookmarks-provider/context";

export const useFunds = () => {
  const context = useContext(BookmarksContext);

  if (!context) {
    throw new Error(
      "useBookmarks hook should be used within BookmarksProvider.",
    );
  }

  const { funds, topInflow, topOutflow, topChangeDaily, isFundsLoading } =
    context;
  return {
    funds,
    topInflow,
    topOutflow,
    topChangeDaily,
    isFundsLoading,
  };
};
