import { QueryKey } from "@tanstack/react-query";
import { UTXOResponse } from "@constants/funds-data"

const getFetchLink = (creator_pub_key_hash: string) =>
  `https://api.linkage.finance/open-requests?creator_pub_key_hash=${creator_pub_key_hash}`;

  const options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  export const fetchOpenRequests = async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }): Promise<UTXOResponse[]> => {
    // Ensure that the second element of queryKey exists and is a string
    const creator_pub_key_hash = queryKey[1];
    if (typeof creator_pub_key_hash !== 'string' || !creator_pub_key_hash) {
      throw new Error('Creator public key hash is required');
    }
  
    try {
      const response = await fetch(getFetchLink(creator_pub_key_hash), options);
      if (!response.ok) {
        throw new Error(`API responded with status ${response.status}`);
      }
      const utxos: UTXOResponse[] = await response.json();
      return utxos;
    } catch (error) {
      // Improved error handling: Logging the error and rethrowing it
      console.error('Failed to fetch open requests:', error);
      throw new Error(`Failed to fetch open requests: ${(error as Error).message}`);
    }
  };
  