import React from "react";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";

export const TokenItem = ({ token }: { token: Token }) => {
  const imageLink = token.token_image?.startsWith("ipfs")
    ? token.token_image.replace("ipfs://", "https://ipfs.io/ipfs/")
    : token.token_image;
  return (
    <div className="flex items-center justify-between w-full py-3">
      <div className="flex items-center gap-2">
        <img src={imageLink} alt="" className="h-12" />
        <h6 className="font-sf-pro text-lg md:text-xl text-gray-800 dark:text-white font-semibold">
          {token.token_symbol}
        </h6>
        {/*{token.verified ? (*/}
        {/*  <Icon icon={ICON_COLLECTION.circleCheck} iconColor="#7C3AED" />*/}
        {/*) : null}*/}
      </div>
      <div>
        <p className="font-sf-pro text-lg md:text-xl text-gray-800 dark:text-white font-semibold">
          {token.distribution} %
        </p>
      </div>
    </div>
  );
};
