import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Loader } from "@ui/loader/loader";
import { ActualFundType } from "@constants/funds-data";

interface FundsTopTableCardProps {
  title: string;
  card: {
    title: string;
    cardContent: ActualFundType[];
  };
  isLoading: boolean;
}

const KEYS = {
  changes: "fund_price",
  outflow: "outflow_7d",
  inflow: "inflow_7d",
};

export const FundsTopTableCard: React.FC<FundsTopTableCardProps> = ({
  title,
  card,
  isLoading,
}) => {
  // TODO: ig there will be a need in rework after getting actual fund info.
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3 className="top-table__title text-xl tracking-wide font-sf-pro font-semibold dark:text-white">
            {title}
          </h3>
          <div className="flex flex-col w-full mt-3 text-lg gap-3">
            {card.cardContent.map((item, index) => {
              const changes =
                card.title === "changes" ? item.price_change24h : 0;
              const value = item[KEYS[card.title]];

              const firstImage = item.tokens[0].token_image;
              const secondImage = item.tokens[1].token_image;
              return (
                <Link
                  to={`/details/${item.fund_id}`}
                  state={item}
                  key={index}
                  className="flex h-full py-1 px-3 justify-between items-center gap-3.5 w-full font-sf-pro text-gray-600 dark:text-white cursor-pointer transition-colors duration-300 md:hover:bg-violet-50 md:hover:dark:bg-gray-800 rounded-lg"
                >
                  <div className="w-2/6 h-full font-semibold flex items-center gap-2.5	whitespace-nowrap	">
                    <div
                      className="relative"
                      style={{ minWidth: "40px", height: "40px" }}
                    >
                      <div className="absolute top-0 left-0 z-10 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
                        <img src={firstImage} alt="" />
                      </div>
                      <div className="absolute bottom-0 right-0 z-20 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
                        <img src={secondImage} alt="" />
                      </div>
                      <div className="absolute bottom-0 left-0 z-30 w-6 h-6 rounded-full bg-violet-700 border border-white flex items-center justify-center font-bold font-sf-pro text-white text-xs	">
                        {item.tokens.length}
                      </div>
                    </div>
                    <span className="font-semibold pr-10 text-l">
                      {item.fund_name}
                    </span>
                  </div>
                  <span className="font-medium text-l">
                    {value.toLocaleString("en")}
                  </span>
                  {changes !== 0 ? (
                    <span
                      className={clsx(
                        "font-medium text-l flex justify-center items-center max-h-4 gap-1",
                        {
                          "text-green-500": changes > 0,
                          "text-red-500": changes < 0,
                        },
                      )}
                    >
                      {changes}
                      {changes > 0 ? (
                        <Icon
                          icon={ICON_COLLECTION.arrowUp}
                          iconColor="#22C55E"
                        />
                      ) : (
                        <Icon
                          icon={ICON_COLLECTION.arrowDown}
                          iconColor="#EF4444"
                        />
                      )}
                    </span>
                  ) : null}
                </Link>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
