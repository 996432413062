import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import { useTheme } from "@/components/theme-context/theme-context";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Icon } from "@ui/icons/icon";

interface Props {
  children: React.ReactNode;
  placement: Placement;
  title: string;
  buttonClassNames?: string;
  wrapperClassNames?: string;
}

export const Dropdown = ({
  children,
  placement,
  title,
  buttonClassNames,
  wrapperClassNames,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  // popper refs
  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElem, setPopperElem] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(popperRef.current, popperElem, {
    placement,
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useOnClickOutside(popperRef, () => setIsOpen(false));

  return (
    <div ref={popperRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={twMerge(
          buttonClassNames,
          "flex gap-3 p-3 border border-gray-200 rounded-xl dark:border-gray-700 relative",
          isOpen && "border-violet_light dark:border-violet_light",
        )}
      >
        <span className="text-gray-500 font-sf-pro font-medium dark:text-gray-300 text-l">
          {title}
        </span>
        <Icon
          icon={ICON_COLLECTION.chevronDown}
          className={twMerge(
            "w-6 h-6 transition-transform duration-200",
            isOpen && "-rotate-180",
          )}
          iconColor={theme === "dark" ? "#fff" : "#000"}
        />
      </button>
      {isOpen ? (
        <div
          ref={setPopperElem}
          role="dialog"
          aria-label="dropdown"
          className={twMerge(
            wrapperClassNames,
            "dialog-sheet p-3 mt-1 z-30 rounded-lg bg-white dark:bg-gray-950 shadow-lg shadow-gray-300 dark:shadow-gray-800 border border-gray-200 dark:border-gray-950",
          )}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
