import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import clsx from "clsx";
import { useWindowSize } from "usehooks-ts";
import { Formik, FormikHelpers } from "formik";

import { FundInteractionsInfoCard } from "./fund-interactions-info-card/fund-interactions-info-card";
import { Loader } from "@ui/loader/loader";
import { getInteractionsValidationSchema } from "@/pages/fund-interactions/components/fund-interactions-info/validation";
import { AddAdaForm } from "@/pages/fund-interactions/components/fund-interactions-info/forms/add-ada-form";
import { RemoveAdaForm } from "@/pages/fund-interactions/components/fund-interactions-info/forms/remove-ada-form";

import { fetchFundDetails } from "@/api/fund-details";

export type FundInteractionForm = {
  ada: number;
};

const initInteractionForm: FundInteractionForm = {
  ada: 0,
};

export const FundInteractionsInfo = () => {
  const [selectedMobileForm, setSelectedMobileForm] = useState(1);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { accountBalance } = useCardano({ limitNetwork: NetworkType.MAINNET });

  // todo: separate schema for remove ada from fund.
  const validationSchema = getInteractionsValidationSchema(accountBalance);

  const isMobile = width < 992;

  const { fund_id } = useParams();

  const {
    data: fund,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fund-details", fund_id],
    queryFn: fetchFundDetails,
    enabled: !!fund_id,
  });

  const handleSubmitForm = (
    value: FundInteractionForm,
    helpers: FormikHelpers<FundInteractionForm>,
  ) => {
    helpers.resetForm();
  };

  return (
    <section className="w-11/12 md:w-full flex flex-col justify-center gap-24px">
      {isLoading || isFetching || !fund ? (
        <div className="w-full p-24px flex flex-col bg-white dark:bg-dark-gray900 rounded-lg">
          <Loader />
        </div>
      ) : (
        <FundInteractionsInfoCard fundInfo={fund} />
      )}
      {!isMobile ? (
        <div className="w-full flex gap-24px">
          <Formik
            initialValues={initInteractionForm}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            isInitialValid={false}
            onSubmit={handleSubmitForm}
          >
            <AddAdaForm fundInfo={fund} />
          </Formik>
          <Formik
            initialValues={initInteractionForm}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            isInitialValid={false}
            onSubmit={handleSubmitForm}
          >
            <RemoveAdaForm />
          </Formik>
        </div>
      ) : (
        <div className="w-full p-12px flex flex-col gap-24px bg-white dark:bg-dark-gray900 rounded-lg">
          <div className="w-full flex gap-1 font-sf-pro bg-gray-100 dark:bg-transparent p-1">
            <button
              className={clsx(
                "w-1/2 px-24px py-8px text-m rounded-lg flex items-center justify-center",
                {
                  "bg-violet-600 dark:bg-violet-700 text-white font-semibold":
                    selectedMobileForm === 1,
                  "text-gray-800 dark:text-gray-500": selectedMobileForm === 2,
                },
              )}
              onClick={() => setSelectedMobileForm(1)}
            >
              {t("funds-interactions-forms.add.btnMobile")}
            </button>
            <button
              className={clsx(
                "w-1/2 px-24px py-8px text-m rounded-lg flex items-center justify-center",
                {
                  "bg-violet-600 dark:bg-violet-700 text-white font-semibold":
                    selectedMobileForm === 2,
                  "text-gray-800 dark:text-gray-500": selectedMobileForm === 1,
                },
              )}
              onClick={() => setSelectedMobileForm(2)}
            >
              {t("funds-interactions-forms.remove.btnMobile")}
            </button>
          </div>
          {selectedMobileForm === 1 ? (
            <Formik
              initialValues={initInteractionForm}
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              validateOnMount
              isInitialValid={false}
              onSubmit={handleSubmitForm}
            >
              <AddAdaForm fundInfo={fund} />
            </Formik>
          ) : (
            <Formik
              initialValues={initInteractionForm}
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              validateOnMount
              isInitialValid={false}
              onSubmit={handleSubmitForm}
            >
              <RemoveAdaForm />
            </Formik>
          )}
        </div>
      )}
    </section>
  );
};
