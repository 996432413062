import React, { useEffect, useState } from "react";

interface Props {
  absoluteRef: React.RefObject<HTMLDivElement>;
  overlapRef: React.RefObject<HTMLParagraphElement>;
  additionalDeps?: any[];
}

export const useOverlapTrack = ({
  absoluteRef,
  overlapRef,
  additionalDeps = [],
}: Props) => {
  const [isOverlapping, setIsOverlapping] = useState(false);

  useEffect(() => {
    const checkOverlap = () => {
      const absoluteItemRect = absoluteRef.current!.getBoundingClientRect();
      const overlapItemRect = overlapRef.current!.getBoundingClientRect();

      const overlap =
        absoluteItemRect.right > overlapItemRect.left &&
        absoluteItemRect.left < overlapItemRect.right &&
        absoluteItemRect.bottom > overlapItemRect.top &&
        absoluteItemRect.top < overlapItemRect.bottom;

      setIsOverlapping(overlap);
    };

    checkOverlap();

    window.addEventListener("resize", checkOverlap);

    return () => {
      window.removeEventListener("resize", checkOverlap);
    };
  }, [...additionalDeps]);

  return isOverlapping;
};
