import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useDebounce, useWindowSize } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";

import RangeDropdown from "@ui/range-dropdown/range-dropdown";
import { Switcher } from "@ui/switcher/switcher";
import { Search } from "@ui/search/search";
import HoldsMobileFilters from "@/pages/positions/components/holds-mobile-filters/holds-mobile-filters";

export const PositionsHoldsSearchBar = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [params, setParams] = useSearchParams();

  const [filters, setFilters] = useState(() => {
    const q = params.get("q") ?? "";
    const onlyVerified = params.get("onlyVerified") !== "false" ?? false;
    const totalLockedRange = params.has("totalLockedRange")
      ? [...params.getAll("totalLockedRange")].map((item) => Number(item))
      : [0, 100_000];
    const priceChangeRange = params.has("priceChangeRange")
      ? [...params.getAll("priceChangeRange")].map((item) => Number(item))
      : [0, 100];
    const priceChangePeriod = params.get("priceChangePeriod") ?? "all";

    return {
      q,
      onlyVerified,
      totalLockedRange,
      priceChangeRange,
      priceChangePeriod,
    };
  });

  const [isInputFocused, setIsInputFocused] = useState(false);

  const isMobile = width <= 992;

  const handleSwitchVerified = () => {
    setFilters((prevState) => ({
      ...prevState,
      onlyVerified: !prevState.onlyVerified,
    }));
  };

  const handleSearchInput = (input: string) => {
    setFilters((prevState) => ({
      ...prevState,
      q: input,
    }));
  };

  const handleTotalLockedRange = (values: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      totalLockedRange: values,
    }));
  };

  const handlePriceChange = (values: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      priceChangeRange: values,
    }));
  };

  const handlePricePeriod = (period: string) => {
    setFilters((prevState) => ({
      ...prevState,
      priceChangePeriod: period,
    }));
  };

  // update params only after user stop input
  const debouncedFilters = useDebounce(filters, 500);

  useEffect(() => {
    const newParams = new URLSearchParams(params);
    setParams((prev) => ({
      ...prev,
      ...newParams,
      ...debouncedFilters,
    }));
  }, [debouncedFilters]);

  return (
    <div className="flex justify-end md:justify-between w-full md:relative">
      {!isMobile ? (
        <Search
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          searchValue={filters.q}
          setSearchValue={handleSearchInput}
        />
      ) : null}
      <div
        className={clsx("flex gap-3", {
          "mr-5": isInputFocused,
        })}
      >
        <div className="flex gap-2 items-center">
          <div className="items-center gap-2 hidden md:flex">
            <Switcher logic={handleSwitchVerified} className="w-44 h-7" />
            <span className="font-sf-pro text-gray-700 dark:text-white text-l">
              {t("verified")}
            </span>
          </div>
          {!isMobile ? (
            <>
              <RangeDropdown
                title={t("funds-list-table.search-bar.dropdowns.total")}
                rangeValue={filters.totalLockedRange.map((item) =>
                  Number(item),
                )}
                setRangeValue={handleTotalLockedRange}
                min={0}
                max={100000}
                activeRadio={filters.priceChangePeriod}
                setActiveRadio={handlePricePeriod}
              />
              <RangeDropdown
                title={t("funds-list-table.search-bar.dropdowns.price")}
                rangeValue={filters.priceChangeRange}
                setRangeValue={handlePriceChange}
                min={0}
                max={100}
                mode="price"
                activeRadio={filters.priceChangePeriod}
                setActiveRadio={handlePricePeriod}
              />
            </>
          ) : (
            <HoldsMobileFilters
              rangeTotalValue={filters.totalLockedRange}
              setTotalRangeValue={handleTotalLockedRange}
              rangePriceValue={filters.priceChangeRange}
              setPriceRangeValue={handlePriceChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};
