import { TOKENS_ICONS } from "@/assets/icons/tokens/tokensIcons";
import { TOP_FUND_COLLECTION } from "@/assets/icons/top-funds/iconsConstant";

// todo: all funds should have unified type..
export type InteractionsFund = {
  count: {
    quantity: number;
    countIcon_1: any;
    countIcon_2: any;
  };
  title: string;
  featured: boolean;
  bookmarked: boolean;
  verified: boolean;
  price: number;
  change_24: number;
  total: number;
  inflow: string;
  outflow: string;
  fee: number;
  id: number;
};

export const FUNDS_INTERACTIONS_DATA: InteractionsFund[] = [
  {
    count: {
      quantity: 19,
      countIcon_1: TOP_FUND_COLLECTION.topFund_1_1,
      countIcon_2: TOP_FUND_COLLECTION.topFund_1_2,
    },
    title: "Claire",
    featured: true,
    bookmarked: false,
    verified: false,
    price: 25351,
    change_24: 10.12,
    total: 44875,
    inflow: "19,304.6",
    outflow: "29.631",
    fee: 1.1,
  },
  {
    count: {
      quantity: 9,
      countIcon_1: TOP_FUND_COLLECTION.topFund_2_1,
      countIcon_2: TOP_FUND_COLLECTION.topFund_2_2,
    },
    title: "Austin",
    featured: true,
    bookmarked: false,
    verified: true,
    price: 55351,
    change_24: 20.12,
    total: 44875,
    inflow: "5,304.6",
    outflow: "1.631",
    fee: 1,
  },
  {
    count: {
      quantity: 2,
      countIcon_1: TOP_FUND_COLLECTION.topFund_3_1,
      countIcon_2: TOP_FUND_COLLECTION.topFund_3_2,
    },
    title: "Pembroke Pines",
    featured: true,
    bookmarked: false,
    verified: true,
    price: 35331,
    change_24: -1.12,
    total: 44875,
    inflow: "4,304.6",
    outflow: "15.631",
    fee: 0.7,
  },
].map((fund, idx) => ({ ...fund, id: idx }));

export const FUND_INTERACTION_INFO = {
  count: {
    quantity: 2,
    countIcon_1: TOP_FUND_COLLECTION.topFund_3_1,
    countIcon_2: TOP_FUND_COLLECTION.topFund_3_2,
  },
  title: "Claire",
  featured: true,
  bookmarked: false,
  tokens: [
    {
      title: "CBLP",
      icon: TOKENS_ICONS.athom,
    },
    {
      title: "EMP",
      icon: TOKENS_ICONS.bdoge,
    },
    {
      title: "INDIE",
      icon: TOKENS_ICONS.chimpy,
    },
    {
      title: "SNEK",
      icon: TOKENS_ICONS.claw,
    },
    {
      title: "WMT",
      icon: TOKENS_ICONS.cnft,
    },
    {
      title: "iUSD",
      icon: TOKENS_ICONS.fractals,
    },
    {
      title: "MCOS",
      icon: TOKENS_ICONS.hosky,
    },
    {
      title: "CHRY",
      icon: TOKENS_ICONS.leaf,
    },
    {
      title: "CLAY",
      icon: TOKENS_ICONS.nftc,
    },
  ],
  bottom: [
    {
      title: "price",
      value: "65,351.8",
    },
    {
      title: "change_24",
      value: "8.12%",
    },
    {
      title: "change_30",
      value: "4.11%",
    },
    {
      title: "total",
      value: "74,875",
    },
    {
      title: "inflow",
      value: "9,304.6",
    },
    {
      title: "outflow",
      value: "9,631",
    },
    {
      title: "fee",
      value: "1%",
    },
  ],
};

export const FUND_INTERACTION_FORMS = [
  {
    id: 1,
    title: "funds-interactions-forms.add.title",
    formTitle: "funds-interactions-forms.add.formTitle",
    balance: "funds-interactions-forms.add.balance",
    points: [
      {
        label: "funds-interactions-forms.add.points.cost",
        value: "funds-interactions-forms.dashes",
        id: 1,
      },
      {
        label: "funds-interactions-forms.add.points.fees",
        value: "funds-interactions-forms.dashes",
        id: 2,
      },
      {
        label: "funds-interactions-forms.add.points.get",
        value: "funds-interactions-forms.add.points.getValue",
        id: 3,
      },
    ],
    btn: "funds-interactions-forms.add.btn",
  },
  {
    id: 2,
    title: "funds-interactions-forms.remove.title",
    formTitle: "funds-interactions-forms.remove.formTitle",
    balance: "funds-interactions-forms.remove.balance",
    balanceSec: "funds-interactions-forms.remove.balanceSec",
    points: [
      {
        label: "funds-interactions-forms.remove.points.cost",
        value: "funds-interactions-forms.dashes",
        id: 1,
      },
      {
        label: "funds-interactions-forms.remove.points.fees",
        value: "funds-interactions-forms.dashes",
        id: 2,
      },
      {
        label: "funds-interactions-forms.remove.points.get",
        value: "funds-interactions-forms.remove.points.getValue",
        id: 3,
      },
      {
        label: "funds-interactions-forms.remove.points.pay",
        value: "funds-interactions-forms.remove.points.payValue",
        id: 4,
      },
    ],
    btn: "funds-interactions-forms.remove.btn",
  },
];
