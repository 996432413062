import React from "react";

import { Layout } from "@/layout/layout";
import { PositionsMain } from "./components/positions-main";

export const Positions = () => {
  return (
    <Layout>
      <section className="w-full min-h-screen dark:bg-dark-bg py-3 md:py-8 px-4 md:px-16">
        <PositionsMain />
      </section>
    </Layout>
  );
};
