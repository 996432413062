const chromeStoreUrl = "https://chrome.google.com/webstore/detail/";
export const WALLET_LINKS = {
  nami: `${chromeStoreUrl}nami/lpfcbjknijpeeillifnkikgncikgfhdo`,
  flint: `${chromeStoreUrl}flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj`,
  typhon: `${chromeStoreUrl}typhon-wallet/kfdniefadaanbjodldohaedphafoffoh`,
  yoroi: `${chromeStoreUrl}yoroi/ffnbelfdoeiohenkjibnmadjiehjhajb`,
  eternl: `${chromeStoreUrl}eternl/kmhcihpebfmpgmihbkipmjlmmioameka`,
  gerowallet: `${chromeStoreUrl}gerowallet/bgpipimickeadkjlklgciifhnalhdjhe`,
  nufi: `${chromeStoreUrl}nufi/gpnihlnnodeiiaakbikldcihojploeca`,
  lace: `${chromeStoreUrl}lace/gafhhkghbfjjkeiendhlofajokpaflmk`,
};
