import React, { useState } from "react";
import Slider from "rc-slider";
import clsx from "clsx";
import { useLockedBody } from "usehooks-ts";

import { ICON_COLLECTION } from "../icons/icon-list";
import { Icon } from "../icons/icon";
import { useTheme } from "@/components/theme-context/theme-context";
import styles from "./range-dropdown.module.scss";
import "./range-dropdown.scss";
import { Search } from "../search/search";
import { Filters } from "@/pages/funds-list/components/funds-main/funds-main";
import "rc-slider/assets/index.css";

interface MobileListFiltersProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  // handleSwitchVerified?: () => void;
  clearable?: boolean;
}

const MobileListFilters: React.FC<MobileListFiltersProps> = ({
  filters,
  setFilters,
  // handleSwitchVerified,
  clearable,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);

  const onChangeFilters = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const handleTotalRangeChange = (value) => {
    onChangeFilters("totalLockedRange", value);
  };
  const handlePriceRangeChange = (value) => {
    onChangeFilters("priceChangeRange", value);
  };
  const handleFeeRangeChange = (value) => {
    onChangeFilters("feeRange", value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleApply = () => {
    setIsOpen(false);
    setIsFiltersSelected(true);
  };

  useLockedBody(isOpen);

  return (
    <div>
      <button
        className="flex gap-3 p-3 border border-gray-200 rounded-xl dark:border-gray-700 dark:bg-gray-900 relative"
        onClick={toggleDropdown}
      >
        <Icon
          icon={ICON_COLLECTION.settings}
          iconColor={theme === "dark" ? "#D1D5DB" : "#9CA3AF"}
        />
        {searchValue && (
          <span
            className={clsx(
              "w-12px h-12px rounded-full absolute -top-1 -right-1 bg-red-500",
              {
                hidden: !isFiltersSelected,
              },
            )}
          ></span>
        )}
      </button>
      <div
        className={clsx("mobile-dropdown-blur", {
          "mobile-dropdown-blur--open": isOpen,
        })}
        onClick={() => setIsOpen(false)}
      ></div>
      <div
        className={clsx(
          "rounded-tl-3xl rounded-tr-3xl fixed z-50 left-0 bottom-0 w-full flex flex-col gap-3 items-center p-4 bg-white dark:bg-gray-950 transition-all duration-700",
          {
            "translate-y-full": !isOpen,
            "translate-y-0": isOpen,
          },
        )}
      >
        <span
          className="w-16 h-1.5 rounded bg-gray-300"
          onClick={() => setIsOpen(false)}
        ></span>
        <div className="w-full flex justify-between">
          <h3 className="w-full font-sf-pro text-gray-800 font-semibold text-xl dark:text-white">
            Filters
          </h3>
          {clearable && (
            <span className="h-24px text-l text-gray-500 font-sf-pro font-semibold whitespace-nowrap flex items-center justify-center">
              Clear all
            </span>
          )}
        </div>
        {/*<div className="items-center gap-2 flex w-full py-3">*/}
        {/*  <button*/}
        {/*    onClick={handleSwitchVerified}*/}
        {/*    className={clsx(`w-44 h-7 switcher after:bg-white after:w-2/5`, {*/}
        {/*      "bg-violet-500 dark:bg-violet-700":*/}
        {/*        !filters.onlyVerified === false,*/}
        {/*      "switcher--clicked bg-gray600": !filters.onlyVerified,*/}
        {/*    })}*/}
        {/*  ></button>*/}
        {/*  <span className="font-sf-pro text-gray-700 dark:text-white">*/}
        {/*    Only Verified*/}
        {/*  </span>*/}
        {/*</div>*/}
        <Search
          isExpand={false}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="flex w-full flex-col gap-2">
          <h4 className="text-gray-500 font-sf-pro font-semibold">
            Strike price:
          </h4>
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                value={
                  filters.totalLockedRange ? filters.totalLockedRange[0] : ""
                }
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                onChange={(e) =>
                  handleTotalRangeChange([
                    +e.target.value,
                    filters.totalLockedRange ? filters.totalLockedRange[1] : "",
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.cross}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                value={
                  filters.totalLockedRange ? filters.totalLockedRange[1] : ""
                }
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                onChange={(e) =>
                  handleTotalRangeChange([
                    filters.totalLockedRange ? filters.totalLockedRange[0] : "",
                    +e.target.value,
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.cross}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
          <div className="px-3">
            <Slider
              range
              min={0}
              max={100000}
              value={filters.totalLockedRange}
              onChange={handleTotalRangeChange}
              className="range-slider mb-3"
            />
          </div>
        </div>
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="flex w-full flex-col gap-2">
          <h4 className="text-gray-500 font-sf-pro font-semibold">
            Price change:
          </h4>
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                value={
                  filters.priceChangeRange ? filters.priceChangeRange[0] : ""
                }
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                onChange={(e) =>
                  handlePriceRangeChange([
                    +e.target.value,
                    filters.priceChangeRange ? filters.priceChangeRange[1] : "",
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                value={
                  filters.priceChangeRange ? filters.priceChangeRange[1] : ""
                }
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                onChange={(e) =>
                  handlePriceRangeChange([
                    filters.priceChangeRange ? filters.priceChangeRange[0] : "",
                    +e.target.value,
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
          <div className="flex w-full justify-between px-1">
            <div className={`${styles["radio-button"]}`}>
              <input
                type="radio"
                name="radio"
                checked={filters.activePriceRadio === "24h"}
                onChange={() => onChangeFilters("activePriceRadio", "24h")}
                className={styles["radio-button__input"]}
              />
              <label
                htmlFor="radio"
                className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
              >
                24h
              </label>
            </div>
            <div className={`${styles["radio-button"]}`}>
              <input
                type="radio"
                name="radio"
                checked={filters.activePriceRadio === "7d"}
                onChange={() => onChangeFilters("activePriceRadio", "7d")}
                className={styles["radio-button__input"]}
              />
              <label
                htmlFor="radio"
                className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
              >
                7d
              </label>
            </div>
            <div className={`${styles["radio-button"]}`}>
              <input
                type="radio"
                name="radio"
                checked={filters.activePriceRadio === "all"}
                onChange={() => onChangeFilters("activePriceRadio", "all")}
                className={styles["radio-button__input"]}
              />
              <label
                htmlFor="radio"
                className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
              >
                All time
              </label>
            </div>
          </div>
          <div className="px-3">
            <Slider
              range
              min={0}
              max={100}
              value={filters.priceChangeRange}
              onChange={handlePriceRangeChange}
              className="range-slider mb-3"
            />
          </div>
        </div>
        <span className="w-28 h-px bg-gray-300"></span>
        {filters.feeRange ? (
          <div className="flex w-full flex-col gap-2">
            <h4 className="text-gray-500 font-sf-pro font-semibold">Fee:</h4>
            <div className="flex justify-between items-center gap-3">
              <div className="flex flex-col w-1/2 relative">
                <input
                  type="number"
                  value={filters.feeRange ? filters.feeRange[0] : ""}
                  className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                  onChange={(e) =>
                    handleFeeRangeChange([
                      +e.target.value,
                      filters.feeRange ? filters.feeRange[1] : "",
                    ])
                  }
                />
                <Icon
                  icon={ICON_COLLECTION.percent}
                  iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                  iconSize="22px"
                  className="absolute top-1/2 right-2 -translate-y-1/2"
                />
              </div>
              <div className="flex flex-col w-1/2 relative">
                <input
                  type="number"
                  value={filters.feeRange ? filters.feeRange[1] : ""}
                  className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                  onChange={(e) =>
                    handleFeeRangeChange([
                      filters.feeRange ? filters.feeRange[0] : "",
                      +e.target.value,
                    ])
                  }
                />
                <Icon
                  icon={ICON_COLLECTION.percent}
                  iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                  iconSize="22px"
                  className="absolute top-1/2 right-2 -translate-y-1/2"
                />
              </div>
            </div>
            <div className="px-3">
              <Slider
                range
                min={0}
                max={100}
                value={filters.feeRange}
                onChange={handleFeeRangeChange}
                className="range-slider mb-3"
              />
            </div>
          </div>
        ) : null}
        <div className="flex w-full justify-between gap-3">
          <button
            onClick={handleApply}
            className="w-1/2 h-12 text-white bg-violet-500 dark:bg-violet-700 rounded-full flex items-center justify-center"
          >
            Apply
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="w-1/2 h-12 text-violet-600 bg-violet-200 dark:bg-gray-800 dark:text-white rounded-full flex items-center justify-center"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileListFilters;
