import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

import PieChart from "./pie-chart/pie-chart";
import { PieDatePicker } from "./pie-date-picker/pie-date-picker";
import { PieDatepickerMobile } from "./pie-date-picker/pie-datepicker-mobile";
import { useTheme } from "@/components/theme-context/theme-context";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

interface Props {
  data: any;
  legendData: { label: string; color: string; value: number }[];
  chartRef: React.RefObject<ChartJSOrUndefined<"pie", any, unknown>>;
}

export const FundDetailsDiagram = ({ chartRef, data, legendData }: Props) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { theme } = useTheme();

  const [showAllTokens, setShowAllTokens] = useState(false);

  const isMobile = width < 992;

  const visibleData =
    legendData.length > 7
      ? isMobile
        ? legendData.slice(0, 6)
        : legendData.slice(0, 7)
      : legendData;

  const hiddenData =
    legendData.length > 7
      ? isMobile
        ? legendData.slice(6, legendData.length)
        : legendData.slice(7, legendData.length)
      : [];

  return (
    <div className="w-full md:w-1/3 h-full flex flex-col gap-32px md:gap-0 justify-between items-center p-16px rounded-lg bg-white dark:bg-gray-900">
      <div className="w-full flex items-center justify-between">
        <span className="font-sf-pro text-l font-semibold text-gray-800 dark:text-white">
          {t("fund-details.pieDiagram.title")}
        </span>
        {/*isMobile ? <PieDatepickerMobile/> : <PieDatePicker />*/}
      </div>
      <div className="w-8/12 md:w-5/12 flex items-center justify-center">
        <PieChart chartRef={chartRef} data={data} />
      </div>
      <div className="w-full flex items-center justify-center gap-12px flex-wrap gap-y-24px md:px-32px">
        {visibleData.map((item, index) => (
          <div key={index} className="flex items-center gap-8px font-sf-pro">
            <div
              className="h-16px w-16px rounded"
              style={{ backgroundColor: item.color }}
            ></div>
            <span className="font-semibold text-gray-800 dark:text-white text-s">
              {item.label}
            </span>
            <span className="font-medium text-gray-500 dark:text-gray-400 text-s">
              {item.value}%
            </span>
          </div>
        ))}
        <div className="flex items-center justify-center relative">
          {hiddenData.length > 0 ? (
            <button
              className="rounded-full px-8px h-6 flex items-center justify-center bg-violet-200 dark:bg-gray-500 border-2 border-transparent hover:border-violet-600 hover:dark:border-gray-100 transition-all duration-300"
              onClick={() => setShowAllTokens(!showAllTokens)}
            >
              <span className="font-m font-medium text-violet-500 dark:text-white">
                +{hiddenData.length}
              </span>
            </button>
          ) : null}
          <div
            className="absolute z-[70] bottom-full right-full md:left-full rounded bg-white dark:bg-gray-950 flex flex-col justify-center gap-8px"
            style={{
              boxShadow:
                theme === "dark"
                  ? "0px 10px 20px 10px rgba(0, 0, 0, 0.10)"
                  : "0px 10px 20px 10px rgba(75, 85, 99, 0.10)",
              padding: showAllTokens ? "12px" : "",
              minWidth: "120px",
            }}
          >
            {hiddenData && showAllTokens
              ? hiddenData.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-8px font-sf-pro p-8px"
                  >
                    <div
                      className="h-16px w-16px rounded"
                      style={{ backgroundColor: item.color }}
                    ></div>
                    <span className="font-semibold text-gray-800 dark:text-white text-s">
                      {item.label}
                    </span>
                    <span className="font-medium text-gray-500 dark:text-gray-400 text-s">
                      {item.value}%
                    </span>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};
