import clsx from "clsx";
import React from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";

import { Layout } from "@/layout/layout";
import { FundInteractionsMain } from "./components/fund-interactions-main/fund-interactions-main";
import { FUND_INTERACTION_FORMS } from "@/constants/funds-interactions";
import { FundInteractionsFormCard } from "./components/fund-interactions-info/fund-interaction-form-card/fund-interaction-form-card";
import { FundInteractionsFormCardMob } from "./components/fund-interactions-info/fund-interaction-form-card/fund-interaction-form-card-mob";

export const FundInteractions = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < 992;

  return (
    <Layout>
      <section className="w-full min-h-screen dark:bg-dark-bg flex flex-col items-center py-9 px-0 md:px-16 overflow-hidden">
        <FundInteractionsMain />
        {!isMobile ? (
          <div className="w-full flex gap-24px mt-24px pointer-events-none">
            {FUND_INTERACTION_FORMS.map((pointsItem, index) => (
              <div className="w-full relative" key={index}>
                <FundInteractionsFormCard
                  pointsItem={pointsItem}
                  width={"w-full"}
                />
                <div className="absolute top-0 left-0 w-full h-full backdrop-blur-sm rounded-lg z-10 flex items-center justify-center pointer-events-none	">
                  <span className="text-2xl font-sf-pro font-semibold text-gray-800 dark:text-white">
                    {t("funds-interactions-forms.empty")}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-11/12 mt-16px relative pointer-events-none">
            <div className="w-full p-12px flex flex-col gap-24px bg-white dark:bg-dark-gray900 rounded-lg">
              <div className="w-full flex gap-1 font-sf-pro bg-gray-100 dark:bg-transparent p-1">
                <button className="w-1/2 px-24px py-8px text-m rounded-lg flex items-center justify-center bg-violet-600 dark:bg-violet-700 text-white font-semibold">
                  {t("funds-interactions-forms.add.btnMobile")}
                </button>
                <button className="w-1/2 px-24px py-8px text-m rounded-lg flex items-center justify-center text-gray-800 dark:text-gray-500">
                  {t("funds-interactions-forms.remove.btnMobile")}
                </button>
              </div>
              <FundInteractionsFormCardMob item={FUND_INTERACTION_FORMS[0]} />
            </div>
            <div className="absolute top-0 left-0 w-full h-full backdrop-blur-sm rounded-lg z-10 flex items-center justify-center">
              <span className="text-2xl font-sf-pro font-semibold text-gray-800 dark:text-white">
                {t("funds-interactions-forms.empty")}
              </span>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};
