import React from "react";
import { useWindowSize } from "usehooks-ts";

import { Layout } from "@/layout/layout";

export const TermsOfService = () => {
  const { width } = useWindowSize();

  const isMobile = width < 992;

  return (
    <Layout>
      <section
        className="w-full dark:bg-dark-bg py-3 md:py-8 px-4 md:px-16"
        style={{ minHeight: isMobile ? "1700px" : "100vh" }}
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 md:p-12 max-w-3xl mx-auto">
          <h1 className="text-2xl md:text-3xl font-bold mb-4">Terms of Use of Linkage Dev Website</h1>
          <p className="text-gray-700 dark:text-gray-300 mb-4">Last revised: 21st May, 2024</p>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">1. Introduction</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This document sets out the terms and conditions on which LinkageDev OÜ will provide services to you through the website <a href="https://www.linkage.finance" className="text-blue-600 dark:text-blue-400">https://www.linkage.finance</a> (hereinafter the "Website").
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            LinkageDev OÜ is duly registered and acting under the laws of Estonia, with registered number 16781488 and registered address at Harju maakond, Tallinn, Kesklinna linnaosa, Tornimäe tn 7-26, 10145.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            In this document, references to we/our/us are references to LinkageDev OÜ (“Company”). Any references to Customer/you/your are references to you as a customer of the Company and user of our Services. Under this agreement, the term “Services” shall mean collectively all the services offered by the Company: interface, informational content and the smart-contract deployment service.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This Agreement does not govern your interactions with Linkage protocol. Linkage protocol is permissionless, therefore the protocol and its decentralised application (DApp) are not hosted by the Company. The full extent of Services provided by the Company with regard to Linkage protocol is outlined below.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            By signing up to use any service through the Website or any of our associated websites, you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement. You have familiarized yourself with our Privacy Policy and Cookie Policy. This Agreement will govern the use of the Services provided by the Company. By using it, you agree to be bound by the terms and conditions set out on this agreement (the "Agreement"). This Agreement will come into effect when you confirm that you agree to it.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT USE THE SERVICE OR THE WEBSITE.
          </p>
          <p className="text-black-700 dark:text-black-300 mb-4">
            WE ARE NOT A BROKER, FINANCIAL INSTITUTION, EXCHANGE, CRYPTOCURRENCY WALLET, OR CREDITOR. THE SERVICES ARE A DECENTRALISED PROTOCOL INTERFACE ONLY. 
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Use of this Website or blockchain technologies, specifically virtual (crypto) currencies or assets, NFT, tokens, wallets (collectively “Digital Assets”) may be illegal in some jurisdictions. It is your responsibility to know the regulatory requirements concerning transactions with Digital Assets in your jurisdiction before using the Website.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This Agreement is not a solicitation for investment and does not represent in any way an offering of securities in any jurisdiction.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            These Terms of Use can be published in different languages for localization purposes. Still, in case of any discrepancies between the English version and version in language other than English, the English version shall always prevail.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">2. Eligibility and Minimal Technical Requirements</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            To be eligible to use the Website, you must be at least 18 years old, have the capacity to enter into legally binding contracts and reside in a country in which the Website is available, or you must perform the transactions by means granted by your legal representative for use on the Website or for free use.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Linkage protocol is developed on the Cardano blockchain and requires a wallet compatible with Cardano to operate. Deploying the smart-contract requires performing a Cardano transaction.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Website is developed and tested on market-standard hardware (windows/macOS/Linux). To access the Website, you need a desktop or mobile device with the latest available software and firmware, a browser (Chromium, Safari, Opera, etc.).  Regular updates to hardware and software may be required to maintain access to the Website. Users are encouraged to verify compatibility before engaging in transactions. The Company shall not be liable for issues arising from incompatibility with user-specific hardware or software configurations.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">3. Services</h2>
          <h3 className="text-lg md:text-xl font-semibold mb-2">Smart-Contract Deployment Service</h3>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Linkage protocol is a fully decentralised, autonomous, community governed protocol that operates independently of the Company. For the payment of a one-off fee, the Company assists you in deploying a smart-contract and grants you a license to use the  ("Smart-Contract") provided by the Company. The Smart-Contract is compatible with the Linkage protocol.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The service allows to deploy, interact with, and utilize the Smart-Contract in accordance with its designed functions and capabilities. The user is permitted to use the Smart-Contract for lawful purposes only, whether for personal projects or in a commercial setting.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company retains all ownership and intellectual property rights in and to the Smart-Contract. This Agreement does not convey any rights of ownership in or related to the Smart-Contract, or any intellectual property rights owned by the Company.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The user is prohibited from modifying, distributing, copying, or creating derivative works based on the Smart-Contract without explicit written permission from the Company. The user shall not reverse-engineer, decompile, or disassemble the Smart-Contract, except as permitted by applicable law.
          </p>
          
          <h3 className="text-lg md:text-xl font-semibold mb-2">Other Services</h3>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            All information provided on the Website is for informational purposes only and should not be construed as professional advice. You should not take, or refrain from taking, any action based on any information contained on the Website. Before you make any financial, legal, or other decisions please examine the terms and conditions, the operation of Linkage protocol and consult an expert, as necessary.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Linkage protocol ecosystem is open source. Its community members act independently of the Company. We assume no liability for actions of entities not associated with the Company.
          </p>
          
          <h3 className="text-lg md:text-xl font-semibold mb-2">Role of the Company</h3>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company is one of, but not the exclusive developer of the Linkage protocol. The Linkage protocol is developed by its community and the Company is one of the community members developing its front-end interface and contributing to the development of the protocol. None of the obligations or functions of the Company grant it ultimate control over the Linkage protocol. The company is not a party to any transaction made by users on the Linkage protocol.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            You acknowledge that you retain control over your Digital Assets at all times. Linkage has no access to your private keys, and you are alone responsible for ensuring access to your assets. The company is not an intermediary, agent, advisor, or custodian in relation to any transaction made via the Linkage protocol. We are under no obligation to fulfil any order, authorisation issued in relation to any blockchain transaction. Excluding the information collected in accordance with our privacy policy, we do not hold any information of any users, users’ identities, or Services beyond what is available or obtainable publicly via the blockchain. 
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Any code or smart contract constructed through the service must be inputted into the Linkage protocol. We do not assist or provide technical support for integrating the smart contracts prepared as part of the service offering into the Linkage protocol.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">4. Licence to Use the Website</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            To the extent necessary to use the Website and for the duration of this Agreement, we grant you a worldwide, non-exclusive, non-transferable, royalty-free license.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            You hereby acknowledge that all intellectual property rights on the Website and its individual components such as videos, audios, source codes, images, logos, trademarks, or any other materials, including but not limited to copyright, patents, trademark rights, know-how, belong to the Company. Except as explicitly stated in this Agreement, the Company does not to any extent assign, transfer or sell any of its intellectual property rights, in particular copyrights, to the User, as well as it does not grant any license to the User other than the license described in this chapter.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            You agree to grant the Company a perpetual, non-exclusive, worldwide, royalty-free, sublicensable, assignable and transferable license to any Content submitted via the Website for any act restricted by any intellectual property right (including copyright) for any purpose reasonably related to the provision and operation of the Website. Such acts include the right to reproduce, make available and communicate to the public, display, perform, distribute, translate, and create adaptations or derivative works of your content. Furthermore, you allow us to use and, to the fullest extent, release your intellectual property rights to any feedback, reports, or suggestions submitted for the purposes of improving the Website and the service provided by the Company. The Company owes you no renumeration for the use of such intellectual property.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The User is authorized to use the Website exclusively for the User’s own personal use. Any data or any other materials found on the Website, both those that are protected under copyright law and those that are not, cannot be used for the User’s commercial activities.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">5. Risks</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            You agree that by using the Website you assume the following risks:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>
              To the extent there is a price or market for Digital Assets, such markets and prices are extremely volatile, and variations in the price of Digital Assets could materially and adversely affect the value of any Digital Assets you own and there is no guarantee that your Digital Assets will have or retain any value;
            </li>
            <li>
              The commercial or market value of the Digital Assets that you acquire may materially diminish in value as a result of a variety of things such as negative publicity, blockchain malfunction and more;
            </li>
            <li>
              There are risks associated with using Digital Assets including, but not limited to, the risk of hardware, software and Internet connections and/or failures, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your digital “wallet” or elsewhere, and We will not be responsible for any of these, however caused;
            </li>
            <li>
              Upgrades to the Linkage protocol, a hard fork or other change, failure or cessation of the protocol or its supporting blockchain, or a change in how transactions are confirmed in the protocol may have unintended, adverse effects on all blockchains using such technologies;
            </li>
            <li>
              We do not make any promises or guarantees related to any other third parties providing to you any services for you in relation to Linkage protocol, including but not limited to the continued availability of either and/or the protection and/or storage of your Digital Assets or any data you provide to those parties;
            </li>
            <li>
              The risk of losing access to your Digital Assets due to loss of private key(s), custodial error or purchaser error;
            </li>
            <li>
              The risk of hacking, security weaknesses, fraud, counterfeiting, cyber-attacks and other technological difficulties;
            </li>
            <li>
            the risk of changes to the regulatory regime governing blockchain technologies, cryptocurrencies, and tokens and new regulations, unfavourable regulatory intervention in one or more jurisdictions or policies any of which may materially adversely affect the use and value of Digital Assets;
            </li>
            <li>
              The risks related to taxation;
            </li>
            <li>
              Digital Assets are not legal tender and are not backed by any government.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            In addition to assuming all of the above risks, you acknowledge that you have obtained sufficient information to make an informed decision to buy, sell, transfer, hold, display and conduct any other actions with Digital Assets. You understand and agree that you are solely responsible for determining the nature, potential value, suitability and appropriateness of these risks for yourself.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We cannot and do not represent or warrant that any Digital Assets, or its supporting systems or technology, is reliable, current or error-free, meets your requirements, or that defects in the Digital Assets, their supporting systems or technology, will be corrected.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">6. Cancellations and refunds</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The smart-contract prepared by the Company is digital content which is delivered via non-physical medium for a fee. The delivery of the code begins with your express consent and acknowledgement of your awareness of the loss of the right of withdrawal.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            An order placed on the blockchain cannot be cancelled or recalled. You understand and agree that due to the nature of blockchain technology and the service, the Company cannot block any transaction. The Company does not act as an intermediary, custodian or executer of any transaction made using the service. Therefore, you agree that the Company has no authority to cancel or refund any transactions made using its services.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Nothing in this Agreement precludes you to rely, upon non-compliance of the digital content or digital service with the terms and conditions of the contract, on the legal remedies provided by law. If the Company has breached the obligation, the consumer may use the following legal remedies: demand the fulfilment of the obligation; to refuse to fulfil their owed obligation; demand compensation for damages; withdraw from the contract or cancel the contract; lower the price; in case of delay in the fulfilment of a financial obligation, demand a late payment interest.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">7. Changes to these General Terms of Use</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company reserves the right, at its sole discretion, to amend these General Terms of Use at any time and will update these General Terms of Use in the event of any such amendments. By continuing to access or use the Service, you confirm your acceptance of the revised General Terms of Use and all of the terms incorporated therein by reference. We encourage you to review the Terms frequently to ensure that you understand the terms and conditions that apply when you access or use the Service. If you do not agree to the revised General Terms of Use, you may not access or use the Service.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We may make all other amendments to the Agreement by posting the revised Terms (Agreement) on the Platform, indicating when the revised Agreement becomes effective. Although we will endeavour to provide you with advance notice where possible, where lawful we may indicate that the revised Agreement shall be effective immediately and if you do not agree with any such modification, you should close your account and cease using the Platform.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">8. Fees</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Be aware that any transactions using the blockchain are inherently subject to transaction fees. Transaction fees on certain transactions might exceed the value of the transaction. You can learn more about Cardano Fee structure here: <a href="https://docs.cardano.org/explore-cardano/fee-structure/" className="text-blue-600 dark:text-blue-400">https://docs.cardano.org/explore-cardano/fee-structure/</a>
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The specific fee for the license, along with payment terms, will be provided at the time of licensing. The fee must be paid in full before the user can access and use the Smart-Contract.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">9. Data protection</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company shall ensure the protection of your data, including your personal data, and the use of the data in line with the procedure prescribed by the terms of the Privacy Policy available on the Website.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">10. Liability</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This clause sets out the entire financial liability for any acts, representations, misrepresentations, omissions or tortious acts of the Company, its employees, agents and subcontractors with respect to and arising from this Agreement.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Services of the Company are provided on an “as is” and “as available” basis without warranties of any kind. The Company disclaims all warranties, including warranties of non-infringement, marketability and fitness for a particular purpose. The Company shall not be liable for any pecuniary damages, loss of profits, cost of substitute goods or Services, loss of data, loss of goodwill, work stoppage, computer and/or device or technology failure or malfunction, or for any form of direct or indirect damages, legal action whatsoever related to any digital asset of any kind, any technology used by the Company or related parties, including applicable blockchain(s). You accept the inherent security risks of providing information and dealing online over the internet and agree that we have no liability or responsibility for any breach of security unless it is due to our gross negligence. If applicable law does not allow all or any part of the above limitation of liability to apply to you, the limitations will apply to you only to the extent permitted by applicable law.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Without prejudice to the other provisions of this Agreement, you take the sole responsibility to pay any and all taxes related to your usage of the Smart-Contract. We shall not be liable for any failure to properly declare and pay your tax liabilities.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            You agree that this limitation of liability is applicable to any event, to the maximum extent permitted by applicable law. Without limiting the effect of the above, in no case shall the liability of the Company exceed the value of the fees paid by you to us.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">11. Warranties and representations</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company warrants, represents and undertakes that it shall provide the Services with reasonable care and skill.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company makes no representation or warranty that the services are applicable or appropriate for use by customers in all jurisdictions and it is your responsibility to ensure compliance with the laws of any relevant jurisdiction of your residence.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            All warranties, representations, conditions, and all other terms of any kind whatsoever implied by statute or common law are excluded from the Agreement to the fullest extent permitted by applicable law.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">12. Term</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This Agreement will continue unless either party notifies the other of termination, in a format that can be reproduced in writing.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">13. Termination</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The Company may: (a) refuse to complete or initiate performance of the Services you have authorized, (b) suspend, restrict, or terminate your access to any or all of the Services, (c) revoke your right to use the Smart-Contract, and/or (d) deactivate or cancel your account with immediate effect for any reason.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We may at any time and with immediate effect terminate the Agreement if:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>We are required so by a valid subpoena, court order, or binding order of a governmental authority;</li>
            <li>You have failed to comply with any applicable laws, directives, rules or regulations;</li>
            <li>You have materially breached any other obligation of the Agreement and failed to remedy such breach within a reasonable time granted by us;</li>
            <li>You have committed fraud in your use of the Services or otherwise used the Services with ill effect;</li>
            <li>This opportunity is set forth in any other provision of this Agreement.</li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            In any case of termination (with or without cause) you must refrain immediately from using any service of the Company. The termination does not limit your access to the Linkage protocol through other means.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">14. Severability</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            If any provision of this agreement is deemed unenforceable by applicable law, court practice or custom, the remainder of this agreement shall remain in force to the fullest possible extent.
          </p>
          
          <h2 className="text-xl md:text-2xl font-semibold mb-2">15. Governing law and Legal disputes</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Any matters arising from this Agreement shall be governed by and interpreted in accordance with the substantive laws of Estonia.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This Agreement, as amended from time to time, constitutes the full and entire understanding and agreement between you and us regarding the subjects hereof and supersedes all prior agreements, arrangements, representations or promises, whether oral or written, as to its subject matter.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            If a dispute arises between you and the Company, you are strongly encouraged to first contact us directly to seek a resolution. For any complaints related to the service you are encouraged to contact us by E-Mail at <a href="mailto:contact@linkage.finance" className="text-blue-600 dark:text-blue-400">contact@linkage.finance</a>. We do our best to reply to your complaints within 15 days of receiving them.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            In case of failure to settle any dispute through negotiations, any disputes which may arise out of or in connection with this Agreement shall be settled by the Harju County Court. None of the provisions of the Agreement shall limit the rights of the consumer under the applicable law. In the event of the occurrence of a provision of this nature, the such provisions shall prevail.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            All private customers are entitled to address a competent supervisory authority, which is the Consumer Protection and Technical Regulatory Authority at Endla 10A, 10122 Tallinn, e-mail: <a href="mailto:info@ttja.ee" className="text-blue-600 dark:text-blue-400">info@ttja.ee</a>.
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            In order to resolve disputes, you, as a private customer, may address the Consumer Disputes Committee. The Consumer Disputes Committee is authorized to resolve disputes arising from contracts entered into between purchasers and sellers with claim amount over 30 euros that the parties have failed to resolve by way of negotiations. Further information on the resolution of complaints is available at https://komisjon.ee/et/avalduse-esitamine (EST).
          </p>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            If you act as a legal person or a sole proprietor, the provisions relating to special consumer rights shall not be applicable.
          </p>
        </div>
      </section>
    </Layout>
  );
};
