import React, { useMemo, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";

import { CreateFundFormType } from "@/pages/create-fund/types";

import { TokensTable } from "./tokens-table/tokens-table";
import { Search } from "@ui/search/search";
import { SelectedTokens } from "@/pages/create-fund/components/select-tokens/selected-tokens/selected-tokens";
import { fetchTokenList } from "@/api/create-fund";

export type Token = {
  policy_id: string;
  token_decimal_places: number;
  token_image: string;
  token_name: string;
  token_symbol: string;
  distribution?: number;
};

export const SelectTokens = () => {
  const { values } = useFormikContext<CreateFundFormType>();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const isMobile = width < 992;

  const { data, isLoading: tokensLoading } = useQuery<Token[]>({
    queryKey: ["create-fund-tokens"],
    queryFn: fetchTokenList,
  });

  const selectList = useMemo(() => {
    if (data) {
      if (!!values.selectedTokenIds.length) {
        const afterSelected = data.filter(
          ({ policy_id }) =>
            !values.selectedTokenIds.some((tokenId) => tokenId === policy_id),
        );

        return afterSelected.filter(({ token_symbol }) =>
          token_symbol.toLowerCase().includes(searchValue.toLowerCase()),
        );
      }

      return data.filter(({ token_symbol }) =>
        token_symbol.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }

    return [];
  }, [data, values.selectedTokenIds, searchValue]);

  return (
    <div
      className={twMerge(
        "h-full w-full md:w-1/2 rounded-lg bg-white p-7 pb-1 dark:bg-gray-900 border dark:border-gray-800",
        isMobile && "min-h-[737px]",
      )}
    >
      <div className="flex justify-between items-center w-full mb-8">
        <div className="flex items-center gap-2">
          <h3 className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white">
            {t("create-fund-tokens.title")}
          </h3>
          <span className="rounded-full bg-violet-200 dark:bg-gray-400 h-6 w-6 flex items-center justify-center font-sf-pro text-violet-600 dark:text-gray-700">
            {values.selectedTokenIds.length}
          </span>
        </div>
        {/*<div className="hidden md:flex items-center gap-2">*/}
        {/*  <Switcher disabled logic={handleSwitcherClick} className="w-44 h-7" />*/}
        {/*  <span className="font-sf-pro text-gray-700 dark:text-white text-lg">*/}
        {/*    {t("verified")}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
      {values.selectedTokenIds.length ? <SelectedTokens /> : null}
      <div className="flex items-center justify-between mb-6 relative">
        <Search
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {/*<div className="flex md:hidden items-center gap-2">*/}
        {/*  <Switcher disabled logic={handleSwitcherClick} className="w-44 h-7" />*/}
        {/*  <span className="font-sf-pro text-gray-700 dark:text-white text-lg">*/}
        {/*    {t("verified")}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
      <TokensTable tokenList={selectList} isLoading={tokensLoading} />
    </div>
  );
};
