import React, { MouseEvent } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";

import { ActualFundType } from "@constants/funds-data";

interface Props {
  dataRow: ActualFundType;
}

export const FundInteractionsFundsTableRow = ({ dataRow }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate("/interactions-info/" + dataRow.fund_id);
  };

  const handleDetailClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate("/details/" + dataRow.fund_id);
  };

  const firstImage = dataRow.tokens[0].token_image;
  const secondImage = dataRow.tokens[1].token_image;

  return (
    <>
      <tr
        className="relative transition-all duration-200 md:hover:bg-violet-50 md:hover:dark:bg-gray-800 cursor-pointer"
        onClick={handleRowClick}
      >
        <td className="bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-none sticky -left-0.5 z-10 pl-3 md:p-0 md:static px-14">
          <div className="relative" style={{ width: "40px", height: "40px" }}>
            <div className="absolute top-0 left-0 z-10 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
              <img src={firstImage} alt="" />
            </div>
            <div className="absolute bottom-0 right-0 z-20 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
              <img src={secondImage} alt="" />
            </div>
            <div className="absolute bottom-0 left-0 z-30 w-6 h-6 rounded-full bg-violet-700 border border-white flex items-center justify-center font-bold font-sf-pro text-white text-xs	">
              {dataRow.tokens.length}
            </div>
          </div>
        </td>
        <td className="py-4">
          <div className="flex items-center gap-3">
            <span className="text-gray-800 text-m md:text-xl font-medium dark:text-white whitespace-nowrap	">
              {dataRow.fund_name}
            </span>
            {/*{dataRow.verified ? (*/}
            {/*  <div className="flex items-center justify-center">*/}
            {/*    <Icon*/}
            {/*      icon={ICON_COLLECTION.circleCheck}*/}
            {/*      className="h-5 w-5"*/}
            {/*      iconColor="#7C3AED"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*) : null}*/}
            {dataRow.featured ? (
              <div className="flex items-center justify-center px-2 rounded-full text-violet-600 bg-violet-200 dark:bg-gray-600 dark:text-gray-100">
                {t("funds-list-table.table.featured")}
              </div>
            ) : null}
          </div>
        </td>
        <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
          {dataRow.fund_price.toLocaleString("en")}
        </td>
        <td
          className={clsx("font-medium", {
            "text-green-500": dataRow.price_change24h > 0,
            "text-red-500": dataRow.price_change24h < 0,
          })}
        >
          <div className="flex items-center justify-end text-m md:text-l">
            {Number(dataRow.price_change24h.toFixed(2)).toLocaleString("en") +
              "%"}
            {dataRow.price_change24h > 0 ? (
              <Icon
                icon={ICON_COLLECTION.arrowUp}
                className="h-5 w-5"
                iconColor="#22C55E"
              />
            ) : (
              <Icon
                icon={ICON_COLLECTION.arrowDown}
                className="h-5 w-5"
                iconColor="#EF4444"
              />
            )}
          </div>
        </td>
        <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
          {Number(dataRow.total_locked.toFixed(2)).toLocaleString("en")}
        </td>
        <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
          {Number(dataRow.inflow_7d.toFixed(2)).toLocaleString("en")}
        </td>
        <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
          {Number(dataRow.outflow_7d.toFixed(2)).toLocaleString("en")}
        </td>
        <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white pr-12px md:rounded-r-md">
          {dataRow.fund_fee + "%"}
        </td>
        <td className="pl-3">
          <button
            onClick={handleDetailClick}
            type="button"
            name="fund-details-link"
            className="group px-6 py-2 rounded-full bg-violet-200 hover:bg-violet-500 dark:bg-gray-800 hover:dark:bg-gray-700 transition-colors duration-300"
          >
            <span className="text-s text-violet-600 group-hover:text-white dark:text-white transition-colors duration-300">
              Details
            </span>
          </button>
        </td>
      </tr>
    </>
  );
};
