import { createContext } from "react";
import { Filters } from "@/pages/funds-list/components/funds-main/funds-main";

interface OverviewFilters {
  filters: Filters;
  maxLockedRange: number;
  minPriceChange: number;
  updateFilterField(key: keyof Filters, value: Filters[keyof Filters]): void;
  handleSortUpdate(sortBy: string): void;
}

export const OverviewFiltersContext = createContext(
  null as unknown as OverviewFilters,
);
