import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FundInteractionsFundsTableRow } from "./fund-interections-funds-table-row";
import { SortButton } from "@/pages/funds-list/components/funds-main/funds-table/sort-button";
import {
  SORT_ORDER_OPTIONS,
  useTableSort,
} from "@/hooks/use-table-sort/use-table-sort";

import { ActualFundType } from "@constants/funds-data";

interface Props {
  data: ActualFundType[];
}

const KEYS = {
  name: "fund_name",
  price: "fund_price",
  ["change-daily"]: "price_change24h",
  ["total-locked"]: "total_locked",
  inflow: "inflow_7d",
  outflow: "outflow_7d",
  ["fund-fee"]: "fund_fee",
};

export const FundInteractionsFundsTable = ({ data }: Props) => {
  const { t } = useTranslation();

  const { order, sortBy, sortOptions, handleSort } = useTableSort();

  const sortedList =
    sortBy !== "name"
      ? [...data].sort((fund_1, fund_2) =>
          order === SORT_ORDER_OPTIONS.ASC
            ? fund_1[KEYS[sortBy]] - fund_2[KEYS[sortBy]]
            : fund_2[KEYS[sortBy]] - fund_1[KEYS[sortBy]],
        )
      : [...data].sort((fund_1, fund_2) =>
          order === SORT_ORDER_OPTIONS.ASC
            ? fund_1.fund_name.localeCompare(fund_2.fund_name)
            : fund_2.fund_name.localeCompare(fund_1.fund_name),
        );

  return (
    <table className="w-full">
      <thead>
        <tr className="relative">
          <th className="bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent h-full sticky -left-0.5 z-10 md:static font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 text-left font-semibold py-2 px-1 md:px-3">
            <SortButton
              name="sort-by-name"
              onClick={() => handleSort(sortOptions.name)}
              order={order}
              label={t("funds-interactions.table.titles.name")}
              className="w-fit"
              showArrow={sortBy === sortOptions.name}
            />
          </th>
          <th></th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-price"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.price)}
              order={order}
              label={t("funds-interactions.table.titles.price")}
              showArrow={sortBy === sortOptions.price}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-daily-change"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.changeDaily)}
              order={order}
              label={t("funds-interactions.table.titles.change_24")}
              showArrow={sortBy === sortOptions.changeDaily}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-total-locked"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.totalLocked)}
              order={order}
              label={t("funds-interactions.table.titles.total")}
              showArrow={sortBy === sortOptions.totalLocked}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-inflow"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.inflow)}
              order={order}
              label={t("funds-interactions.table.titles.inflow")}
              showArrow={sortBy === sortOptions.inflow}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-outflow"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.outflow)}
              order={order}
              label={t("funds-interactions.table.titles.outflow")}
              showArrow={sortBy === sortOptions.outflow}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-12px text-right">
            <SortButton
              name="sort-by-fund-fee"
              className="ml-auto w-fit"
              onClick={() => handleSort(sortOptions.fundFee)}
              order={order}
              label={t("funds-interactions.table.titles.fee")}
              showArrow={sortBy === sortOptions.fundFee}
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedList.map((dataRow, index) => (
          <FundInteractionsFundsTableRow key={index} dataRow={dataRow} />
        ))}
      </tbody>
    </table>
  );
};
