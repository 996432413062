import React from "react";
import styles from "./create-fund-form.module.scss";
import { Checkbox } from "@ui/checkbox/checkbox";
import { useTranslation } from "react-i18next";

export const CreateFundFormFilled = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full md:w-1/2 rounded-lg bg-white p-7 dark:bg-gray-900 border dark:border-gray-800">
      <h3 className="font-sf-pro text-2xl font-semibold text-gray-800 mb-8 dark:text-white">
        {t("create-fund-form.title")}
      </h3>
      <form className={styles["form"]}>
        <div className="flex flex-col gap-1 w-full">
          <label
            htmlFor="fundName"
            className="text-m md:text-l text-gray-800 font-medium dark:text-white"
          >
            {t("create-fund-form.input-title.name")}
          </label>
          <input
            type="text"
            name="fundName"
            placeholder={t("create-fund-form.input-title.name")}
            className="w-full py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            htmlFor="description"
            className="text-m md:text-l text-gray-800 font-medium dark:text-white"
          >
            {t("create-fund-form.input-title.description")}
          </label>
          <textarea
            name="description"
            placeholder={t("create-fund-form.input-title.description")}
            rows={3}
            className="w-full font-sf-pro py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500"
          />
        </div>
        <div className="flex flex-col gap-2 items-start w-full">
          <label
            htmlFor="creatorName"
            className="text-m md:text-l text-gray-800 font-medium dark:text-white"
          >
            {t("create-fund-form.input-title.creator")}
          </label>
          <input
            type="text"
            name="creatorName"
            placeholder={t("create-fund-form.input-title.creator")}
            className="w-full py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500"
          />
          <Checkbox
            name="hide"
            label={t("create-fund-form.input-title.hide")}
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between items-center">
            <label
              htmlFor="ada"
              className="text-m md:text-l text-gray-800 font-medium dark:text-white"
            >
              {t("create-fund-form.input-title.ada")}
            </label>
            <button className="hidden md:flex gap-2 items-center h-4">
              <span className="text-base font-sf-pro text-gray-800 dark:text-gray-400">
                {t("create-fund-form.input-title.balanceFilled")}
                {"123,549.912"}
              </span>
            </button>
          </div>
          <div className={styles["input-with-addon"]}>
            <input
              type="number"
              name="ada"
              placeholder="--"
              className="w-full py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-white"
            />
            <span className={styles["input-addon"]}>ADA</span>
          </div>
        </div>
        <Checkbox
          name="comission"
          label={t("create-fund-form.input-title.comission")}
        />
        <div className="flex w-full justify-between gap-6">
          <div className="flex flex-col gap-1 w-1/2">
            <label
              htmlFor="address"
              className="text-m md:text-l text-gray-800 font-medium dark:text-white"
            >
              {t("create-fund-form.input-title.address")}
            </label>
            <input
              type="text"
              name="address"
              placeholder={t("create-fund-form.input-title.address")}
              className="w-full py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500"
            />
          </div>
          <div className="flex flex-col gap-1 w-1/2">
            <label
              htmlFor="fee"
              className="text-m md:text-l text-gray-800 font-medium dark:text-white"
            >
              {t("create-fund-form.input-title.fee")}
            </label>
            <div className={styles["input-with-addon"]}>
              <input
                type="number"
                name="fee"
                placeholder={t("create-fund-form.input-title.fee")}
                className="w-full py-3 px-4 border border-gray-200 rounded dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500"
              />
              <span className={styles["input-addon"]}>%</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
