import React, { useEffect, useState } from "react";
import { Switcher } from "@ui/switcher/switcher";
import { Link, useLocation } from "react-router-dom";
import { HeaderConnect, HeaderMenu } from "@constants/header";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ConnectWallet } from "../connect-wallet/connect-wallet";
import { ROUTES } from "@constants/routes/routes";
import { SelectLang } from "@ui/select-lang/select-lang";
import headerIconLight from "../../assets/icons/header/header-logo-light.png";
import headerIconDark from "../../assets/icons/header/header-logo-dark.png";
import headerMobileIconLight from "../../assets/icons/header/mobile-logo-light.png";
import headerMobileIconDark from "../../assets/icons/header/mobile-logo-dark.png";
import styles from "./header.module.scss";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "../theme-context/theme-context";

export const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("theme", theme);

    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    toggleTheme();
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  return (
    <header
      className={clsx("header h-24 flex flex-row items-center justify-center", {
        [styles["header--open"]]: isMenuOpen,
      })}
    >
      <div className=" header__container h-full flex items-center justify-between w-screen mx-auto px-8 dark:border-b dark:bg-dark-bg dark:border-dark-border">
        {theme === "light" ? (
          <a href={ROUTES.home.path} className="w-56 xl:w-fit">
            <img src={headerIconLight} alt="" className="hidden md:block" />
            <img
              src={headerMobileIconLight}
              alt=""
              className="block md:hidden"
            />
          </a>
        ) : (
          <a href={ROUTES.home.path} className="w-56 xl:w-fit">
            <img src={headerIconDark} alt="" className="hidden md:block" />
            <img
              src={headerMobileIconDark}
              alt=""
              className="block md:hidden"
            />
          </a>
        )}
        <div
          className={clsx(styles["burger-button"])}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span className="bg-black dark:bg-white"></span>
          <span className="bg-black dark:bg-white"></span>
          <span className="bg-black dark:bg-white"></span>
        </div>
        <div
          className={styles["header-blur"]}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        ></div>
        <nav
          className={clsx(
            styles["header-menu"],
            "flex items-center justify-between h-full md:bg-white dark:bg-dark-bg overflow-y-auto md:overflow-y-visible",
          )}
        >
          <ul
            className={clsx(
              styles["header-menu__list"],
              "flex gap-x-1 xl:gap-x-6 text-xl h-full",
            )}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {theme === "light" ? (
              <a href={ROUTES.home.path} className={styles["mobile-logo"]}>
                <img src={headerIconLight} alt="" />
              </a>
            ) : (
              <a href={ROUTES.home.path} className={styles["mobile-logo"]}>
                <img src={headerIconDark} alt="" />
              </a>
            )}
            {HeaderMenu.map((item) => (
              <li
                key={item.title}
                className={clsx(
                  styles["header-menu__item"],
                  "h-full px-2 py-3 md:py-0 flex justify-center items-center cursor-pointer border-b-2 border-transparent md:hover:bg-violet_light md:hover:dark:bg-violet_light md:hover:text-violet_light transition-all duration-300 hover:dark:bg-gray-800",
                  {
                    "font-sf-pro font-bold md:border-violet_light text-black md:text-violet_light  dark:text-white md:hover:text-white":
                      location.pathname === item.path,
                    "font-sf-pro px-3 text-black md:text-gray400 md:hover:text-white dark:text-gray-500":
                      location.pathname !== item.path,
                  },
                )}
              >
                <Link
                  to={item.path}
                  className="h-full flex items-center justify-center"
                >
                  {t(`header.menu.${item.title}`)}
                </Link>
              </li>
            ))}
          </ul>
          <div
            className={clsx(
              styles["side-buttons"],
              "flex items-center justify-center gap-1 xl:gap-3",
            )}
          >
            <div
              className={`${styles["func-buttons"]} flex items-center gap-1 xl:gap-6`}
            >
              <SelectLang />
              <Switcher
                logic={() => handleThemeSwitch()}
                theme={theme}
                className="w-69 h-9"
              />
            </div>
            <ConnectWallet
              text={HeaderConnect.title}
              icon={ICON_COLLECTION.walletIcon}
              className={`${styles["connect-btn"]} py-8px px-32px`}
            />
          </div>
        </nav>
      </div>
    </header>
  );
};
