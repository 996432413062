import React from "react";
import { Icon } from "../icons/icon";
import { ICON_COLLECTION } from "../icons/icon-list";
import { useTranslation } from "react-i18next";
import { useTheme } from "@/components/theme-context/theme-context";

export const SwipeToSeeMore = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <div className="w-full md:hidden flex items-center justify-center py-12px gap-8px">
      <Icon
        icon={ICON_COLLECTION.swipeIcon}
        iconColor={theme === "dark" ? "#D1D5DB" : "#9CA3AF"}
        iconSize="24px"
      />
      <span className="font-sf-pro text-m font-medium text-gray-400 dark:text-gray-300">
        {t("swipe")}
      </span>
    </div>
  );
};
