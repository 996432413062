import React from "react";

interface CardProps {}

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
}) => {
  return (
    <div className="w-full bg-white max-h-80 min-w-fit rounded-xl md:py-6 md:px-3 p-3.5 dark:bg-dark-gray900">
      {children}
    </div>
  );
};
