import React from "react";
import { useWindowSize } from "usehooks-ts";

import { Layout } from "@/layout/layout";

export const Privacy = () => {
  const { width } = useWindowSize();

  const isMobile = width < 992;

  return (
    <Layout>
      <section
        className="w-full dark:bg-dark-bg py-3 md:py-8 px-4 md:px-16"
        style={{ minHeight: isMobile ? "1700px" : "100vh" }}
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 md:p-12 max-w-3xl mx-auto">
          <h1 className="text-2xl md:text-3xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            This Privacy Policy explains how we collect, use, and protect your personal data in accordance with the General Data Protection Regulation (GDPR).
          </p>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Data Controller and Data Protection Officer</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            The data controller responsible for your personal data is Linkage Dev OÜ, located at  Harju maakond, Tallinn, Kesklinna linnaosa, Tornimäe tn 7-26, 10145, Estonia. Our Data Protection Officer (DPO) can be contacted at contact@linkage.finance.
          </p>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Personal Data We Collect</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We collect the following personal data about you:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>Identification data (e.g., wallet address).</li>
            <li>Technical data (e.g., IP address, browser type, and version).</li>
            <li>Usage data (e.g., information on how you use our website, products, and services).</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Purpose of Processing Personal Data</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We use your personal data for the following purposes:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>To provide and manage our services.</li>
            <li>To personalize your experience on our website.</li>
            <li>To improve our products and services.</li>
            <li>To comply with legal obligations.</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Legal Basis for Processing</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We process your personal data based on the following legal grounds:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>Your consent.</li>
            <li>The performance of a contract with you.</li>
            <li>Compliance with legal obligations.</li>
            <li>Our legitimate interests (e.g., to improve our services).</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Your Rights</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Under the GDPR, you have the following rights regarding your personal data:
          </p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>The right to access your personal data.</li>
            <li>The right to rectify your personal data.</li>
            <li>The right to erase your personal data.</li>
            <li>The right to restrict the processing of your personal data.</li>
            <li>The right to data portability.</li>
            <li>The right to object to the processing of your personal data.</li>
            <li>The right to withdraw consent at any time.</li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            If you wish to exercise any of these rights, please contact our DPO at contact@linkage.finance.
          </p>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Data Security</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We implement appropriate technical and organizational measures to ensure the security of your personal data.
          </p>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            If you have any questions about this Privacy Policy, please contact us.
          </p>
        </div>
      </section>
    </Layout>
  );
};
