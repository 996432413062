import React from "react";
import { twMerge } from "tailwind-merge";

import { Separator } from "@/pages/confirmation/components/separator";

export const ListItem = ({
  label,
  value,
  valueNoWrap,
}: {
  label: string;
  value: string;
  valueNoWrap?: boolean;
}) => (
  <div className="flex items-end gap-3">
    <p className="text-m md:text-l font-medium text-gray-800 dark:text-white whitespace-nowrap">
      {label}
    </p>
    <Separator />
    <p
      className={twMerge(
        "text-m md:text-l font-medium text-gray-800 dark:text-white text-right",
        valueNoWrap && "whitespace-nowrap",
      )}
    >
      {value}
    </p>
  </div>
);
