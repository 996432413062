import React, { ChangeEvent } from "react";
import { useFormikContext } from "formik";
import { twMerge } from "tailwind-merge";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";

import { CreateFundFormType } from "@/pages/create-fund/types";
import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";

import styles from "../../create-fund-form/create-fund-form.module.scss";

interface SelectedTokensRowProps {
  token: Token;
}

const isDistributionChanged = (arr: Token[]) => {
  if (arr.length === 0) return false;

  const initDistribution = arr[0]?.distribution;
  return arr.every((token) => token.distribution === initDistribution);
};

export const SelectedTokensRow: React.FC<SelectedTokensRowProps> = ({
  token,
}) => {
  const { values, setFieldValue } = useFormikContext<CreateFundFormType>();

  const handleDelete = () => {
    const totalPercentage = 100;
    const filteredIds = values.selectedTokenIds.filter(
      (id) => id !== token.policy_id,
    );
    const distribution = totalPercentage / filteredIds.length;

    let newTokens = Object.fromEntries(
      filteredIds.map((id) => [id, values.selectedTokens[id]]),
    );

    const shouldRecalculateDistribution = isDistributionChanged(
      filteredIds.map((id) => values.selectedTokens[id]),
    );

    if (shouldRecalculateDistribution) {
      newTokens = Object.fromEntries(
        filteredIds.map((id) => [
          id,
          { ...values.selectedTokens[id], distribution },
        ]),
      );
    }

    setFieldValue("selectedTokenIds", filteredIds);
    setFieldValue("selectedTokens", newTokens);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `selectedTokens.${token.policy_id}.distribution`,
      event.target.value,
    );
  };

  const imageLink = token.token_image?.startsWith("ipfs")
    ? token.token_image.replace("ipfs://", "https://ipfs.io/ipfs/")
    : token.token_image;

  return (
    <div className="flex items-center justify-between w-full py-3 border-b border-gray-100 dark:border-gray-800">
      <div className="flex items-center gap-2">
        <img src={imageLink} alt="" className="h-12" />
        <h6 className="font-sf-pro text-lg md:text-xl text-gray-800 dark:text-white font-semibold">
          {token.token_symbol}
        </h6>
        {/*{token.verified ? (*/}
        {/*  <Icon icon={ICON_COLLECTION.circleCheck} iconColor="#7C3AED" />*/}
        {/*) : null}*/}
      </div>
      <div className="flex items-center gap-3">
        <div className={styles["input-with-addon"]}>
          <input
            type="number"
            name="token-distribution"
            min={1}
            max={100}
            value={token.distribution}
            onChange={handleOnChange}
            className="px-3 pr-8 py-2 border border-gray-200 dark:border-gray-800 dark:bg-gray-800 rounded-lg font-sf-pro text-gray-800 dark:text-white"
          />
          <span className={twMerge(styles["input-addon"], "dark:text-white")}>
            %
          </span>
        </div>
        <button
          onClick={handleDelete}
          className="flex items-center justify-center"
        >
          <Icon
            icon={ICON_COLLECTION.xCircle}
            className="height-24"
            iconColor="#6B7280"
            hoverColor="#EF4444"
          />
        </button>
      </div>
    </div>
  );
};
