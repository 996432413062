import { number, object } from "yup";

export const getInteractionsValidationSchema = (walletBalance: number) => {
  return object().shape({
    ada: number()
      .required("You should enter a value.")
      .min(1, "Should be a positive number")
      .max(walletBalance, "Input value can't be higher than your balance"),
  });
};
