import React, { useState } from "react";
import { useWindowSize } from "usehooks-ts";

import { useTheme } from "@/components/theme-context/theme-context";
import { ActualTokenType } from "@constants/funds-data";

interface Props {
  tokens: ActualTokenType[];
}

export const TokensList = ({ tokens }: Props) => {
  const { theme } = useTheme();
  const { width } = useWindowSize();

  const [showAllTokens, setShowAllTokens] = useState(false);
  const isMobile = width < 992;

  const visibleTokens = !isMobile
    ? tokens.slice(0, 7)
    : showAllTokens
    ? tokens
    : tokens.slice(0, 7);
  const dropdownTokens = tokens.slice(7);

  return (
    <div className="w-full flex items-center flex-wrap gap-3">
      {visibleTokens.map((token, index) => (
        <div key={index} className="flex flex-col items-center gap-1 px-2">
          <div className="w-fit rounded-full overflow-hidden">
            <img
              src={token.token_image}
              alt=""
              style={{
                minWidth: "32px",
                minHeight: "32px",
                maxWidth: "32px",
                maxHeight: "32px",
              }}
            />
          </div>
          <span className="text-gray-800 dark:text-white text-s font-medium">
            {token.token_symbol}
          </span>
        </div>
      ))}
      {!isMobile ? (
        <div className="flex items-center justify-center relative">
          {tokens.length > 7 && (
            <button
              onClick={() => setShowAllTokens(!showAllTokens)}
              className="rounded-full px-8px h-6 flex items-center justify-center bg-violet-200 dark:bg-gray-500 border-2 border-transparent hover:border-violet-600 hover:dark:border-gray-100 transition-all duration-300"
            >
              <span className="font-m font-medium text-violet-500 dark:text-white">
                +{tokens.length - 7}
              </span>
            </button>
          )}
          {showAllTokens ? (
            <div
              className="absolute z-50 -bottom-2 right-4 p-12px translate-y-full translate-x-3/4 rounded-lg bg-white dark:bg-gray-950 flex flex-col justify-center gap-8px"
              style={{
                boxShadow:
                  theme === "dark"
                    ? "0px 10px 20px 10px rgba(0, 0, 0, 0.10)"
                    : "0px 10px 20px 10px rgba(75, 85, 99, 0.10)",
              }}
            >
              {dropdownTokens.map((token, index) => (
                <div key={index} className="flex items-center gap-12px">
                  <img
                    src={token.token_image}
                    alt=""
                    style={{
                      minWidth: "32px",
                      minHeight: "32px",
                      maxWidth: "32px",
                      maxHeight: "32px",
                    }}
                  />
                  <span className="text-gray-800 dark:text-white text-s font-medium">
                    {token.token_symbol}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        tokens.length > 7 && (
          <button
            onClick={() => setShowAllTokens(!showAllTokens)}
            className="rounded-full px-8px h-6 flex items-center justify-center bg-violet-200 dark:bg-gray-500 border-2 border-transparent"
          >
            <span className="font-m font-medium text-violet-500 dark:text-white">
              {showAllTokens ? "-" : "+"}
              {tokens.length - 7}
            </span>
          </button>
        )
      )}
    </div>
  );
};
