import React from "react";
import { useWindowSize } from "usehooks-ts";

import { Layout } from "@/layout/layout";
import { FundDetailsMain } from "./components/fund-details-main/fund-details-main";

export const FundDetails = () => {
  const { width } = useWindowSize();

  const isMobile = width < 992;

  return (
    <Layout>
      <section
        className="w-full dark:bg-dark-bg py-3 md:py-8 px-4 md:px-16"
        style={{ minHeight: isMobile ? "1700px" : "100vh" }}
      >
        <FundDetailsMain />
      </section>
    </Layout>
  );
};
