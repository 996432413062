import React from "react";
import { useTranslation } from "react-i18next";

export const PositionHistoryTableRow = ({ dataRow }) => {
  const { t } = useTranslation();
  return (
    <tr className="relative">
      <td className="py-4"></td>
      <td className="w-16 bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent sticky -left-0.5 z-10 pl-1 md:p-0 md:static px-14">
        <div className="relative" style={{ width: "40px", height: "40px" }}>
          <img
            src={dataRow.count.countIcon_1}
            alt=""
            className="absolute top-0 left-0 z-10 h-8 w-8"
          />
          <img
            src={dataRow.count.countIcon_2}
            alt=""
            className="absolute bottom-0 right-0 z-20 h-8 w-8"
          />
          <div className="absolute bottom-0 left-0 z-30 w-6 h-6 rounded-full bg-violet-700 border border-white flex items-center justify-center font-bold font-sf-pro text-white text-xs	">
            {dataRow.count.quantity}
          </div>
        </div>
      </td>
      <td>
        <div className="flex items-center gap-3">
          <span className="text-gray-800 text-m md:text-xl font-medium dark:text-white whitespace-nowrap	">
            {dataRow.title}
          </span>
          {/*{dataRow.verified ? (*/}
          {/*  <div className="flex items-center justify-center">*/}
          {/*    <Icon*/}
          {/*      icon={ICON_COLLECTION.circleCheck}*/}
          {/*      className="h-5 w-5"*/}
          {/*      iconColor="#7C3AED"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*) : null}*/}
          {dataRow.featured ? (
            <div className="text-m flex items-center justify-center px-2 rounded-full text-violet-600 bg-violet-200 dark:bg-gray-600 dark:text-gray-100">
              {t("funds-list-table.table.featured")}
            </div>
          ) : null}
        </div>
      </td>
      <td className="text-m pl-7 md:text-l text-gray-800 font-medium text-right dark:text-white">
        {dataRow.action}
      </td>
      <td
        className={
          "text-m md:text-l text-gray-800 font-medium text-right dark:text-white"
        }
      >
        {dataRow.quantity}
      </td>
      <td
        className={
          "text-m md:text-l pl-7 text-gray-800 font-medium text-right dark:text-white"
        }
      >
        {dataRow.date}
      </td>
      <td className="text-m pl-7 md:text-l text-gray-800 font-medium text-right dark:text-white">
        {dataRow.fee + "%"}
      </td>
    </tr>
  );
};
