import React, { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

import { FundDetailsNav } from "../fund-details-nav/fund-details-nav";
import { FundsDetailsInfo } from "../fund-details-info/fund-details-info";
import { FundsDetailsTable } from "../fund-details-table/fund-details-table";
import { fetchFundDetails } from "@/api/fund-details";

export const FundDetailsMain = () => {
  // todo: ig we will need a context provider with custom hooks.
  const ref = useRef<ChartJSOrUndefined<"pie", any, unknown>>(null);

  const { fund_id } = useParams();

  const { data } = useQuery({
    queryKey: ["fund-details", fund_id ?? ""],
    queryFn: fetchFundDetails,
  });

  const handleTableHover = (label: string) => {
    let labels = [] as string[];

    if (data) {
      labels = data.tokens.map((token) => token.token_symbol);
    }

    const chart = ref.current;
    if (chart) {
      const activeIndex = labels.findIndex((item) => item === label);
      if (!!label && activeIndex >= 0) {
        chart.tooltip?.setActiveElements(
          [{ datasetIndex: 0, index: activeIndex }],
          {
            x: 71,
            y: 71,
          },
        );
        chart.setActiveElements([{ datasetIndex: 0, index: activeIndex }]);
      } else {
        chart.setActiveElements([]);
        chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
      }

      chart.update();
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center gap-24px">
      <FundDetailsNav />
      <FundsDetailsInfo chartRef={ref} />
      <FundsDetailsTable handleTableHover={handleTableHover} />
    </div>
  );
};
