import React, { MouseEventHandler } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import {
  SORT_ORDER_OPTIONS,
  SortOrderValue,
} from "@/hooks/use-table-sort/use-table-sort";

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  name: HTMLButtonElement["name"];
  order: SortOrderValue | "";
  label: string;
  showArrow: boolean;
  className?: string;
  iconPosition?: "left" | "right";
}

/**
 * This component is made just for table headers.
 * @param
 * @param (iconPosition) - keep in mind that if you will you position - right -
 * you need to use pr-4 on your td's as well, to align column's
 * */
export const SortButton = ({
  showArrow,
  onClick,
  order,
  label,
  name,
  className,
  iconPosition = "left",
}: Props) => {
  return (
    <button
      type="button"
      className={twMerge(
        clsx(
          "w-full text-left flex items-center justify-start gap-1 relative",
          className,
          iconPosition === "left" && "pl-4",
          iconPosition === "right" && "pr-4",
        ),
      )}
      name={name}
      onClick={onClick}
    >
      {showArrow ? (
        <div
          className={clsx("w-fit max-h-3.5 transform duration-200 absolute", {
            "-rotate-180": order === SORT_ORDER_OPTIONS.ASC,
            "left-0": iconPosition === "left",
            "right-0": iconPosition === "right",
          })}
        >
          <Icon iconSize="14px" icon={ICON_COLLECTION.arrowDown} />
        </div>
      ) : null}
      {label}
    </button>
  );
};
