import React, { useContext } from "react";
import { CardanoSerializerContext } from "@/providers/cardano-serializer-provider/context";

export const useCardanoSerializedHandlers = () => {
  const context = useContext(CardanoSerializerContext);

  if (!context) {
    throw new Error(
      "useCardanoSerializedHandlers should be used within CardanoSerializerProvider",
    );
  }

  const { placeFundRequests, getPubKeyHash, cancelFundRequest } = context;
  return {
    placeFundRequests,
    getPubKeyHash,
    cancelFundRequest,
  };
};
