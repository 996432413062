import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";
import { formatImageLink } from "@/api/helpers/helper";

const tokenListQuery = "https://api.linkage.finance/tokens";
const options: RequestInit = {
  method: "GET",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
};
export const fetchTokenList = async (): Promise<Token[]> => {
  try {
    const res = await fetch(tokenListQuery, options);
    const list: Token[] = await res.json();

    return list
      .filter((token) => token.token_name !== "" || token.policy_id !== "")
      .map((token) => ({
        ...token,
        token_image: formatImageLink(token.token_image),
      }));
  } catch (e) {
    console.error("API FetchTokens ERROR::", e);
    return [];
  }
};
