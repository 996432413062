import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FundsDetailsTableRow } from "./fund-details-table-row";
import { Loader } from "@ui/loader/loader";
import { SwipeToSeeMore } from "@ui/swipe/swipe";
import { SortButton } from "@/pages/funds-list/components/funds-main/funds-table/sort-button";
import {
  SORT_ORDER_OPTIONS,
  useTableSort,
} from "@/hooks/use-table-sort/use-table-sort";
import { useQuery } from "@tanstack/react-query";
import { fetchFundDetails } from "@/api/fund-details";

interface Props {
  handleTableHover: (label: string) => void;
}

const KEYS = {
  name: "token_symbol",
  price: "token_price",
  cap: "token_marketcap",
  ["change-daily"]: "token_price_change24h",
  volume: "token_volume24h",
  share: "token_share",
};

export const FundsDetailsTable = ({ handleTableHover }: Props) => {
  const { t } = useTranslation();
  const { order, sortBy, handleSort, sortOptions } = useTableSort();
  const { fund_id } = useParams();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["fund-details", fund_id],
    queryFn: fetchFundDetails,
    enabled: !!fund_id,
  });

  if (!data) {
    return (
      <div className="w-full flex flex-col gap-32px pl-0 p-12px md:p-24px rounded-lg bg-white dark:bg-gray-900">
        <Loader />
      </div>
    );
  }

  const sortedList =
    sortBy === "name"
      ? [...data.tokens].sort((token_a, token_b) =>
          order === SORT_ORDER_OPTIONS.ASC
            ? token_a.token_symbol.localeCompare(token_b.token_symbol)
            : token_b.token_symbol.localeCompare(token_a.token_symbol),
        )
      : [...data.tokens].sort((token_a, token_b) =>
          order === SORT_ORDER_OPTIONS.ASC
            ? token_a[KEYS[sortBy]] - token_b[KEYS[sortBy]]
            : token_b[KEYS[sortBy]] - token_a[KEYS[sortBy]],
        );

  return (
    <>
      {isLoading || isFetching ? (
        <div className="w-full flex flex-col gap-32px pl-0 p-12px md:p-24px rounded-lg bg-white dark:bg-gray-900">
          <Loader />
        </div>
      ) : (
        <div className="w-full flex flex-col pl-0 p-12px md:p-24px rounded-lg bg-white dark:bg-gray-900">
          <span className="mb-32px pl-12px md:p-0 text-2xl font-sf-pro font-semibold text-gray-800 dark:text-white">
            {t("fund-details.table.title")}
          </span>
          <div className="w-full overflow-x-auto md:mx-0 mx-12px pb-3 md:pr-0 pr-12px">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent h-full sticky -left-0.5 z-10 md:static font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 text-left font-semibold py-2 px-1 md:px-3">
                    <SortButton
                      name="sort-by-name"
                      onClick={() => handleSort(sortOptions.name)}
                      order={order}
                      label={t("funds-list-table.table.title.name")}
                      showArrow={sortBy === sortOptions.name}
                    />
                  </th>
                  <th></th>
                  <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
                    <SortButton
                      className="w-fit ml-auto"
                      name="sort-by-price"
                      onClick={() => handleSort(sortOptions.price)}
                      order={order}
                      label={t("fund-details.table.columns.price")}
                      showArrow={sortBy === sortOptions.price}
                    />
                  </th>
                  <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
                    <SortButton
                      className="w-fit ml-auto"
                      name="sort-by-cap"
                      onClick={() => handleSort(sortOptions.cap)}
                      order={order}
                      label={t("fund-details.table.columns.cap")}
                      showArrow={sortBy === sortOptions.cap}
                    />
                  </th>
                  <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
                    <SortButton
                      className="w-fit ml-auto"
                      name="sort-by-24h-change"
                      onClick={() => handleSort(sortOptions.changeDaily)}
                      order={order}
                      label={t("fund-details.table.columns.change")}
                      showArrow={sortBy === sortOptions.changeDaily}
                    />
                  </th>
                  <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
                    <SortButton
                      className="w-fit ml-auto"
                      name="sort-by-24h-volume"
                      onClick={() => handleSort(sortOptions.volume)}
                      order={order}
                      label={t("fund-details.table.columns.volume")}
                      showArrow={sortBy === sortOptions.volume}
                    />
                  </th>
                  <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
                    <SortButton
                      className="w-fit ml-auto"
                      name="sort-by-share"
                      onClick={() => handleSort(sortOptions.share)}
                      order={order}
                      label={t("fund-details.table.columns.share")}
                      showArrow={sortBy === sortOptions.share}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedList.map((dataRow) => (
                  <FundsDetailsTableRow
                    key={dataRow.token_policy_id}
                    handleTableHover={handleTableHover}
                    dataRow={dataRow}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <SwipeToSeeMore />
        </div>
      )}
    </>
  );
};
