import { Token } from "@/pages/create-fund/components/select-tokens/select-tokens";

export const getCurrentDistribution = (
  ids: string[],
  tokens: Record<string, Token>,
) => {
  if (ids.length === 0) {
    return "100.00";
  }

  if (ids.length === 1) {
    return (+(tokens[ids[0]]?.distribution ?? 0)).toFixed(1);
  }

  return ids.reduce((acc, id) => {
    if (Number.isNaN(+acc)) {
      return (
        +(tokens[acc]?.distribution ?? 0) + +(tokens[id]?.distribution ?? 0)
      ).toFixed(1);
    }
    return (+acc + +(tokens[id]?.distribution ?? 0)).toFixed(1);
  });
};
