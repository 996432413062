import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import { ROUTES } from "@constants/routes/routes";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";
import { TokensList } from "./tokens-list/tokens-list";
import { ActualFundType } from "@constants/funds-data";

interface Props {
  fundInfo: ActualFundType;
}

export const FundInteractionsInfoCard = ({ fundInfo }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const firstImage = fundInfo.tokens[0].token_image;
  const secondImage = fundInfo.tokens[1].token_image;

  return (
    <div className="w-full p-24px flex flex-col bg-white dark:bg-dark-gray900 rounded-lg">
      <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-24px md:mb-12px">
        <div className="w-full flex gap-12px items-center">
          <Link
            to={ROUTES.interactions.path}
            className="flex items-center justify-center"
          >
            <Icon
              icon={ICON_COLLECTION.arrowLeft}
              iconColor={theme === "dark" ? "#fff" : "#1F2937"}
            />
          </Link>
          <div className="relative" style={{ width: "40px", height: "40px" }}>
            <div className="absolute top-0 left-0 z-10 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
              <img src={firstImage} alt="" />
            </div>
            <div className="absolute bottom-0 right-0 z-20 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
              <img src={secondImage} alt="" />
            </div>
            <div className="absolute bottom-0 left-0 z-30 w-6 h-6 rounded-full bg-violet-700 border border-white flex items-center justify-center font-bold font-sf-pro text-white text-xs	">
              {fundInfo.tokens.length}
            </div>
          </div>
          <h3 className="text-gray-800 dark:text-white text-2xl font-semibold font-sf-pro">
            {fundInfo.fund_name}
          </h3>
          <div className="flex items-center justify-center px-2 rounded-full text-violet-600 bg-violet-200 dark:bg-gray-600 dark:text-gray-100">
            {t("funds-list-table.table.featured")}
          </div>
        </div>
        <Link
          to={`/details/${fundInfo.fund_id}`}
          className="group py-3 px-8 w-full md:w-fit text-center rounded-full bg-violet-200 hover:bg-violet-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors duration-300"
        >
          <span className="text-l font-sf-pro font-semibold text-violet-600 dark:text-white">
            Info
          </span>
        </Link>
      </div>
      <div className="flex flex-col md:flex-row items-start justify-between gap-24px md:gap-32px mb-32px">
        <div className="w-full md:w-1/2 flex flex-col gap-12px font-sf-pro">
          <h5 className="text-gray-800 dark:text-white text-l font-medium">
            {t("funds-interactions-info.about.title")}
          </h5>
          <span className="text-gray-500 text-l font-medium">
            {fundInfo.fund_description}
          </span>
        </div>
        <div className="w-full md:w-1/2 flex flex-col gap-12px font-sf-pro">
          <h5 className="text-gray-800 dark:text-white text-l font-medium">
            {t("funds-interactions-info.tokens.title")}
          </h5>
          <TokensList tokens={fundInfo.tokens} />
        </div>
      </div>
      <div className="w-full flex flex-col md:grid md:grid-rows-3 md:grid-flow-col gap-y-16px gap-x-64px">
        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.price")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {Number(fundInfo.fund_price.toFixed(2)).toLocaleString("en")}
          </span>
        </div>

        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.change_24")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {Number((fundInfo.price_change24h ?? 0).toFixed(2)).toLocaleString(
              "en",
            )}
          </span>
        </div>

        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.total")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {Number(fundInfo.total_locked.toFixed(2)).toLocaleString("en")}
          </span>
        </div>

        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.inflow")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {Number(fundInfo.inflow_7d.toFixed(2)).toLocaleString("en")}
          </span>
        </div>

        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.outflow")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {Number(fundInfo.outflow_7d.toFixed(2)).toLocaleString("en")}
          </span>
        </div>

        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#D1D5DB" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-info.bottom.fee")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Tooltip"
            />
          </h6>
          <div className="w-full h-full border-b-2 border-dotted border-gray-300 dark:border-gray-700"></div>
          <span className="text-l h-full text-gray-800 dark:text-white font-medium whitespace-nowrap">
            {fundInfo.fund_fee}
          </span>
        </div>
      </div>
    </div>
  );
};
