import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import clsx from "clsx";

import { useTheme } from "@/components/theme-context/theme-context";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Switcher } from "@ui/switcher/switcher";
import { Search } from "@ui/search/search";

import styles from "./holds-mobile-filters.module.scss";
import "rc-slider/assets/index.css";

interface HoldsMobileFiltersProps {
  rangeTotalValue?: number[];
  setTotalRangeValue?: (value: number[]) => void;
  rangePriceValue?: number[];
  setPriceRangeValue?: (value: number[]) => void;
}

const HoldsMobileFilters: React.FC<HoldsMobileFiltersProps> = ({
  rangeTotalValue,
  setTotalRangeValue,
  rangePriceValue,
  setPriceRangeValue,
}) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleTotalRangeChange = (value) => {
    if (setTotalRangeValue) {
      setTotalRangeValue(value);
    }
  };
  const handlePriceRangeChange = (value) => {
    if (setPriceRangeValue) {
      setPriceRangeValue(value);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  const handleSwitch = () => {};

  const handleApply = () => {
    setIsOpen(false);
    setIsFiltersSelected(true);
  };

  return (
    <div>
      <button
        className="flex gap-3 p-3 border border-gray-200 rounded-xl dark:border-gray-700 dark:bg-gray-900 relative"
        onClick={toggleDropdown}
      >
        <Icon
          icon={ICON_COLLECTION.settings}
          iconColor={theme === "dark" ? "#D1D5DB" : "#9CA3AF"}
        />
        {searchValue !== "" && (
          <span
            className={clsx(
              "w-12px h-12px rounded-full absolute -top-1 -right-1 bg-red-500",
              {
                hidden: !isFiltersSelected,
              },
            )}
          ></span>
        )}
      </button>
      <div
        className={clsx(styles["mobile-dropdown-blur"], {
          [styles["mobile-dropdown-blur--open"]]: isOpen,
        })}
        onClick={() => setIsOpen(false)}
      ></div>
      <div
        className={clsx(
          "rounded-tl-3xl rounded-tr-3xl fixed z-50 left-0 bottom-0 w-full flex flex-col gap-3 items-center p-4 bg-white dark:bg-gray-950 transition-all duration-700",
          {
            "translate-y-full": !isOpen,
            "translate-y-0": isOpen,
          },
        )}
      >
        <span
          className="w-16 h-1.5 rounded bg-gray-300"
          onClick={() => setIsOpen(false)}
        ></span>
        <div className="w-full flex justify-between">
          <h3 className="w-full font-sf-pro text-gray-800 font-semibold text-2xl dark:text-white">
            Filters
          </h3>
        </div>
        <div className="items-center gap-2 flex w-full py-12px">
          <Switcher className="w-44 h-7" logic={handleSwitch} />
          <span className="font-sf-pro text-gray-700 dark:text-white">
            Only Verified
          </span>
        </div>
        <Search
          isExpand={false}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="flex w-full flex-col gap-2">
          <h4 className="text-gray500 dark:text-white font-sf-pro font-semibold text-l">
            Total locked:
          </h4>
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                value={rangeTotalValue ? rangeTotalValue[0] : ""}
                onChange={(e) =>
                  handleTotalRangeChange([
                    +e.target.value,
                    rangeTotalValue ? rangeTotalValue[1] : "",
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.cross}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                value={rangeTotalValue ? rangeTotalValue[1] : ""}
                onChange={(e) =>
                  handleTotalRangeChange([
                    rangeTotalValue ? rangeTotalValue[0] : "",
                    +e.target.value,
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.cross}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
          <div className="px-3">
            <Slider
              range
              min={0}
              max={100000}
              value={rangeTotalValue}
              onChange={handleTotalRangeChange}
              className="range-slider mb-3"
            />
          </div>
        </div>
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="flex w-full flex-col gap-2">
          <h4 className="text-gray500 dark:text-white font-sf-pro font-semibold text-l">
            Price change:
          </h4>
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                value={rangePriceValue ? rangePriceValue[0] : ""}
                onChange={(e) =>
                  handlePriceRangeChange([
                    +e.target.value,
                    rangePriceValue ? rangePriceValue[1] : "",
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
            <div className="flex flex-col w-1/2 relative">
              <input
                type="number"
                className="py-3 px-4 border border-gray-200 rounded-lg text-gray-800 font-medium w-full dark:bg-gray-800 dark:border-gray-800 dark:text-white"
                value={rangePriceValue ? rangePriceValue[1] : ""}
                onChange={(e) =>
                  handlePriceRangeChange([
                    rangePriceValue ? rangePriceValue[0] : "",
                    +e.target.value,
                  ])
                }
              />
              <Icon
                icon={ICON_COLLECTION.percent}
                iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                iconSize="22px"
                className="absolute top-1/2 right-2 -translate-y-1/2"
              />
            </div>
          </div>
          <div className="px-3">
            <Slider
              range
              min={0}
              max={100}
              value={rangePriceValue}
              onChange={handlePriceRangeChange}
              className="range-slider mb-3"
            />
          </div>
        </div>
        <span className="w-28 h-px bg-gray-300"></span>
        <div className="flex w-full justify-between gap-3">
          <button
            onClick={handleApply}
            className="w-1/2 h-12 text-white bg-violet-500 dark:bg-violet-700 rounded-full flex items-center justify-center"
          >
            Apply
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="w-1/2 h-12 text-violet-600 bg-violet-200 dark:bg-gray-800 dark:text-white rounded-full flex items-center justify-center"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default HoldsMobileFilters;
