import React from "react";
import { useTranslation } from "react-i18next";

import { FundsTableRow } from "./funds-table-row";
import { SortButton } from "@/pages/funds-list/components/funds-main/funds-table/sort-button";
import { useTableSort } from "@/hooks/use-table-sort/use-table-sort";
import { ActualFundType } from "@constants/funds-data";
import { useOverviewFilterHandlers } from "@/providers/overview-filters-provider/hooks/use-overview-filter-handlers";
import { useOverviewFilterValues } from "@/providers/overview-filters-provider/hooks/use-overview-filter-values";

interface Props {
  data: ActualFundType[];
}

export const FundsTable = ({ data }: Props) => {
  const { t } = useTranslation();

  const { filters } = useOverviewFilterValues();
  const { handleSortUpdate } = useOverviewFilterHandlers();
  const { sortOptions } = useTableSort();

  return (
    <table className="w-full mt-7">
      <thead>
        <tr className="relative">
          <th></th>
          <th></th>
          <th className="w-full bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent h-full sticky -left-0.5 z-10 md:static font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 text-left font-semibold py-2 px-1 md:px-3">
            <SortButton
              name="sort-by-name"
              onClick={() => handleSortUpdate(sortOptions.name)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.name")}
              showArrow={filters.sortBy === sortOptions.name}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-price"
              onClick={() => handleSortUpdate(sortOptions.price)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.price")}
              showArrow={filters.sortBy === sortOptions.price}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-daily-change"
              onClick={() => handleSortUpdate(sortOptions.changeDaily)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.change_24")}
              showArrow={filters.sortBy === sortOptions.changeDaily}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-total-locked"
              onClick={() => handleSortUpdate(sortOptions.totalLocked)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.total")}
              showArrow={filters.sortBy === sortOptions.totalLocked}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-inflow"
              onClick={() => handleSortUpdate(sortOptions.inflow)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.inflow")}
              showArrow={filters.sortBy === sortOptions.inflow}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-0 text-right">
            <SortButton
              name="sort-by-outflow"
              onClick={() => handleSortUpdate(sortOptions.outflow)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.outflow")}
              showArrow={filters.sortBy === sortOptions.outflow}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 px-3 pr-12px text-right">
            <SortButton
              name="sort-by-fund-fee"
              onClick={() => handleSortUpdate(sortOptions.fundFee)}
              order={filters.sortOrder}
              label={t("funds-list-table.table.title.fee")}
              showArrow={filters.sortBy === sortOptions.fundFee}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataRow) => (
          <FundsTableRow key={dataRow.id} dataRow={dataRow} />
        ))}
      </tbody>
    </table>
  );
};
