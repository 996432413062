import React, { useRef } from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { useTheme } from "@/components/theme-context/theme-context";

interface SearchProps {
  isInputFocused?: boolean;
  setIsInputFocused?: (value: boolean) => void;
  isExpand?: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
}

export const Search: React.FC<SearchProps> = ({
  isInputFocused,
  setIsInputFocused,
  isExpand = true,
  searchValue,
  setSearchValue,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const inputRef = useRef<HTMLInputElement>(null);

  const isMobile = width < 992;

  const handleInputFocus = () => {
    if (setIsInputFocused) {
      setIsInputFocused(true);
    }
  };
  const handleInputBlur = (e) => {
    if (!e.relatedTarget && setIsInputFocused) {
      setIsInputFocused(false);
    }
  };
  const handleClickCross = () => {
    setSearchValue("");
    setIsInputFocused?.(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div
      className={twMerge(
        clsx("h-14 w-full md:w-72", {
          "w-full": isExpand,
        }),
      )}
    >
      <div
        className={twMerge(
          clsx(
            `flex items-center justify-start py-3 px-5 rounded-full bg-violet-50 dark:bg-gray-800`,
            {
              "w-full relative gap-3": !isExpand,
              "absolute top-1/2 -translate-y-1/2 left-0 z-20 h-full md:gap-3":
                isExpand,
              "gap-3 w-full transition-all": isInputFocused,
            },
          ),
        )}
      >
        <button
          onClick={handleInputFocus}
          className="h-full flex items-center justify-center"
        >
          <Icon icon={ICON_COLLECTION.SearchIcon} />
        </button>
        <input
          ref={inputRef}
          type="text"
          name="search-input"
          value={searchValue}
          placeholder={
            isInputFocused
              ? t("funds-list-table.search-bar.typing")
              : t("funds-list-table.search-bar.search")
          }
          className={twMerge(
            clsx(
              "md:w-full bg-transparent dark:text-white font-medium placeholder:font-medium",
              {
                "w-full placeholder:text-gray-800 dark:placeholder:text-white":
                  isInputFocused,
                "w-full": isExpand,
              },
            ),
          )}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {isInputFocused || !isExpand ? (
          <button
            onClick={handleClickCross}
            className="absolute top-1/2 -translate-y-1/2 right-16px z-20 h-16px w-16px"
          >
            <Icon
              icon={ICON_COLLECTION.cross}
              iconColor={theme === "dark" ? "#fff" : "#6B7280"}
              iconSize={isMobile ? "20px" : "16px"}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
