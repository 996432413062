import { useContext } from "react";
import { OverviewFiltersContext } from "@/providers/overview-filters-provider/context";

export const useOverviewFilterValues = () => {
  const context = useContext(OverviewFiltersContext);

  if (!context) {
    throw new Error(
      "useOverviewFilterValues hook should be used within OverviewFiltersProvider",
    );
  }

  const { filters, maxLockedRange, minPriceChange } = context;
  return {
    filters,
    maxLockedRange,
    minPriceChange,
  };
};
