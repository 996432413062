import React, { useRef } from "react";

import styles from "./modal.module.scss";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  contentClassName?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  contentClassName = "",
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, onClose);

  if (!isOpen) {
    return null; // Don't render the modal if it's not open.
  }

  return (
    <div className={styles.modal}>
      <div
        ref={modalRef}
        className={twMerge(
          styles.modalContent,
          contentClassName,
          "w-[90%] md:w-full border-px border-white",
        )}
      >
        <button className="absolute right-4 top-4 z-10" onClick={onClose}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-dark dark:text-white"
          >
            <path
              d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
};
