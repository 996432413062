import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VioletButton } from "@ui/violet-button/violet-button";
import {
  TABS,
  TabValue,
} from "@/pages/funds-list/components/funds-main/funds-main";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "usehooks-ts";

interface FundsMainNavBarProps {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
  // isMobile: boolean;
}

export const FundsMainNavBar: React.FC<FundsMainNavBarProps> = ({
  activeTab,
  setActiveTab,
  // isMobile,
}) => {
  const navigate = useNavigate();

  const handleTabClick = (tab: TabValue) => {
    setActiveTab(tab);
  };
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = width <= 992;

  return (
    <div className="flex justify-between items-center w-full font-sf-pro text-lg mb-5">
      <div className="flex gap-3">
        <button
          onClick={() => handleTabClick(TABS.featured)}
          className={twMerge(
            clsx(
              "text-gray-400 text-l md:text-xl py-2 px-3 border-b-2 border-transparent hover:bg-violet_light hover:text-white dark:hover:text-white transition-all duration-300 dark:text-gray-500",
              {
                "text-violet-600 dark:text-white border-violet-600":
                  activeTab === TABS.featured,
              },
            ),
          )}
        >
          {t("funds-list-table.nav-bar.menu.featured")}
        </button>
        <button
          onClick={() => handleTabClick(TABS.all)}
          className={twMerge(
            clsx(
              "text-gray-400 text-l md:text-xl py-2 px-3 border-b-2 border-transparent hover:bg-violet_light hover:text-white dark:hover:text-white transition-all duration-300 dark:text-gray-500",
              {
                "text-violet-600 dark:text-white border-violet-600":
                  activeTab === TABS.all,
              },
            ),
          )}
        >
          {t("funds-list-table.nav-bar.menu.all")}
        </button>
      </div>
      <div className="flex gap-3 items-center">
        <button
          onClick={() => handleTabClick(TABS.bookmarks)}
          className={twMerge(
            clsx(
              "text-gray-400 text-l md:text-xl py-2 px-3 border-b-2 border-transparent hover:bg-violet_light hover:text-white dark:hover:text-white transition-all duration-300 dark:text-gray-500",
              {
                "text-violet-600 dark:text-white border-violet-600":
                  activeTab === TABS.bookmarks,
              },
            ),
          )}
        >
          {t("funds-list-table.nav-bar.bookmarks")}
        </button>
        {isMobile ? null : (
          <VioletButton
            onClick={() => navigate("/create")}
            text={t("funds-list-table.nav-bar.new-fund")}
            className="py-4 px-32px"
          />
        )}
      </div>
    </div>
  );
};
