import React, { useRef, useState } from "react";
import clsx from "clsx";
import Slider from "rc-slider";
import { usePopper } from "react-popper";
import { useOnClickOutside } from "usehooks-ts";

import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Icon } from "@ui/icons/icon";
import { useTheme } from "@/components/theme-context/theme-context";

import "./range-dropdown.scss";
import "rc-slider/assets/index.css";
import styles from "./range-dropdown.module.scss";
import { twMerge } from "tailwind-merge";

interface RangeDropdownProps {
  title: string;
  rangeValue: number[];
  setRangeValue: (value: number[]) => void;
  min: number;
  max: number;
  mode?: string;
  activeRadio: string;
  setActiveRadio: (value: string) => void;
}

const RangeDropdown: React.FC<RangeDropdownProps> = ({
  title,
  rangeValue,
  setRangeValue,
  min,
  max,
  mode,
  activeRadio,
  setActiveRadio,
}) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-end",
  });

  const handleRangeChange = (value) => {
    setRangeValue(value);
  };

  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  useOnClickOutside(popperRef, () => setIsOpen(false));

  return (
    <div ref={popperRef}>
      <div>
        <button
          className={clsx(
            "flex gap-3 p-3 border border-gray-200 rounded-xl dark:border-gray-700 relative",
            {
              "border-violet-600 dark:border-violet-600": isOpen,
            },
          )}
          ref={buttonRef}
          onClick={toggleDropdown}
        >
          <span className="text-gray-500 font-medium dark:text-gray-300 text-l">
            {title}:
          </span>
          <Icon
            icon={ICON_COLLECTION.chevronDown}
            className={twMerge(
              "w-6 h-6 transition-transform duration-200",
              isOpen && "-rotate-180",
            )}
            iconColor={theme === "dark" ? "#fff" : "#000"}
          />
        </button>
      </div>
      {isOpen && (
        <div
          style={popper.styles.popper}
          className="dialog-sheet p-3 mt-1 z-30 bg-white border border-gray-200 rounded-lg shadow-lg shadow-gray-300 dark:shadow-gray-800 dark:bg-gray-950 dark:border-gray-950"
          ref={setPopperElement}
          {...popper.attributes.popper}
          role="dialog"
          aria-label="Dropdown"
        >
          <div className="flex flex-col gap-3">
            <div className="flex justify-between items-center gap-3">
              <div className="flex flex-col">
                <span className="text-black dark:text-white">From:</span>
                <div className="relative py-3 px-4 border border-gray-200 rounded-lg w-28 dark:bg-gray-800 dark:border-gray-800">
                  <input
                    type="number"
                    value={rangeValue[0]}
                    className="bg-transparent text-gray-800 font-medium dark:text-white w-2/3"
                    onChange={(e) =>
                      handleRangeChange([+e.target.value, rangeValue[1]])
                    }
                  />
                  {mode === "price" || mode === "fee" ? (
                    <Icon
                      icon={ICON_COLLECTION.percent}
                      iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                      iconSize="20px"
                      className="absolute top-1/2 right-2 -translate-y-1/2"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-black dark:text-white">To:</span>
                <div className="relative py-3 px-4 border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-800 w-28">
                  <input
                    type="number"
                    value={rangeValue[1]}
                    className="bg-transparent text-gray-800 font-medium dark:text-white w-fit"
                    onChange={(e) =>
                      handleRangeChange([rangeValue[0], +e.target.value])
                    }
                  />
                  {mode === "price" || mode === "fee" ? (
                    <Icon
                      icon={ICON_COLLECTION.percent}
                      iconColor={theme === "dark" ? "#fff" : "#D1D5DB"}
                      iconSize="20px"
                      className="absolute top-1/2 right-2 -translate-y-1/2"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {mode === "price" ? (
              <div className="flex w-full justify-between px-1">
                <div className={`${styles["radio-button"]}`}>
                  <input
                    type="radio"
                    name="radio"
                    checked={activeRadio === "24h"}
                    onChange={() => setActiveRadio("24h")}
                    className={styles["radio-button__input"]}
                  />
                  <label
                    htmlFor="radio"
                    className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
                  >
                    24h
                  </label>
                </div>
                <div className={`${styles["radio-button"]}`}>
                  <input
                    type="radio"
                    name="radio"
                    checked={activeRadio === "7d"}
                    onChange={() => setActiveRadio("7d")}
                    className={styles["radio-button__input"]}
                  />
                  <label
                    htmlFor="radio"
                    className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
                  >
                    7d
                  </label>
                </div>
                <div className={`${styles["radio-button"]}`}>
                  <input
                    type="radio"
                    name="radio"
                    checked={activeRadio === "all"}
                    onChange={() => setActiveRadio("all")}
                    className={styles["radio-button__input"]}
                  />
                  <label
                    htmlFor="radio"
                    className={`${styles["radio-button__label"]} text-gray-700 font-medium dark:text-gray-500`}
                  >
                    All time
                  </label>
                </div>
              </div>
            ) : null}
            <div className="px-3">
              <Slider
                range
                min={min}
                max={max}
                value={rangeValue}
                onChange={handleRangeChange}
                className="range-slider mb-3"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeDropdown;
