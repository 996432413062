import { TOP_FUND_COLLECTION } from "@/assets/icons/top-funds/iconsConstant";
import table_coin_1 from "@/assets/icons/details/table-coin-1.png";
import table_coin_2 from "@/assets/icons/details/table-coin-2.png";
import table_coin_3 from "@/assets/icons/details/table-coin-3.png";

export const FUND_DETAILS = {
  count: {
    quantity: 2,
    countIcon_1: TOP_FUND_COLLECTION.topFund_3_1,
    countIcon_2: TOP_FUND_COLLECTION.topFund_3_2,
  },
  title: "Claire",
  featured: true,
  infoText: {
    title: "fund-details.info.title",
    text: "fund-details.info.text",
  },
  infoList: [
    {
      label: "fund-details.infoList.total",
      value: "12,915",
    },
    {
      label: "fund-details.infoList.tokens",
      value: "7",
    },
    {
      label: "fund-details.infoList.price",
      value: "27.35",
    },
    {
      label: "fund-details.infoList.inflow",
      value: "63159.14",
    },
    {
      label: "fund-details.infoList.outflow",
      value: "63186.49",
    },
    {
      label: "fund-details.infoList.change",
      value: "-5.2%",
    },
    {
      label: "fund-details.infoList.creator",
      value: "JohnDoe",
    },
    {
      label: "fund-details.infoList.fee",
      value: "2.1%",
    },
  ],
};

export const DETAILS_PIE_DIAGRAM = [
  {
    label: "EMP",
    value: "32",
    color: "#5B21B6",
  },
  {
    label: "SNOW",
    value: "24",
    color: "#6D28D9",
  },
  {
    label: "MELD",
    value: "18",
    color: "#7C3AED",
  },
  {
    label: "MIN",
    value: "11",
    color: "#8B5CF6",
  },
  {
    label: "CLAP",
    value: "5",
    color: "#A78BFA",
  },
  {
    label: "LENFI",
    value: "5",
    color: "#C4B5FD",
  },
  {
    label: "AGIX",
    value: "5",
    color: "#DDD6FE",
  },
  {
    label: "AGIX",
    value: "5",
    color: "#DDD6FE",
  },
  {
    label: "AGIX",
    value: "5",
    color: "#DDD6FE",
  },
];

export const DETAILS_CHARTS = [
  {
    title: "fund-details.charts.ada",
    data: [0.3, 0.312, 0.299, 0.321, 0.322, 0.317, 0.324],
  },
  {
    title: "fund-details.charts.total",
    data: [1.2, 1.1, 0.95, 1, 1.05, 1, 1.2],
  },
];

export const DETAILS_TABLE = [
  {
    icon: table_coin_1,
    title: "EMP",
    price: 0.339212,
    cap: 1124.2,
    change_24: -1.2,
    volume_24: 0,
    share: 30,
  },
  {
    icon: table_coin_2,
    title: "SNOW",
    price: 2.156112,
    cap: 5021.1,
    change_24: 9.5,
    volume_24: 0,
    share: 17,
  },
  {
    icon: table_coin_3,
    title: "MELD",
    price: 0.782145,
    cap: 4501.9,
    change_24: -10.1,
    volume_24: 0,
    share: 21,
  },
];
