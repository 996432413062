//todo: probably will be a func.
import { ActualFundType } from "@constants/funds-data";
import { formatImageLink } from "@/api/helpers/helper";

const fetchFundsQuery = "https://api.linkage.finance/funds";
const options: RequestInit = {
  method: "GET",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
};

export const fetchFundsList = async (): Promise<ActualFundType[]> => {
  try {
    const res = await fetch(fetchFundsQuery, options);
    const list = await res.json();

    return list.map((fund: ActualFundType) => ({
      ...fund,
      tokens: fund.tokens.map((token) => ({
        ...token,
        token_image: formatImageLink(token.token_image),
      })),
    }));
  } catch (e) {
    console.error("API fetchFunds ERROR ::", e);
    return [];
  }
};
