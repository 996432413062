import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Pagination from "@ui/pagination/pagination";
import { SwipeToSeeMore } from "@ui/swipe/swipe";

import { Loader } from "@ui/loader/loader";
import { FundsMainNavBar } from "./funds-main-search-bar/funds-main-nav-bar";
import { FundsMainSearchBar } from "./funds-main-search-bar/funds-main-search-bar";
import { FundsTable } from "./funds-table/funds-table";
import { useBookmarks } from "@/providers/bookmarks-provider/hooks/use-bookmarks";
import { useFunds } from "@/providers/bookmarks-provider/hooks/use-fund";
import { useOverviewFilterValues } from "@/providers/overview-filters-provider/hooks/use-overview-filter-values";
import { useOverviewFilterHandlers } from "@/providers/overview-filters-provider/hooks/use-overview-filter-handlers";
import {
  SORT_ORDER_OPTIONS,
  SortOrderValue,
} from "@/hooks/use-table-sort/use-table-sort";

const perPage = 10;

export const TABS = {
  featured: "featured",
  all: "all",
  bookmarks: "bookmarks",
} as const;
export type TabValue = typeof TABS[keyof typeof TABS];

export type Filters = {
  sortBy: string;
  sortOrder: SortOrderValue | "";
  searchValue: string;
  onlyVerified: boolean;
  totalLockedRange: number[];
  priceChangeRange: number[];
  activePriceRadio: string;
  feeRange: number[];
};
export const FILTER_KEYS = {
  search: "searchValue",
  verified: "onlyVerified",
  locked: "totalLockedRange",
  priceChange: "priceChangeRange",
  priceRadio: "activePriceRadio",
  fee: "feeRange",
} as const;
export type FilterKeysValue = typeof FILTER_KEYS[keyof typeof FILTER_KEYS];

const SORT_TO_FILTERS = {
  name: "fund_name",
  price: "fund_price",
  ["change-daily"]: "price_change24h",
  ["total-locked"]: "total_locked",
  inflow: "inflow_7d",
  outflow: "outflow_7d",
  ["fund-fee"]: "fund_fee",
};

export const FundsMain = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<TabValue>(
    () => (searchParams.get("tab") as TabValue) ?? TABS.featured,
  );
  const [currentPage, setCurrentPage] = useState(() =>
    searchParams.has("page") ? Number(searchParams.get("page")) : 1,
  );

  const { bookmarks } = useBookmarks();
  const { funds, isFundsLoading } = useFunds();

  const { filters, maxLockedRange, minPriceChange } = useOverviewFilterValues();
  const { updateFilterField } = useOverviewFilterHandlers();

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleTabChange = (tab: TabValue) => {
    if (activeTab === TABS.bookmarks && tab === TABS.bookmarks) {
      setSearchParams((prev) => ({
        ...prev,
        ...filters,
        tab: TABS.all,
      }));
      setActiveTab(TABS.all);
      setCurrentPage(1);
    }
    if (activeTab === tab) return;
    setSearchParams((prev) => ({
      ...prev,
      ...filters,
      tab,
    }));
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const { displayList, totalPages } = useMemo(() => {
    let newFunds = [...funds];

    if (activeTab === TABS.featured) {
      newFunds = newFunds.filter((fund) => fund.featured);
    }
    if (activeTab === TABS.bookmarks) {
      newFunds = bookmarks
        .filter((fund_id) => funds.some((fund) => fund.fund_id === fund_id))
        .map((fund_id) => funds.find((fund) => fund.fund_id === fund_id)!);
    }
    // if (filters.onlyVerified) {
    //   funds = funds.filter((fund) => fund.verified);
    // }
    if (filters.searchValue.trim() !== "") {
      newFunds = newFunds.filter((fund) =>
        fund.fund_name
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase().trim()),
      );
    }

    if (!!filters.sortOrder && filters.sortOrder) {
      if (filters.sortBy === "name") {
        newFunds.sort((fund_1, fund_2) =>
          filters.sortOrder === SORT_ORDER_OPTIONS.ASC
            ? fund_1.fund_name.localeCompare(fund_2.fund_name)
            : fund_2.fund_name.localeCompare(fund_1.fund_name),
        );
      } else {
        newFunds.sort((fund_1, fund_2) =>
          filters.sortOrder === SORT_ORDER_OPTIONS.ASC
            ? fund_1[SORT_TO_FILTERS[filters.sortBy]] -
              fund_2[SORT_TO_FILTERS[filters.sortBy]]
            : fund_2[SORT_TO_FILTERS[filters.sortBy]] -
              fund_1[SORT_TO_FILTERS[filters.sortBy]],
        );
      }
    }

    const returnList = newFunds
      .filter(
        (fund) =>
          fund.total_locked >= filters.totalLockedRange[0] &&
          fund.total_locked <= filters.totalLockedRange[1],
      )
      .filter(
        (fund) =>
          fund.price_change24h >= filters.priceChangeRange[0] &&
          fund.price_change24h <= filters.priceChangeRange[1],
      )
      .filter(
        (fund) =>
          fund.fund_fee >= filters.feeRange[0] / 100 &&
          fund.fund_fee <= filters.feeRange[1] / 100,
      );

    return {
      displayList: returnList.slice(
        currentPage === 1 ? 0 : perPage * (currentPage - 1),
        currentPage === 1 ? perPage : perPage * currentPage,
      ),
      totalPages: Math.ceil(funds.length / perPage),
    };
  }, [activeTab, currentPage, filters, bookmarks, funds]);

  return (
    <section className="mt-6 p-12px md:px-24px md:py-32px w-11/12 md:w-full bg-white dark:bg-dark-gray900 rounded-lg overflow-hidden md:overflow-visible">
      <FundsMainNavBar activeTab={activeTab} setActiveTab={handleTabChange} />
      <FundsMainSearchBar
        maxLocked={maxLockedRange}
        handleFilters={updateFilterField}
        filters={filters}
        // todo: fix mobile setFilters
        setFilters={(values) => console.log("mob filters", values)}
      />
      <div className="w-full">
        {isFundsLoading ? (
          <Loader />
        ) : (
          <div className="w-full">
            <div className="w-full overflow-x-scroll md:overflow-x-auto pb-3 md:pb-0">
              <FundsTable data={displayList} />
            </div>
            <SwipeToSeeMore />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </section>
  );
};
