import React, { useEffect, useState } from "react";
import { OverviewFiltersContext } from "@/providers/overview-filters-provider/context";
import { Filters } from "@/pages/funds-list/components/funds-main/funds-main";
import { useSearchParams } from "react-router-dom";
import { useFunds } from "@/providers/bookmarks-provider/hooks/use-fund";
import {
  SORT_ORDER_OPTIONS,
  SortOrderValue,
} from "@/hooks/use-table-sort/use-table-sort";

interface Props {
  children: React.ReactNode;
}

const OverviewFiltersProvider = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filters>(() => {
    const sortBy = searchParams.get("sortBy") ?? "";
    const sortOrder = (searchParams.get("sortOrder") as SortOrderValue) ?? "";
    const searchValue = searchParams.get("searchValue") ?? "";
    const onlyVerified = searchParams.get("onlyVerified") !== "false" ?? false;

    const totalLockedRange = searchParams.has("totalLockedRange")
      ? [...searchParams.getAll("totalLockedRange")].map((item) => Number(item))
      : [0, 1_000_000];
    const priceChangeRange = searchParams.has("priceChangeRange")
      ? [...searchParams.getAll("priceChangeRange")].map((item) => Number(item))
      : [0, 100];
    const activePriceRadio = searchParams.get("activePriceRadio") ?? "24h";
    const feeRange = searchParams.has("feeRange")
      ? [...searchParams.getAll("feeRange")].map((item) => Number(item))
      : [0, 100];

    return {
      searchValue,
      onlyVerified,
      totalLockedRange,
      priceChangeRange,
      activePriceRadio,
      feeRange,
      sortBy,
      sortOrder,
    };
  });

  const { funds } = useFunds();

  const maxLockedRange =
    funds.length < 2
      ? 1_000_000
      : funds.reduce((prev, curr) =>
          prev.total_locked > curr.total_locked ? prev : curr,
        ).total_locked;

  const minPriceChange =
    funds.length < 2
      ? 0
      : funds.reduce((prev, curr) =>
          prev.price_change24h < curr.price_change24h ? prev : curr,
        ).price_change24h;

  const updateFilterField = (
    key: keyof Filters,
    value: Filters[keyof Filters],
  ) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
    setSearchParams((prev) => ({
      ...prev,
      ...filters,
      [key]: value,
    }));
  };

  const handleSortUpdate = (sortBy: string) => {
    const sortOrder =
      sortBy === filters.sortBy && filters.sortOrder === SORT_ORDER_OPTIONS.ASC
        ? SORT_ORDER_OPTIONS.DESC
        : SORT_ORDER_OPTIONS.ASC;

    setFilters((prev) => ({
      ...prev,
      sortBy,
      sortOrder,
    }));
    setSearchParams((prev) => ({
      ...prev,
      ...filters,
      sortBy,
      sortOrder,
    }));
  };

  useEffect(() => {
    if (maxLockedRange !== 1_000_000) {
      updateFilterField("totalLockedRange", [
        filters.totalLockedRange[0],
        maxLockedRange,
      ]);
    }
    if (minPriceChange !== 0) {
      updateFilterField("priceChangeRange", [
        minPriceChange,
        filters.priceChangeRange[1],
      ]);
    }
  }, [minPriceChange, maxLockedRange]);

  return (
    <OverviewFiltersContext.Provider
      value={{
        filters,
        maxLockedRange,
        minPriceChange,
        updateFilterField,
        handleSortUpdate,
      }}
    >
      {children}
    </OverviewFiltersContext.Provider>
  );
};

export default OverviewFiltersProvider;
