import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input = ({ className, ...rest }: InputProps) => {
  return (
    <input
      {...rest}
      className={twMerge(
        "w-full py-3 px-4 border border-gray-200 rounded focus:border-violet_light dark:focus:border-violet_light hover:border-violet_light disabled:hover:border-gray-200 dark:hover:border-violet_light dark:disabled:hover:border-gray-800 dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500",
        className,
      )}
    />
  );
};
