import React, { MouseEvent, useRef } from "react";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { VioletButton } from "@ui/violet-button/violet-button";
import { useLockedBody, useOnClickOutside } from "usehooks-ts";

interface Props {
  isModalShown: boolean;
  heading: string;
  handleCancelButton: () => void;
  handleSubmitButton: () => void;
}

export const ConfirmationModal = ({
  heading,
  isModalShown,
  handleSubmitButton,
  handleCancelButton,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(modalRef, handleCancelButton);
  useLockedBody(isModalShown);

  return isModalShown ? (
    <div className="fixed z-50 left-0 right-0 top-0 bottom-0 bg-gray-900/60 flex items-center justify-center">
      <div
        ref={modalRef}
        className="flex flex-col gap-9 p-4 rounded-lg bg-white dark:bg-gray-800"
      >
        <div className="flex items-center justify-between gap-6">
          <h4 className="text-l dark:text-white">{heading}</h4>
          <button
            type="button"
            onClick={handleCancelButton}
            className="max-h-[16px] text-gray-800 dark:text-white hover:text-gray-400 transition-colors duration-300"
          >
            <Icon iconSize="16px" icon={ICON_COLLECTION.cross} />
          </button>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={handleCancelButton}
            type="button"
            className="group w-full px-4 py-2 rounded-full border border-red-400 hover:border-red-700 transition-colors duration-300"
          >
            <span className="capitalize text-l text-red-400 group-hover:text-red-700 transition-colors duration-300">
              cancel
            </span>
          </button>
          <VioletButton
            onClick={handleSubmitButton}
            className="px-4 py-2 w-full h-[37px]"
            text="Confirm"
          />
        </div>
      </div>
    </div>
  ) : null;
};
