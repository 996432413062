import { QueryKey } from "@tanstack/react-query";
import { ActualFundType } from "@constants/funds-data";
import { formatImageLink } from "@/api/helpers/helper";

const getFetchLink = (fund_id: string) =>
  `https://api.linkage.finance/funds/${fund_id}`;

const options: RequestInit = {
  method: "GET",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
};

export const fetchFundDetails = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<ActualFundType> => {
  const id = (queryKey[1] as string) ?? "";
  try {
    const res = await fetch(getFetchLink(id), options);
    const fund: ActualFundType = await res.json();
    return {
      ...fund,
      tokens: fund.tokens.map((token) => ({
        ...token,
        token_image: formatImageLink(token.token_image),
      })),
    };
  } catch (e) {
    throw new Error("API");
  }
};
