import * as React from "react";
import "./icon.scss";

import { IconCollectionType } from "./icon-list";
import { twMerge } from "tailwind-merge";
interface IconProps {
  className?: string;
  icon: IconCollectionType;
  iconColor?: string;
  iconSize?: string;
  tooltip_id?: string;
  tooltip_text?: string;
  hoverColor?: string;
}

export const Icon: React.FC<IconProps> = ({
  className,
  icon,
  iconColor,
  iconSize,
  tooltip_id,
  tooltip_text,
  hoverColor,
}) => {
  const Component = icon;

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const hoverStyles =
    isHovered && hoverColor
      ? {
          color: hoverColor,
          transition: "all 0.2s ease 0s",
        }
      : {};

  return (
    <span
      className={twMerge("icon", className)}
      style={{
        color: iconColor,
        width: iconSize,
        height: iconSize,
        ...hoverStyles,
      }}
      data-tooltip-id={tooltip_id}
      data-tooltip-content={tooltip_text}
      data-tooltip-place="top"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Component stroke="currentColor" fill="none" />
    </span>
  );
};
