import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { Icon } from "@ui/icons/icon";
import { Loader } from "@ui/loader/loader";
import { useTheme } from "@/components/theme-context/theme-context";
import { useBookmarks } from "@/providers/bookmarks-provider/hooks/use-bookmarks";
import { useBookmarkHandlers } from "@/providers/bookmarks-provider/hooks/use-bookmark-handlers";

import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { ROUTES } from "@/constants/routes/routes";
import { fetchFundDetails } from "@/api/fund-details";

export const FundDetailsNav = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { bookmarks } = useBookmarks();
  const { handleBookmark } = useBookmarkHandlers();

  const { fund_id } = useParams();

  const {
    data: fund,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fund-details", fund_id],
    queryFn: fetchFundDetails,
    enabled: !!fund_id,
  });

  // todo: error message?
  if (!fund) {
    return (
      <div className="w-full flex items-center justify-between rounded-lg bg-white dark:bg-gray-900 p-6">
        <Loader size="43" />
      </div>
    );
  }

  const isBookmarked = bookmarks.find((fund_id) => fund_id === fund.fund_id);

  const firstImage = fund.tokens[0].token_image;
  const secondImage = fund.tokens[1].token_image;

  return (
    <>
      {isLoading || isFetching ? (
        <div className="w-full flex items-center justify-between rounded-lg bg-white dark:bg-gray-900 p-6">
          <Loader size="43" />
        </div>
      ) : (
        <div className="w-full flex items-center justify-between rounded-lg bg-white dark:bg-gray-900 p-6">
          <div className="flex items-center gap-12px">
            <Link
              to={ROUTES.interactions.path}
              className="flex items-center justify-center cursor-pointer"
            >
              <Icon
                icon={ICON_COLLECTION.arrowLeft}
                iconColor={theme === "dark" ? "#fff" : "#1F2937"}
              />
            </Link>
            <div className="relative" style={{ width: "40px", height: "40px" }}>
              <div className="absolute top-0 left-0 z-10 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
                <img src={firstImage} alt="" />
              </div>
              <div className="absolute bottom-0 right-0 z-20 h-8 w-8 rounded-full overflow-hidden bg-white dark:bg-gray-800">
                <img src={secondImage} alt="" />
              </div>

              <div className="absolute bottom-0 left-0 z-30 w-6 h-6 rounded-full bg-violet-700 border border-white flex items-center justify-center font-bold font-sf-pro text-white text-xs	">
                {fund?.tokens.length ?? 0}
              </div>
            </div>
            <h3 className="text-gray-800 dark:text-white text-2xl font-semibold font-sf-pro">
              {fund.fund_name}
            </h3>
            <div className="flex items-center justify-center px-2 rounded-full text-violet-600 bg-violet-200 dark:bg-gray-600 dark:text-white">
              {t("funds-list-table.table.featured")}
            </div>
          </div>
          <div className="hidden md:flex items-center gap-24px">
            <button
              onClick={() => handleBookmark(fund.fund_id)}
              className="flex items-center justify-center rounded-full bg-violet-50 dark:bg-gray-800 p-6px cursor-pointer hover:dark:bg-gray-600 transition-all duration-300"
            >
              <Icon
                icon={
                  !!isBookmarked
                    ? ICON_COLLECTION.bookmarkFilled
                    : ICON_COLLECTION.bookmark
                }
              />
            </button>
            <Link
              to={`/interactions-info/${fund_id}`}
              className="py-12px px-32px rounded-full bg-violet-200 dark:bg-gray-800 hover:bg-violet-300 hover:dark:bg-gray-700 transition-all duration-300 text-l font-sf-pro font-semibold text-violet-600 dark:text-white"
            >
              {t("fund-details.navBtns.desk")}
            </Link>
          </div>
          <div className="block md:hidden relative">
            <div
              className="p-12px flex items-center justify-center rounded-lg border border-violet-400 dark:border-gray-700"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <Icon
                icon={ICON_COLLECTION.moreHorizontal}
                iconSize="24px"
                iconColor="#9CA3AF"
              />
            </div>
            {isDropdownOpen ? (
              <div
                className="w-64 p-12px flex flex-col gap-12px absolute bottom-0 left-0 translate-y-full -translate-x-2/3 bg-white dark:bg-gray-950 rounded-lg"
                style={{
                  boxShadow:
                    theme === "dark"
                      ? ""
                      : "0px 10px 20px 6px rgba(75, 85, 99, 0.10)",
                }}
              >
                <Link
                  to={ROUTES.interactionsInfo.path}
                  className="w-full flex items-center justify-center gap-8px py-8px px-24px rounded-full dark:bg-gray-800 bg-violet-200 font-sf-pro text-s font-semibold text-violet-600 dark:text-white whitespace-normal"
                >
                  {t("fund-details.navBtns.mobileAdd")}
                  <Icon
                    icon={ICON_COLLECTION.plus}
                    iconSize="16px"
                    iconColor={theme === "dark" ? "#fff" : "#7C3AED"}
                  />
                </Link>
                <button className="w-full flex items-center justify-center gap-8px py-8px px-24px rounded-full dark:bg-gray-800 bg-violet-200 font-sf-pro text-s font-semibold text-violet-600 dark:text-white whitespace-normal">
                  {t("fund-details.navBtns.mobileBook")}
                  <Icon
                    icon={
                      !!isBookmarked
                        ? ICON_COLLECTION.bookmarkFilled
                        : ICON_COLLECTION.bookmark
                    }
                    iconSize="16px"
                    iconColor={theme === "dark" ? "#fff" : "#7C3AED"}
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
